import DataTable, {
    BDSGridProps
} from '@barracuda-internal/bds-core/dist/DataTable/DataTable';
import React, { ReactElement } from 'react';
import TableMask from 'src/routes/dashboard/TableMask';

interface Props extends BDSGridProps {
	isLoading?: boolean;
}

function DITable({ isLoading = false, ...props }: Props): ReactElement {
	return (
		<>
			<TableMask open={isLoading} message="Loading users"></TableMask>
			<DataTable {...props} />
		</>
	);
}

export default DITable;
