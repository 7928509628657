import Toolbar from '@material-ui/core/Toolbar';
import classNames from 'classnames';
import React, { forwardRef } from 'react';
import { BCCCloudApiAccount } from 'src/components/BCC/BCCCloudApi';
import styles from './styles';

export interface BCCAccountToolbarProps {
	className?: string;
}

export interface BCCAccount extends BCCCloudApiAccount {}

export interface BCCAuthContextValue {
	currentAccountId: number;
	defaultAccountId: number;
	username: string;
	accounts: Array<BCCAccount>;
	isLoading: boolean;
	switchAccount: (newAccountId: number) => void;
}

export const BCCAccountToolbar = forwardRef<
	null,
	React.PropsWithChildren<BCCAccountToolbarProps>
>((props, ref): React.ReactElement => {
	const classes = styles();
	const { className, children } = props;

	return (
		<Toolbar
			ref={ref}
			variant="dense"
			className={classNames(className, classes.accountBar)}
		>
			{children}
		</Toolbar>
	);
});

export default BCCAccountToolbar;
