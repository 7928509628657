import { UserManager } from 'oidc-client';
import React, { ReactElement, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useAppToolchain } from 'src/hooks/useAppToolchain';

interface Props {
	userManager: UserManager;
}

function OidcSignin({}: Props): ReactElement {
	const [signinValid, setSigninValid] = useState(false);
	const { oidcUserManager } = useAppToolchain();

	useEffect(() => {
		oidcUserManager
			.signinRedirectCallback()
			.then(() => {
				setSigninValid(true);
			})
			.catch((e) => {
				console.log(e);
			});
	}, []);

	return signinValid ? (
		<Redirect to="/detections" />
	) : (
		<div>Waiting for redirect...</div>
	);
}

export default OidcSignin;
