import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useState } from 'react';
export default function TextFileUploader({ onFileChange }) {
	const [selectedFile, setSelectedFile] = useState('');
	const onDrop = useCallback((acceptedFiles) => {
		if (!acceptedFiles.length) return;

		const formData = new FormData();
		formData.append('payload', acceptedFiles[0]);
		setSelectedFile(acceptedFiles[0].name);
		onFileChange(formData);
	}, []);

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		accept: ['text/*'],
		multiple: false,
		onDrop,
	});

	return (
		<div>
			<div {...getRootProps()}>
				<input {...getInputProps()} />
				{isDragActive ? (
					<p>Drop your file here.</p>
				) : (
					<div>
						<p>
							Drop any plain text file here, or click to upload.
						</p>
						<p>
							Each keyword or phrase must be separated by a new
							line.{' '}
						</p>
					</div>
				)}
			</div>
			{ selectedFile != '' ? <div>Selected File: <b>{selectedFile}</b></div> : null }
		</div>
	);
}
