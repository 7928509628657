import React, { ReactElement } from 'react';
import AppLayout from 'src/routes/layouts/AppLayout';
import OnboardingPage from 'src/routes/OnboardingPage';

interface Props {}

function OnboardingRoute({}: Props): ReactElement {
	return (
		<AppLayout noSubHeader={true}>
			<OnboardingPage />
		</AppLayout>
	);
}

export default OnboardingRoute;
