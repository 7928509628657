import * as React from 'react';
import Box from '@mui/material/Box';
import useStyles from 'src/styles/di-theme';
import classNames from 'classnames';
import { IconButton } from '@barracuda-internal/bds-core';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import Typography from '@mui/material/Typography';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { DIHtmlTooltip } from 'src/components/TooltipColumn';
import IOSSwitch from './IOSSwitch';
import { SideDetailContext } from './SideDetailPanel';
import Tooltip from '@mui/material/Tooltip';
export default function CenteredIcons({
	zoom,
	onZoomIn,
	onZoomOut,
	total,
	current,
	onUp,
	onDown,
	classifier = '',
	setShowRedactions,
	isViewable,
	isCropEnabled
}) {
	const backgroundColor = '#2B2929';
	const classes = useStyles();
	const { showRedactions } = React.useContext(SideDetailContext);

	const handleSwitchToggle = () => {
		setShowRedactions(!showRedactions);
	};
	return (
		<div
			style={{backgroundColor}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					color: 'white',
					opacity: 0.9,
					padding: 1,
				}}
				className={!isCropEnabled ? classNames(classes['d_visible']) : classNames(classes['d_hidden'])}
			>
				{classifier != '' ? (
					<>
						<Typography className={classNames(classes['pl_2'])}>
							{classifier.length > 19 ? (
								<DIHtmlTooltip
									ttcontent={
										<Typography
											variant="body1"
											color="textSecondary"
											className={classNames(
												classes['d_inline'],
											)}
										>
											<Typography
												variant="body1"
												key={classifier}
												className={classNames(
													classes['p_1'],
												)}
											>
												{classifier}
											</Typography>
										</Typography>
									}
									content={
										<Typography
											variant="body1"
											className={classNames(
												classes['d_inline'],
												classes['cursor_pointer'],
											)}
										>
											{classifier.slice(0, 16) + '... '}
										</Typography>
									}
								></DIHtmlTooltip>
							) : (
								classifier
							)}
						</Typography>
						<IconButton
							size="small"
							onClick={() => {
								onUp();
							}}
							className={classNames(classes['pl_2'])}
						>
							<KeyboardArrowUpRoundedIcon
								fontSize="small"
								style={{ color: 'white' }}
							/>
						</IconButton>
						<Typography
							className={classNames(classes['pl_1'], classes['pr_1'])}
						>
							{current} of {total}
						</Typography>
						<IconButton
							size="small"
							onClick={() => {
								onDown();
							}}
						>
							<KeyboardArrowDownIcon
								fontSize="small"
								style={{ color: 'white' }}
							/>
						</IconButton>
					</>
				) : null}
				<Box
					sx={{
						flexGrow: 1,
					}}
				></Box>
				{isViewable && <Box
					style={{
						display: 'flex',
						alignItems: 'center',
						marginRight: '15px'
					}}
				>	<Typography 
						className={classNames(classes['p_1'])}
					>
						Redactions
					</Typography>
					<Tooltip title={showRedactions ? `Redaction: On` : `Redaction: Off`}>
						<div>
							<IOSSwitch checked={showRedactions} onChange={handleSwitchToggle} />
						</div>
					</Tooltip>
				</Box>}
				
				<IconButton
					size="small"
					onClick={() => {
						onZoomOut();
					}}
				>
					<RemoveIcon
						fontSize="small"
						style={{ color: 'white' }}
						onClick={() => {
							onZoomOut();
						}}
					/>{' '}
				</IconButton>
				<Typography>{zoom}%</Typography>
				<IconButton
					size="small"
					onClick={() => {
						onZoomIn();
					}}
					className={classNames(classes['pr_2'])}
				>
					<AddIcon fontSize="small" style={{ color: 'white' }} />
				</IconButton>
			</Box>
		</div>
		
	);
}
