import React from 'react';
import useTimeout from 'src/hooks/useTimeout';
import BaseLayout from 'src/routes/layouts/BaseLayout';
import LoadingIndicator from './lib/components/LoadingIndicator';

function AppLoadingIndicator() {
	const isLong = useTimeout(500);

	return (
		<BaseLayout>
			<LoadingIndicator
				message="Data Inspector is loading"
				messageOnly={!isLong}
			/>
		</BaseLayout>
	);
}

export default AppLoadingIndicator;
