import React, { useState } from 'react';
import { Checkbox, Typography, Box, IconButton } from '@barracuda-internal/bds-core';
import { FormControlLabel, FormGroup} from '@material-ui/core';
import Close from '@mui/icons-material/Close';
import useStyles from 'src/styles/di-theme';
import { without, union } from 'lodash';
import classNames from 'classnames';
export default function ColumnSelector({
	allColumns,
	onSelectionChange,
	onClose,
	selectedColumns,
}) {
	const classes = useStyles();

	return (
		<div>
			<div
				className={classNames(
					classes['p_1'],
					classes['border_bottom_gray'],
				)}
			>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					<Typography variant="h3" color="textPrimary">
						Select Columns
					</Typography>

					<Box sx={{ flexGrow: 1 }}></Box>
					<IconButton
						aria-label="Filter"
						onClick={() => onClose()}
						edge="end"
						size="small"
					>
						<Close />
					</IconButton>
				</Box>
			</div>

			<div className={classNames(classes['p_1'])}>
			<FormGroup>
				{allColumns.map((column) => (
						<FormControlLabel
						className={classNames(classes['pb_2'],classes['pl_2'])}
							control={
								<Checkbox
							size="small"
							disabled={column.disable}
							style={{ padding: '0 5px' }}
							checked={selectedColumns.indexOf(column.value) > -1}
							onChange={(event, checked) => {
								let temp = selectedColumns;
								if (checked) {
									temp = union(selectedColumns, [column.value]);
								} else {
									temp = without(selectedColumns, column.value);
								}
								onSelectionChange(temp);
							}}
						/>
							}
							label={
								<Typography variant="body1" color="textPrimary">
								{column.value}
							</Typography>
							}
						/>
				))}
				</FormGroup>
			</div>
		</div>
	);
}
