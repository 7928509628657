
import React, { useEffect } from 'react';
import BaseLayout from 'src/routes/layouts/BaseLayout';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
export default function LoginAsSupport(): React.ReactElement {
    const { oidcUserManager } = useAppToolchain();

	useEffect(() => {
		
        const queryParameters = new URLSearchParams(window.location.search)
        const impersonation_token = queryParameters.get("impersonation_token")
        oidcUserManager.signinRedirect({
            acr_values: ['idp:microsoft' ],
            extraQueryParams : {impersonation_token }
        });
	
	}, [location]);

	return (
		<BaseLayout>
			<div>Login as support</div>
		</BaseLayout>
	);
}
