import React from 'react';
import '../../styles/toast.css';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import {AlertVariant, AnimationType, Toast, TransitionAlertsProps, ToastComponentProps} from './toast-models';


const getAlertStyle = (variant: AlertVariant) => {
  switch (variant) {
    case 'outlined':
      return { backgroundColor: '#ffffff', color: '#313131', border: '1px solid #313131' };
    case 'standard':
      return { backgroundColor: '#dcdcdc', color: '#313131', border: 'none' };
    default:
      return { backgroundColor: '#313131', color: '#ffffff', border: 'none' };
  }
};

const getAnimationClasses = (animation: AnimationType) => {
  switch (animation) {
    case 'pop':
      return "popIn";
    case 'slide':
      return "slideIn";
    case 'fade':
    default:
      return "fadeIn";
  }
};

const Toast: React.FC<Toast> = (
  { 
    type = 'default',
    message,
    isCancellable = false,
    variant = 'standard',
    duration = 3000,
    animation = 'fade',
  }
) => {

  const renderAlert = {
    cancellable: <TransitionAlerts type={type} message={message} variant={variant} />,
    default: (
      <Alert
        icon={false}
        sx={{ mb: 2, ...getAlertStyle(variant) }}
        variant={variant}
      >
        {message}
      </Alert>
    ),
    standard: (
      <Alert
        sx={{ mb: 2 }}
        severity={type === 'default' ? 'info' : type} 
        variant={variant}
      >
        {message}
      </Alert>
    ),
  };

  const animationClass = getAnimationClasses(animation);

  return (
    <div 
      className={`toast ${animationClass}`} 
      style={{ '--animation-duration': `${duration / 1000}s` } as React.CSSProperties}
    >
      {
        isCancellable ? 
          renderAlert.cancellable : 
          (
            type === 'default' ? renderAlert.default : renderAlert.standard
          )
      }
    </div>
  );
};

const TransitionAlerts: React.FC<TransitionAlertsProps> = ({ type, message, variant }) => {
  const [open, setOpen] = React.useState(true);

  return (
    <Box sx={{ width: '100%' }}>
      <Collapse in={open}>
        {
          type !== 'default' && 
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              sx={{ mb: 2 }}
              severity={type}
              variant={variant}
            >
              {message} 
            </Alert>
        }
        {
          type === 'default' && 
            <Alert
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }
              icon={false}
              sx={{ mb: 2, ...getAlertStyle(variant) }}
              variant={variant}
            >
              {message} 
            </Alert>
        }
      </Collapse>
    </Box>
  );
}



const ToastComponent: React.FC<ToastComponentProps> = ({toasts, position}) => {
  return (
    <div className={`toast-container ${position} ${position.split("-")[0]}`}>
      {
        toasts.map(toast => (
          <Toast key={toast.id} {...toast} />
        ))
      }
    </div>
  )
}

export default ToastComponent;