import React from 'react';
import { DateTime } from 'luxon';
import { TableCell } from '@material-ui/core';
import { Typography } from '@barracuda-internal/bds-core';

export function DateCell({
	lastModified,
}: {
	lastModified: string;
}): React.ReactElement {
	return (
		<TableCell>
			<CaseDateInfo lastModified={lastModified} />
		</TableCell>
	);
}

export function CaseDateInfo({
	lastModified,
}: {
	lastModified: string;
}): React.ReactElement {
	return (
		<div
			style={{
				flexDirection: 'column',
				textAlign: 'left',
				textTransform: 'none',
			}}
		>
			<Typography variant="body2" noWrap>
				{DateTime.fromISO(lastModified).toLocaleString(
					DateTime.DATE_FULL,
				)}
			</Typography>
			<Typography variant="subtitle1" noWrap>
				{DateTime.fromISO(lastModified).toLocaleString(
					DateTime.TIME_WITH_SHORT_OFFSET,
				)}
			</Typography>
		</div>
	);
}
