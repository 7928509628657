import React, { useEffect, useState } from 'react';
import {
	Tabs,
	Tab,
	Box,
	Typography,
	Chip,
	Button,
	Tooltip,
	IconButton,
	Snackbar,
} from '@barracuda-internal/bds-core';
import SearchField from 'src/components/SearchField';
import Refresh from '@mui/icons-material/Refresh';
import Download from '@mui/icons-material/Download';
import FilterList from '@mui/icons-material/FilterList';
import { DateRange } from 'mui-daterange-picker';
import GridPanel from './GridPanel';
import useStyles from 'src/styles/di-theme';
import FilterPanel from 'src/components/FilterPanel';
import { without, union } from 'lodash';
import SideDetailPanel from './SideDetailPanel';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
import { ESFeedResponse, ClassifierCategories } from 'src/types/dataTypes';
import DismissPopup from './DismissPopup';
import moment from 'moment';
import classNames from 'classnames';
import FileDownload from 'js-file-download';
import DIDateRange from 'src/components/DIDateRange';
import DropDownMenu from 'src/components/DropDownMenu';
import { makeStyles } from '@material-ui/styles';
import filterIcon from '../../static/icon-filter-active.svg';
import StatusComponent from './StatusComponent';

type StatusDataItem = {
	title: string;
	count: number;
	type: string;
	detectionsbeforeDateCount?: number;
	totalCount?: number;
	route?: number | string | null;
	status: 'idle' | 'success' | 'failed';
  };

function a11yProps(index: number) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const localStyles = makeStyles({
	imageIcon: {
		position: 'relative',
		top : -5
	},
	iconRoot: {
		textAlign: 'center',
	},
});
export var storageRegion = ""
export default function DetectionPage() {
	const { auth } = useAuth();
	const { api } = useAppToolchain();
	
	
	const [tab, setTab] = React.useState(0);
	const [search, setSearch] = React.useState('');
	const [expandPanel, setExpandPanel] = React.useState(false);
	const [selectedFeed, setSelectedFeed] = React.useState(null);
	const [showFilter, setShowFilter] = React.useState(false);
	const [showDismiss, setShowDismiss] = React.useState(false);
	const [selectedFilters, setSelectedFilters] = React.useState([]);
	const [page, setPage] = React.useState(1);
	const [pageSize, setPageSize] = React.useState(25);
	const [sort, setSort] = React.useState('');
	const [sortDir, setSortDir] = React.useState('desc');
	const classes = useStyles();
	const localClasses = localStyles();
	const [dateRange, setDateRange] = React.useState<DateRange>({
		startDate: moment().subtract(30, 'days').toDate(),
		endDate: moment().toDate(),
	});
	const [searchText, setSearchText] = React.useState('');
	const [searchField, setSearchField] = React.useState('');
	const [refreshSearch, setRefreshSearch] = React.useState(0);
	const [refreshGrid, setRefreshGrid] = React.useState(0);
	const [isDownload, setIsDownload] = React.useState(false);
	const searchFields = ['file', 'owner', 'filePath', 'fileExt', 'creator'];
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		refreshEndDate();
		setSelectedFilters([])
		setSelectedFeed(null);
		setShowFilter(false);
		setPage(1);
		setTab(newValue);
	};
	const [showShimmer, setShowShimmer] = React.useState(true);

	const [remediateOption, setRemediateOption] = React.useState('Dismiss');
	const handleRemediateClose = (remediateOption) => {
		setRemediateOption(remediateOption);
		setShowDismiss(true);
	};

	const [selectedState, setSelectedState] = React.useState([]);

	const [statusData, setStatusData] = React.useState<StatusDataItem[]>([
		{
			title: 'Total scans finished',
			count: 0,
			type: 'scansFinished',
			route: '/scanlog',
			status: 'idle',
		},
		{
			title: 'Total active detections',
			count: 0,
			type: 'activeDetections',
			detectionsbeforeDateCount: 0,
			route: 0,
			status: 'idle',
		},
		{
			title: 'Scans in progress',
			count: 0,
			type: 'scansInProgress',
			route: null,
			status: 'idle',
		},
		{
			title: 'Total scans remediated',
			count: 0,
			type: 'scansRemediated',
			route: 2,
			status: 'idle',
		},
		{
			title: 'Active policies',
			count: 0,
			type: 'activePolicies',
			totalCount: 0,
			route: '/settings/policy',
			status: 'idle',
		}
	]);

	const handleFilterChange = (filter, checked) => {
		let temp = selectedFilters;
		if (checked) {
			temp = union(selectedFilters, [filter]);
		} else {
			temp = without(selectedFilters, filter);
		}
		setPage(1);
		setSelectedFilters(temp);
	};
	const downloadFile = () => {
		setIsDownload(true);
	};
	const getSelectedFilters = () => {
		let params = [];
		selectedFilters.forEach((filter) => {
			let category = filter.split(':')[0].trim();
			let value = filter.split(': ')[1];
			if (category.search('Classifiers') != -1) category = 'Classifier';
			switch (category) {
				case 'Classifier':
					params.push({ name: 'tags', value });
					break;
				case 'Sharing':
					params.push({ name: 'sharingLevel', value });
					break;
				case 'Platform':
					params.push({ name: 'source', value });
					break;
			}
		});
		return params;
	};
	const getStatus = () =>
		tab == 0 ? 'Open' : tab == 1 ? 'RemediationInitiated' : 'Dismissed';
	const [state, feedResponse, err] = useApiRequest<ESFeedResponse>(() => {
		setSelectedState([]);
		return api.getAccountESFeeds(
			auth.accountDetails.account.id,
			dateRange.startDate,
			dateRange.endDate,
			searchText,
			searchField,
			getSelectedFilters(),
			page,
			pageSize,
			sort,
			sortDir,
			getStatus(),
		);
	}, [
		auth.accountDetails.account.id,
		searchText,
		searchField,
		selectedFilters,
		page,
		pageSize,
		dateRange,
		sort,
		sortDir,
		refreshGrid,
		tab,
	]);

	const [categoryState, categoryResponse, categoryErr] =
		useApiRequest<ClassifierCategories>(() => {
			return api.getClassifierCategories(auth.accountDetails.account.id);
		}, [auth.accountDetails.account.id]);
	var classifierOptions = [];
	var classifierMap = {
		ContentClassifiers: 'Content Classifiers',
		SharingClassifiers: 'Sharing Classifiers',
		CustomClassifiers: 'Custom Classifiers',
	};
	if (categoryResponse) {
		for (let i in categoryResponse) {
			classifierOptions.push({
				category: classifierMap[i],
				id: 'tags',
				options: categoryResponse[i].sort(),
			});
		}
	}

	const [feedExportStatus] = useApiRequest<string>(
		() =>
			isDownload &&
			api.getAccountDownloadESFeeds(
				auth.accountDetails.account.id,
				dateRange.startDate,
				dateRange.endDate,
				searchText,
				searchField,
				getSelectedFilters(),
				sort,
				sortDir,
				getStatus(),
			),
		[isDownload],
		(res) => {
			setIsDownload(false);
			if (res[0] == 'RESOLVED' && res[1]) {
				FileDownload(
					res[1],
					`feeds_${
						moment(dateRange.startDate).format('MM/DD/YYYY') +
						' - ' +
						moment(dateRange.endDate).format('MM/DD/YYYY')
					}.csv`,
				);
			}
		},
	);

	const [aggState, feedAggregateResponse, aggErr] =
		useApiRequest<ESFeedResponse>(
			() =>
				api.getAccountAggregateESFeeds(
					auth.accountDetails.account.id,
					dateRange.startDate,
					dateRange.endDate,
					searchText,
					searchField,
					getSelectedFilters(),
					getStatus(),
				),
			[
				auth.accountDetails.account.id,
				searchText,
				searchField,
				selectedFilters,
				dateRange,
				refreshGrid,
				tab,
			],
		);
	let totalRecords = 0;
	if (feedResponse) {
		totalRecords = feedResponse.totalCount;
	}

	const refreshEndDate = () => {
		let range = dateRange;
		range.endDate = moment(range.endDate).isSame(new Date(), 'day')
			? new Date()
			: moment(range.endDate).endOf('day').toDate();
		setDateRange(range);
		setRefreshGrid(refreshGrid + 1);
	};
	let gridWidth = 'calc(100vw - 48px)';
	let statusWidth = 'calc(100vw - 60px)';
	let innerGridWidth = 'calc(100vw - 78px)';
	if (showFilter || selectedFeed) {
		gridWidth = 'calc(100vw - 464px)';
		innerGridWidth = 'calc(100vw - 508px)';
		statusWidth = 'calc(100vw - 500px)';
	}
	const [openSB, setOpenSB] = React.useState(false);
	const [sMsg, setSMsg] = React.useState('');


	useEffect(() => {
		if(!auth.isFullAdmin()) return;

		let isFetching = false;
		const intervalId = setInterval(() => {
		  if (!isFetching) {
			fetchStatusData();
		  }
		}, 300000);
	  
		const fetchStatusData = async () => {
		  isFetching = true;
		  try {
			let startDate = moment().subtract(1, 'days').toDate();
			let endDate = moment().toDate();

			const requests = [
				async () => {
				  	try {
						const scansActive = await api.getAccountESFeeds(
							auth.accountDetails.account.id,
							startDate,
							endDate,
							'',
							'',
							getSelectedFilters(),
							1,
							25,
							'',
							'desc',
							'Open',
						);
						updateStatusData('activeDetections', scansActive.totalCount, 'success')
					} catch {
						updateStatusData('activeDetections', 0, 'failed');
					}
				},
				async () => {
					try {
					  	const scansRemediated = await api.getAccountESFeeds(
							auth.accountDetails.account.id,
							startDate,
							endDate,
							'',
							'',
							getSelectedFilters(),
							1,
							25,
							'',
							'desc',
							'Dismissed',
						);
					  updateStatusData('scansRemediated', scansRemediated.totalCount, 'success')
					} catch {
					  updateStatusData('scansRemediated', 0, 'failed');
					}
				},
				async () => {
					try {
					  	const scansFinished = await api.getAccountESScans(
							auth.accountDetails.account.id,
							startDate,
							endDate,
							'',
							'',
							getSelectedFilters(),
							1,
							25,
							'',
							'desc',
						);
					 	updateStatusData('scansFinished', scansFinished.totalCount, 'success')
					} catch {
					  	updateStatusData('scansFinished', 0, 'failed');
					}
				},
				async () => {
					try {
					  	const activePolicies = await api.getPolicyEngineFeeds(
							auth.accountDetails.account.id,
							''
						);

						const activeCount = activePolicies.data.reduce((acc, curr) => {
							if(!curr.disabled) {
								acc += 1;
							}
							return acc;
						}, 0);

					  	updateStatusData('activePolicies', activeCount, 'success', activePolicies.count)
					} catch {
					  	updateStatusData('activePolicies', 0, 'failed', 0);
					}
				},
				async () => {
					try {
					  	const scansInProgressCount = await api.getScansinProgress(
							auth.accountDetails.account.id
						);
						
					  	updateStatusData('scansInProgress', scansInProgressCount, 'success')
					} catch {
					  	updateStatusData('scansInProgress', 0, 'failed');
					}
				},
			];

			await Promise.all(requests.map((req) => req()));

			getPrevious24HoursData();

		  } catch (error) {
			console.error("Error on fetching status data", error);
		  } finally {
			isFetching = false;
		  }
		};

		fetchStatusData();
	  
		return () => {
		  	clearInterval(intervalId);
		};
	  }, [auth.isFullAdmin()]);
	  
		const updateStatusData = (type, count, status, additionalCount = null) => {
			setStatusData((prev) =>
				prev.map((item) =>
				item.type === type
					? {
						...item,
						count,
						status,
						...(additionalCount !== null ? { totalCount: additionalCount } : {}),
					}
					: item
				)
			);
		};

	const getPrevious24HoursData = async function() {
		try {
			const detectionsbeforeDate = await api.getAccountESFeeds(
				auth.accountDetails.account.id,
				moment().subtract(2, 'days').toDate(),
				moment().subtract(1, 'days').toDate(),
				searchText,
				searchField,
				getSelectedFilters(),
				page,
				pageSize,
				sort,
				sortDir,
				'Open',
			);
			
			setStatusData((prev) =>
				prev.map((item) =>
				item.type === 'activeDetections'
					? {
						...item,
						detectionsbeforeDateCount: detectionsbeforeDate.totalCount
					}
					: item
				)
			);

		}catch (error) {
			console.error("Error on fetching getting previous 24hours data", error);
		  }
		
	}
	
	return (
		<>
			<DismissPopup
				showDismiss={showDismiss}
				selectedFeeds={
					selectedState.length > 0 ? selectedState : [selectedFeed != null ? selectedFeed.feedId : ""]
				}
				remediateOption={remediateOption}
				closePopup={(refresh) => {
					if (refresh) {
						setSelectedState([]);
						setRefreshGrid(refreshGrid + 1);
						setSMsg(
							"Marked records are under remediation, viewable and updated in real-time in the 'In Progress' tab.",
						);
						setOpenSB(true);
					}
					setShowDismiss(false);
				}}
			></DismissPopup>
			<Snackbar
				open={openSB}
				autoHideDuration={6000}
				onClose={() => {
					setOpenSB(false);
				}}
				message={sMsg}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
			/>

			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
				}}
			>
				
					<Box sx={{ width: expandPanel ? 0: gridWidth }}>
						<div
							className={classNames(
								classes['ml_3'],
								classes['mt_2'],
								classes['mb_3'],
							)}
						>
							<Typography variant="h1" color="textPrimary">
								Detections
							</Typography>

							{ 
								auth.isFullAdmin() && <div
									className={classNames(
										classes['mt_2'],
										classes['mb_3'],
										expandPanel ? classes['d_none'] : classes['d_block']
									)}
									style={{width: `${statusWidth}`}}
								>
									<StatusComponent  statusData={statusData} setTab={setTab} />
								</div>
							}

							<Box
								sx={{ borderBottom: 1, borderColor: 'divider' }}
							>
								<Tabs
									value={tab}
									textColor="primary"
									indicatorColor="primary"
									onChange={handleChange}
									aria-label="Unresolved, In progress and Resolved tabs"
								>
									<Tab label="Unresolved" {...a11yProps(0)} />
									<Tab
										label="In Progress"
										{...a11yProps(1)}
									/>
									<Tab label="Resolved" {...a11yProps(1)} />
								</Tabs>
							</Box>
							<div
								className={classNames(
									classes['mr_2'],
									classes['mt_2'],
								)}
							>
								<div className={classes['pb_1']}>
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
										}}
									>
										<DIDateRange
											dateRange={dateRange}
											setDateRange={(range) => {
												setPage(1);
												setDateRange(range);
											}}
										></DIDateRange>
										<Box
											sx={{ width: '350px' , minWidth : '200px'}}
											className={classNames(
												classes['ml_2'],
											)}
										>
											<SearchField
												searchFields={searchFields}
												setSearchField={setSearchField}
												setSearchText={setSearchText}
												search={search}
												setSearch={setSearch}
												setPage={setPage}
												refresh={refreshSearch}
											/>
										</Box>

										<div>
											<Tooltip title="Filter">
												<span>
												{selectedFilters.length > 0 ? (
													<IconButton
														classes={{
															root: localClasses.iconRoot,
														}}
														color="primary"
														onClick={() => {
															setSelectedFeed(
																null,
															);
															setShowFilter(true);
														}}
													>
														<img
															className={
																localClasses.imageIcon
															}
															src={filterIcon}
														/>
													</IconButton>
												) : (
													<IconButton
														className={classNames(
															classes[
																'button_tf_height'
															],
														)}
														onClick={() => {
															setSelectedFeed(
																null,
															);
															setShowFilter(true);
														}}
													>
														<FilterList />
													</IconButton>
												)}
												</span>
											</Tooltip>
										</div>
										<div></div>

										<div>
											<Tooltip title="Export CSV">
												<span>
												<IconButton
													disabled={
														feedExportStatus ==
														'RUNNING'
													}
													className={classNames(
														classes[
															'button_tf_height'
														],
													)}
													onClick={() =>
														downloadFile()
													}
												>
													<Download />
												</IconButton>
												</span>
											</Tooltip>
										</div>

										<div>
											<Tooltip title="Refresh">
												<span>
												<IconButton
													disabled={
														state == 'RUNNING'
													}
													className={classNames(
														classes[
															'button_tf_height'
														],
													)}
													onClick={refreshEndDate}
												>
													<Refresh />
												</IconButton>
												</span>
											</Tooltip>
										</div>

										<Box sx={{ flexGrow: 1 }}></Box>
										{tab == 0 ? (
											<div>
												{selectedState.length > 0 ? (
													<Typography
														className={classNames(
															classes[
																'line_height_40'
															],
														)}
													>
														{' '}
														<b>
															{
																selectedState.length
															}
														</b>{' '}
														selected{' '}
													</Typography>
												) : null}
											</div>
										) : null}

										{tab == 0 ? (
											<div>
												<div>
													<DropDownMenu
														disabled={
															selectedState.length ==
																0 &&
															selectedFeed == null
														}
														label="Remediate"
														menus={[
															{
																type: 'menu',
																label: 'Quarantine',
																onClick: () => {
																	handleRemediateClose(
																		'Quarantine',
																	);
																},
															},
															{
																type: 'menu',
																label: 'UnShare',
																onClick: () => {
																	handleRemediateClose(
																		'UnShare',
																	);
																},
															},
															{
																type: 'menu',
																label: 'Dismiss',
																onClick: () => {
																	handleRemediateClose(
																		'Dismiss',
																	);
																},
															},
															{ type: 'divider' },
															{
																type: 'menu',
																label: 'Delete',
																onClick: () => {
																	handleRemediateClose(
																		'Delete',
																	);
																},
															},
														]}
													></DropDownMenu>
												</div>
											</div>
										) : null}
									</Box>
								</div>

								<div>
									{selectedFilters.map((filter) => (
										<Chip
											className={classNames(
												classes['mr_2'],
												classes['mb_1'],
											)}
											label={filter}
											size="small"
											onDelete={() =>
												handleFilterChange(
													filter,
													false,
												)
											}
										/>
									))}
									{selectedFilters.length > 0 ? (
										<Button
											size="small"
											color="primary"
											variant="text"
											className={classNames(
												classes['mr_2'],
												classes['mb_1'],
											)}
											onClick={() =>
												setSelectedFilters([])
											}
										>
											Clear All
										</Button>
									) : null}
								</div>

								<GridPanel
									onSelectFeed={(feed) => {
										if (tab == 0) {
											setShowFilter(false);
											setSelectedFeed(feed);
											setShowShimmer(true);
										}
									}}
									selectedFeed={selectedFeed}
									feeds={
										feedResponse ? feedResponse.data : []
									}
									pageSize={pageSize}
									page={page}
									loading={state == 'RUNNING'}
									total={totalRecords}
									onPageChange={(page, pageSize) => {
										setPage(page);
										setPageSize(pageSize);
									}}
									sort={sort}
									sortDir={sortDir}
									onSortChange={(field, dir) => {
										setSort(field);
										setSortDir(dir);
									}}
									selectedState={selectedState}
									setSelectedState={(s) => {
										setSelectedState(s);
									}}
									width={innerGridWidth}
									tab={
										tab == 0
											? 'Unresolved'
											: tab == 1
											? 'In Progress'
											: 'Resolved'
									}
									searchText={searchText}
									selectedFilters={selectedFilters}
									showFilter={showFilter}
								></GridPanel>
							</div>
						</div>
					</Box>
			
				{selectedFeed != null ? (
					<div
						className={
							expandPanel
								? classes['full_panel']
								: classes['side_panel']
						}
					>
						<SideDetailPanel
							expand={expandPanel}
							onExpand={(e) => setExpandPanel(e)}
							esFeed={selectedFeed}
							onClose={() => {
								setSelectedFeed(null);
								setShowShimmer(true);
							}}
							setSearch={(search) => {
								setExpandPanel(false);
								setSelectedFeed(null);
								setSearch(search);
								setRefreshSearch(refreshSearch + 1);
							}}
							allCategories={categoryResponse}
							showShimmer={showShimmer}
							setShowShimmer={setShowShimmer}
							refreshEndDate={refreshEndDate}
						></SideDetailPanel>
					</div>
				) : null}
				{showFilter ? (
					<div className={classes['side_panel']}>
						<FilterPanel
							selected={selectedFilters}
							onSelectionChange={handleFilterChange}
							onClose={() => setShowFilter(false)}
							aggData={feedAggregateResponse}
							filterOptions={[
								{
									category: 'Platform',
									id: 'cloudType',
									options: [
										'OneDriveConnector',
										'SharepointLibraryConnector',
									].sort(),
								},
								{
									category: 'Sharing',
									id: 'sharingLevels',
									options: [
										'Public',
										'Internal',
										'Private',
										'External',
										'Protected',
										'Tenant',
									].sort(),
								},
							].concat(classifierOptions)}
						></FilterPanel>
					</div>
				) : null}
			</Box>
		</>
	);
}
