import React, { ComponentType } from "react";

// Define a type for the component's state, including the hasError property
interface ErrorBoundaryState {
  hasError: boolean;
}

// Create a higher-order component (HOC) for error boundaries
function withErrorBoundary<P>(
  WrappedComponent: ComponentType<P>, 
  FallbackComponent: ComponentType | null = null
) {
  // Return a new class-based component that handles errors
  return class extends React.Component<P, ErrorBoundaryState> {
    constructor(props: P) {
      super(props);
      this.state = { hasError: false }; // Initialize state with hasError
    }

    static getDerivedStateFromError(error: Error) {
      // Update state so the next render shows the fallback UI
      return { hasError: true };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
      // You can log the error to an error reporting service if needed
      console.error("Error caught by ErrorBoundary:", error, errorInfo);
    }

    render() {
      if (this.state.hasError) {
        // Render fallback UI if there is an error
        return FallbackComponent ? <FallbackComponent /> : <h1>Something went wrong.</h1>;
      }

      // Render the wrapped component normally
      return <WrappedComponent {...this.props} />;
    }
  };
}

export default withErrorBoundary;
