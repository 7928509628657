import React, { useCallback, useEffect, useState, useRef } from 'react';
import debounce from 'lodash/debounce';
import { TextField } from '@barracuda-internal/bds-core';
import useStyles from 'src/styles/di-theme';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import { CloseSharp } from '@material-ui/icons';

export default function SearchField({
    searchFields,
    setSearchField,
    setSearchText,
    search,
    setSearch,
    setPage,
    refresh,
    label = 'Search by file or owner',
}) {
    const classes = useStyles();
    const [showTooltip, setShowTooltip] = useState(false);
    const searchTextRef = useRef(search);

    useEffect(() => {
        searchTextRef.current = search;
    }, [search]);

    const setSearchTextFn = useCallback((val) => {
        let array = val.split(/:(.*)/s);
        if (array.length > 1) {
            let idx = searchFields.findIndex((item) => item === array[0].trim());
            if (idx === -1) {
                setSearchField('');
                setSearchText(val);
            } else {
                setSearchField(array[0].trim());
                setSearchText(array[1].trim());
            }
        } else {
            setSearchField('');
            setSearchText(val.trim());
        }
        setPage(1);
    }, []);

    const debouncedUpdateSearch = useCallback(debounce((val) => {
        setSearchTextFn(val);
    }, 300), [setSearchTextFn]);

    useEffect(() => {
        return () => debouncedUpdateSearch.cancel();
    }, [debouncedUpdateSearch]);

    const handleSearch = (event) => {
        let val = event.target.value;
        setSearch(val);
        debouncedUpdateSearch(val);
    };

    useEffect(() => {
        debouncedUpdateSearch(searchTextRef.current);
    }, [search, refresh, debouncedUpdateSearch]);

    let helperText =
        'Joe or <field>:<text> where field can be ' + searchFields.join(', ');

    return (
        <Tooltip title={helperText} arrow open={showTooltip}>
            <div>
                <TextField
                    fullWidth
                    size="small"
                    label={label}
                    onChange={handleSearch}
                    onFocus={() => setShowTooltip(true)}
                    onBlur={() => setShowTooltip(false)}
                    value={search}
                    variant="outlined"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="start">
                                <CloseSharp
                                    fontSize="small"
                                    className={classes['cursor_pointer']}
                                    onClick={() => {
                                        setSearch('');
                                        setSearchTextFn('');
                                    }}
                                />
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
        </Tooltip>
    );
}
