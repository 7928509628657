import { Avatar, TableCell } from '@material-ui/core';
import { Typography } from '@barracuda-internal/bds-core';
import React from 'react';
import Gravatar from 'react-gravatar';
import { User } from 'src/types/dataTypes';



export function UserCell({ user }: { user: User }): React.ReactElement {
	return (
		<TableCell component="td" scope="row">
			<CaseUserInfo user={user} />
		</TableCell>
	);
}

// TODO : Remove all inline styling
// TODO : Add docblocks
export function CaseUserInfo({ user }: { user: User }): React.ReactElement {
	if (!user || !user.name || !user.email)
		return (
			<div>
				<Typography variant="body2" noWrap>
					Unknown
				</Typography>
			</div>
		);

	return (
		<div
			style={{
				flexDirection: 'row',
				display: 'flex',
				alignItems: 'center',
			}}
		>
			<Avatar
				// eslint-disable-next-line @typescript-eslint/no-unused-vars
				component={({ children, ...props }) => {
					return <Gravatar {...props} email={user.email} size={40} />;
				}}
				style={{
					float: 'left',
					marginRight: '10px',
				}}
			/>
			<div
				style={{
					flexDirection: 'column',
					textAlign: 'left',
					textTransform: 'none',
				}}
			>
				<Typography variant="body2" noWrap>
					{user.name}
				</Typography>
			</div>
		</div>
	);
}
