import { Button, Typography } from '@barracuda-internal/bds-core';
import React from 'react';
import useStyles from 'src/styles/di-theme';
import classNames from 'classnames';
import error from '../static/error_page.svg';

function AppErrorPage(): React.ReactElement {
	const classes = useStyles();
    

	return (
		<div className={classNames(classes['p_4'], classes['text_center'])}>
			<div className={classes['pt_4']}>
				<img
					src={error}
					alt="Barracuda Data Inspector logo"
					width={400}
				/>
			</div>
			<div className={classNames(classes['pt_2'], classes['pb_3'])}>
				<Typography variant="h5">Invalid Scope</Typography>
				<Typography>
					There's an issue accessing the needed information for login.
					Please try again or contact support
				</Typography>
			</div>

			<Button color="primary" variant="contained">
				Visit Support
			</Button>
		</div>
	);
}

export default AppErrorPage;
