import { Typography } from '@barracuda-internal/bds-core';
import React, { ReactElement } from 'react';
import { Redirect } from 'react-router-dom';
import useAuth from 'src/hooks/useAuth';
import ClassifierTable from 'src/routes/settings/classifiers/ClassifierTable';
import useStyles from 'src/styles/di-theme';
import classNames from 'classnames';
interface Props {}

function ClassifierSettings({}: Props): ReactElement {
	const { auth } = useAuth();
	const classes = useStyles();
	if (!auth?.isFullAdmin()) {
		return <Redirect to="/settings/"></Redirect>;
	}

	return (
		<div
			className={classNames(
				classes['pt_4'],
				classes['plr_4'],
				classes['m_auto']
			)}
		>
			<Typography variant="h1" color="textPrimary" gutterBottom>
				Classifiers
			</Typography>
			<ClassifierTable accountId={auth.accountDetails.account.id} />
		</div>
	);
}

export default ClassifierSettings;
