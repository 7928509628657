import React, { ReactElement, useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
import LoadingIndicator from 'src/lib/components/LoadingIndicator';
import { CodeusAccountConfig, DIConnector } from 'src/types/dataTypes';

export function withRequireOnboarding(
	Component: (props) => ReactElement,
): (props) => ReactElement {
	const [doCompleteOnboarding, setDoCompleteOnboarding] = useState(false);
	const [reloadConfig, setReloadConfig] = useState(0);
	const [page,setPage] = useState('loading');
	const { auth } = useAuth();
	const { api } = useAppToolchain();
	const isSetup = localStorage.getItem('is_setup') == 'true';
	if (isSetup) return () => <Redirect to="/welcome" />;
	const [connectorStatus, connectors, err] = useApiRequest<DIConnector[]>(
		auth && (() => api.getAccountConnectors(auth.accountDetails.account.id)),
		[auth, api],
	);
	const [configStatus, config, err1] =
		useApiRequest<CodeusAccountConfig>(() => {
			if (!auth) return;
			return api.getAccountConfig(auth?.accountDetails.account.id);
		}, [auth, api, reloadConfig]);

	const [assignStatus, assignments, err5] = useApiRequest(() => {
		if (!auth) return;
		return api.getAccountAssignment(auth.accountDetails.account.id, {
			obj: 'obj_all',
			operation: 'delete',
		});
	}, [auth, api], (res) =>{
		console.log(res)
	});

	const [updateOnBoardTimeState, res, err3] = useApiRequest(
		doCompleteOnboarding  && (() => 
			 api.saveAccountConfig(auth.accountDetails.account.id, [
				{
					path: '/onboardedTime',
					op: 'replace',
					value: new Date().toISOString(),
				},
			])),
		[doCompleteOnboarding],
		() => {
			setReloadConfig(reloadConfig + 1);
		},
	); 

	useEffect(() => {
		if( assignStatus == 'REJECTED' || configStatus == 'REJECTED'){
			setPage('requestedPage')
		}else{
			if( config && assignments && connectors ){
				if (config.onboardedTime == null) {
					if (connectors.length > 0) {
						setDoCompleteOnboarding(true);
						setPage('loading')
					} else {
						var isWidgetAllowed = false;
						let currentUserId = auth.user.id;
						assignments.data.forEach((ass) => {
							if (ass.subject == currentUserId) {
								isWidgetAllowed = true;
							}
						});
						if (isWidgetAllowed) {
							setPage('widget');
						}else{
							setPage('requestedPage')
						}
					}
				}else{
					setPage('requestedPage')
				}
			}
			
		}
		
	}, [auth,assignments,connectors, config,assignStatus]);

	if( page == 'loading'){
		return  () => <LoadingIndicator />;
	}else if( page == 'widget'){
		return () => <Redirect to="/welcome" />;
	}else{
		return (topProps) => {
			return <Component {...topProps} />;
		};
	}


}
