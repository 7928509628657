import { Typography } from '@barracuda-internal/bds-core';
import { Dialog, Grid } from '@material-ui/core';
import React, { ReactElement, useState } from 'react';
import FEATURES from 'src/featureMap';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
import withAnalytics from 'src/lib/withAnalytics';
import EditUserDialog from 'src/routes/settings/users/EditUserDialog';
import UserSearch from 'src/routes/settings/users/UserSearch';
import UserTable from 'src/routes/settings/users/UserTable';
import { UserDetail } from 'src/types/dataTypes';
import classNames from 'classnames';
import useStyles from 'src/styles/di-theme';
import ulog from 'ulog';
import { Alert } from '@material-ui/lab';

interface Props {}

export interface SearchProps {
	text: string;
	type: 'name' | 'email';
}

function UserSettings({}: Props): ReactElement {
	const { auth } = useAuth();
	const { api } = useAppToolchain();

	const [doSearch, setDoSearch] = useState<SearchProps>();
	const [showEditDialog, setShowEditDialog] = useState<UserDetail>();

	const [searchStatus, users, err, _, setUsers] = useApiRequest<UserDetail[]>(
		(signal) =>
			api.getAccountUsers(
				auth.accountDetails.account.id,
				doSearch?.text
					? {
							type: doSearch?.type,
							value: doSearch?.text,
					  }
					: null,
				{
					signal,
				},
			),
		[doSearch],
		([_, u, e]) => {
			ulog.debug('USER: Retrieved:', u, e);
		},
	);

	const handleCloseEditUserDialog = () => {
		setShowEditDialog(null);
	};
	const handleChange = (user: UserDetail) => {
		const index = users.findIndex(
			(u) => u.user.id === showEditDialog.user.id,
		);
		if (index === -1) return;

		// FIX ME: This is a straight-up mutation... But right
		// now it's an easy way of keeping the interface matching
		// the API without a full refresh.
		users[index] = user;
		setUsers([...users]);
	};
	const handleEditUser = (user: UserDetail) => setShowEditDialog(user);
	const handleSave = () => {};
	const handleSearch = withAnalytics(
		FEATURES.settingsUsersSearch,
		(search: SearchProps) => setDoSearch(search),
		(search: SearchProps) => ({
			searchType: search.type,
		}),
	);
	const classes = useStyles();

	return (
		< div className={classNames(
			classes['pt_4'],
			classes['plr_4'],
		)}>
			<Typography
				variant="h1"
				color="textPrimary"
				className={classes['mb_2']}
			>
				Users
			</Typography>
			{showEditDialog && (
				<Dialog
					maxWidth="lg"
					onClose={handleCloseEditUserDialog}
					open={!!showEditDialog}
				>
					<EditUserDialog
						onCancel={handleCloseEditUserDialog}
						onChange={handleChange}
						onSave={handleSave}
						userDetail={showEditDialog}
					/>
				</Dialog>
			)}
			<Alert color="info" className={classNames(classes['mb_2'])}>
					User roles originate from Barracuda Cloud Control and are modifiable within the Barracuda Cloud Control System.
					</Alert>
			<UserSearch
				onSearch={handleSearch}
				isSearching={searchStatus === 'RUNNING' && !!doSearch}
			/>
			<div className={classNames(classes['pt_3'])}>
				<UserTable
					data={users}
					isLoading={searchStatus === 'RUNNING'}
					onEditUser={handleEditUser}
				/>
			</div>
		</div>
	);
}

export default UserSettings;
