import {
	Button,
	Grid,
	Radio,
	RadioGroup,
	TextField,
	Typography,
} from '@barracuda-internal/bds-core';
import React, { ChangeEvent, ReactElement, useState } from 'react';
import { SearchProps } from 'src/routes/settings/UserSettings';
import classNames from 'classnames';
import useStyles from 'src/styles/di-theme';

interface Props {
	isSearching: boolean;
	onSearch: (search: SearchProps) => void;
}

function UserSearch({ isSearching = false, onSearch }: Props): ReactElement {
	const [searchText, setSearchText] = useState('');
	const [searchType, setSearchType] = useState<'name' | 'email'>('name');
	const classes = useStyles();

	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) =>
		setSearchText(e.target.value);

	const handleSearchClick = () =>
		onSearch({
			type: searchType,
			text: searchText.trim(),
		});

	const handleSearchTypeChange = (e) => {
		setSearchType(e.target.value);
		if (searchText)
			onSearch({
				type: e.target.value,
				text: searchText,
			});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		handleSearchClick();
	};

	return (
		<form onSubmit={handleSubmit}>
			<Grid container direction="row" spacing={1} alignItems="center">
				<Grid item>
					<TextField
						value={searchText}
						onChange={handleSearchChange}
						label="Search"
					/>
				</Grid>
				<Grid item>
					<div className={classNames(classes['pl_3'])}>
						<Button
							disabled={isSearching}
							isLoading={isSearching}
							onClick={handleSearchClick}
							variant="contained"
							color="primary"
						>
							Search
						</Button>
					</div>
				</Grid>
			</Grid>
			<RadioGroup
				row
				onChange={handleSearchTypeChange}
				value={searchType}
				className={classNames(classes['pt_1'])}
			>
				<div>
					<Radio value="name" color="primary" />
					<Typography
						component="span"
						variant="body1"
						color="textPrimary"
					>
						Search By Name
					</Typography>
				</div>
				<div>
					<Radio value="email" color="primary" />
					<Typography
						component="span"
						variant="body1"
						color="textPrimary"
					>
						Search By Email
					</Typography>
				</div>
			</RadioGroup>
		</form>
	);
}

export default UserSearch;
