import React, { useEffect, useState } from 'react';
import DILogo from 'src/components/DILogo';
import FullPageError from 'src/components/FullPageError';
import useTimeout from 'src/hooks/useTimeout';
import BaseLayout from 'src/routes/layouts/BaseLayout';
import ulog from 'ulog';
import AppLoadingIndicator from './AppLoadingIndicator';
import { storageRegion } from './routes/AppRoute';
/**
 * This component manages loading pre-app configuration.
 */

function AppLoader({ app: App, ...appProps }) {
	const [appConfig, setAppConfig] = useState();
	const [loadError, setLoadError] = useState();
	const [isLongLoad, clearTimer] = useTimeout(1000);
	
	useEffect(() => {
		getAppConfig()
			.then((config) => setAppConfig(config))
			.catch((err) => setLoadError(err))
			.finally(() => clearTimer());
	}, []);

	if (loadError) {
		ulog.error(loadError);
		return <FullPageError message="Error loading application." />;
	}

	if (appConfig) {
		ulog.info(`Loaded with config:`, appConfig);
		return <App {...appProps} config={appConfig} />;
	}

	if (!appConfig && isLongLoad) {
		return (
			<div>
				<DILogo />
				<AppLoadingIndicator />
			</div>
		);
	}

	return <BaseLayout />;
}

/**
 * This exists as its own function because we can get config either by
 * loading it from a URL, or from environmental variables, depending on
 * if we are in development or production.
 *
 * This function provides on simple go-to way of returning the app config
 * without having to know which environment you're in.
 */

export async function getAppConfig() {
	if (inDev() && !process.env.CONFIG_URL) return getEnvConfig();

	return await getRemoteConfig(process.env.CONFIG_URL);
}

export async function getRemoteConfig(configURL?: string) {
	try {
		const res = await fetch(configURL || '/config.json');
		if (!res.ok) throw new Error('Bad response from remote config.');
		const data = await res.json();
		//alert("app loader")
		console.log("AppLoader",data)
		console.log("currentRegion",data.currentRegion)
		console.log("IN_url",data.regionMapping["IN"])
		console.log("storageRegion_appLoader",storageRegion)
		const url = window.location.href
		
		if (storageRegion == "IN"){
			if(!url.includes(data.regionMapping["IN"])){
				window.location.replace("https://"+data.regionMapping["IN"])
				console.log("IN region",data.regionMapping["IN"])
			}
		}
		// Get data and set production to true...
		// We do this because the remote config should only be used in production,
		// and it needs to be turned on to enable analytics.
		if (!data.env) data.env = 'production';

		return data;
	} catch (err) {
		throw new Error('Bad response from remote config.');
	}
}

function getEnvConfig() {
	return {
		apiEndpoint: process.env.API_ENDPOINT,
		appInsightsKey: process.env.APPINSIGHTS_INSTRUMENTATIONKEY,
		codeusEnvironment: process.env.CODEUS_ENV,
		env: process.env.NODE_ENV,
		googleTrackingCode: process.env.GOOGLE_TRACKING_CODE,
		intercomAppId: process.env.INTERCOM_APP_ID,
		loginURL: process.env.LOGIN_URL,
		logoutURL: process.env.LOGOUT_URL,
		sentryDsn: process.env.SENTRY_DSN,
		mixpanelProjectCode: process.env.MIXPANEL_PROJECT_TOKEN,
		cookielawDomainId: process.env.COOKIELAW_DOMAIN_ID,
		oidcAuthority: process.env.OIDC_AUTHORITY,
		oidcPostLogoutRedirectUri: process.env.OIDC_POST_LOGOUT_REDIRECT_URI,
		oidcSigninRenewUri: process.env.OIDC_SIGNIN_RENEW_URI,
		oidcRedirectUri: process.env.OIDC_REDIRECT_URI,
		regionMapping : {IN: "app.dvin.codeus.ai", US: "app.dev.codeus.ai"},
		currentRegion : process.env.CURRENT_REGION

	};
}

function inDev(): boolean {
	// NOTE: When using Parcel, process.env acts a little funny.
	// You can pull a specific value from it, but you cannot read out .env
	// such as console.log(process.env).
	//
	// Read: Do not freak out if you're debugging and process.env looks empty.
	ulog.debug(`NODE_ENV is:`, process.env.NODE_ENV);

	return process.env.NODE_ENV === 'development';
}

export default AppLoader;
