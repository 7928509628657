import { Button } from '@barracuda-internal/bds-core';
import { Paper } from '@material-ui/core';
import mixpanel from 'mixpanel-browser';
import React, { useEffect, useState } from 'react';
import DILogo from 'src/components/DILogo';
import MicrosoftSignInButton from 'src/components/MicrosoftSignInButton';
import FEATURES from 'src/featureMap';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import withAnalytics from 'src/lib/withAnalytics';
import cudaLogo from 'src/static/barracuda-logo-light.svg';
import { useClasses } from './styles';
import { Typography } from '@barracuda-internal/bds-core';

export function LoginSidebar(): React.ReactElement {
	const { oidcUserManager } = useAppToolchain();

	useEffect(() => mixpanel.identify(), []);

	const signinRedirect = (provider) => {
		return oidcUserManager.signinRedirect({
			acr_values: ['idp:' + provider],
		});
	};

	const classes = useClasses();
	return (
		<aside>
			<Paper className={classes.sidebar}>
				<div className={classes.diLogo}>
					<DILogo />
				</div>

				<div className={classes.loginPrompt}>
					<Typography variant="h4" gutterBottom>
						Find sensitive and malicious data in your Office 365
						OneDrive and SharePoint
					</Typography>
					<Typography variant="body1" gutterBottom>
						Barracuda Data Inspector scans files in OneDrive and
						SharePoint and reports on sensitive information or
						malicious data.
					</Typography>
					<div className={classes.buttonWrapper}>
						<MicrosoftSignInButton
							onClick={withAnalytics(
								FEATURES.loginButtonClick,
								() => signinRedirect('microsoft'),
							)}
						/>
					</div>
				</div>
				<div className={classes.cudaLogo}>
					<img
						src={cudaLogo}
						alt="Barracuda"
						className={classes.classLogo}
					/>
				</div>
			</Paper>
		</aside>
	);
}
