import React from 'react';
import { Pagination, Box, Typography } from '@barracuda-internal/bds-core';
import { FormControl } from '@material-ui/core';
import { Select, MenuItem } from '@barracuda-internal/bds-core';
import useStyles from 'src/styles/di-theme';
export default function TablePagination({
	pageSize = 25,
	page = 1,
	onPageChange,
	total = 100,
}: {
	pageSize?;
	page?;
	onPageChange;
	total?;
}): React.ReactElement {
	const classes = useStyles();
	const totalPages = Math.ceil(total / pageSize);
	const pageOptions = [10, 25, 50, 100];
	const handleChange = (event, pageNumber: number) => {
		onPageChange(pageNumber, pageSize);
	};
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
			}}
		>
			<Pagination
				count={totalPages}
				page={page}
				onChange={handleChange}
			/>
			<div className={classes['pl_4']}>
				<FormControl>
					<Select
						value={pageSize}
						onChange={(event) => {
							onPageChange(1, event.target.value);
						}}
					>
						{pageOptions.map((page) => (
							<MenuItem key={page} value={page}>
								{page}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>
			<Box sx={{ flexGrow: 1 }}></Box>
			<div className={classes['mr_6']}>
				{total > 0 ? (
					<Typography variant="body1" color="textSecondary">
						{(page - 1) * pageSize + 1} - {page * pageSize < total ?page * pageSize : total } of{' '}
						{total} items
					</Typography>
				) : null}
			</div>
		</Box>
	);
}
