import { colors } from '@barracuda-internal/bds-core';
import { Container, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import React, { useState } from 'react';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
import useAPIEffect from 'src/lib/useAPIEffect';
import { CodeusAccountConfig } from 'src/types/dataTypes';
import ulog from 'ulog';

const useStyles = makeStyles((theme) => ({
	paper: () => ({
		textAlign: 'center',
		backgroundColor: '#FBEAEA',
		color: '#581313',
		padding: 8,
	}),
}));

export function TierStatusDisplay({
	tierStatus,
	isFullAdmin,
}: {
	tierStatus: string;
	isFullAdmin: boolean;
}) {
	const normalizedTier = tierStatus.toLowerCase();
	const classes = useStyles();
	if (normalizedTier === 'paid' || normalizedTier === 'demo') return null;
	if (!isFullAdmin) return null;

	let text;
	switch (normalizedTier) {
		case 'trial':
			text =
				'Your trial scan is limited to roughly 10,000 items. Click the chat button in the lower right corner of your screen and ask us about an upgrade.';
	}

	return (
		<Grid container>
			<Grid xs={12} item>
				<div className={classes.paper}>{text}</div>
			</Grid>
		</Grid>
	);
}

export default function TierStatusRow() {
	const { api, config } = useAppToolchain();
	const { auth } = useAuth();
	const classes = useStyles();
	const [accountTier, setAccountTier] = useState<string>();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useAPIEffect(
		(ignore, signal) => {
			if (!auth) return;

			ulog.debug('FEED: Getting account tier');
			setIsLoading(true);
			api.getAccountTierState(auth.accountDetails.account.id, {
				signal,
			})
				.then((data) => {
					console.log(data);
					!ignore && setAccountTier(data.state);
				})
				.finally(() => setIsLoading(false));
		},
		[auth],
	);
	const [configStatus, config1, err1] =
		useApiRequest<CodeusAccountConfig>(() => {
			if (!auth) return;

			return api.getAccountConfig(auth?.accountDetails.account.id);
		}, [auth, api]);

	if (isLoading) return null;

	if (config1 && config1.onboardedTime == null) {
		return (
			<Grid container>
				<Grid xs={12} item>
					<div className={classes.paper}>
						{auth.isFullAdmin() ? (
							<div>
								This account is not setup yet. Please go to the{' '}
								<a href='/welcome'>Welcome page</a> to set it up
							</div>
						) : (
							<div>
								This account is not setup yet. Please contact
								your Account Admin to set it up.
							</div>
						)}
					</div>
				</Grid>
			</Grid>
		);
	}
	if (accountTier && auth)
		return (
			<TierStatusDisplay
				tierStatus={accountTier}
				isFullAdmin={auth.isFullAdmin()}
			/>
		);

	return null;
}

