import React, { useRef, useEffect, createContext } from 'react';
import { Typography, Box, IconButton } from '@barracuda-internal/bds-core';
import { Grid } from '@material-ui/core';
import Close from '@mui/icons-material/Close';
import useStyles from 'src/styles/di-theme';
import classNames from 'classnames';
import { getTagStyle } from './Renderer';
import { Chip } from '@barracuda-internal/bds-core';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import { Feed, Tag } from 'src/types/dataTypes';
import useAPIEffect from 'src/lib/useAPIEffect';
import LoadingIndicator from 'src/lib/components/LoadingIndicator';
import { SecurityReport } from 'src/components/SecurityReport';
import ScanLogPopup from './ScanLogPopup';
import moment from 'moment';
import ImageToolbox from './ImageToolbar';
import CheckIcon from '@mui/icons-material/Check';
import ImageViewer from './ImageViewer';
import { TagRenderer } from './Renderer';
import { DIHtmlTooltip } from 'src/components/TooltipColumn';
import Shimmer from 'src/components/Shimmer';
import InfoIcon from '@material-ui/icons/Info';
import FeedbackPopup from './FeedbackPopup';
import FalseNegativeFeedbackPopup from './FalseNegativeFeedbackPopup';
import FeedbackConfirm from './FeedbackConfirm';
import FeedbackIcon from '@mui/icons-material/Feedback';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import "react-image-crop/dist/ReactCrop.css";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';


const shimmerWidth = [100, 90, 70, 50, 80, 60, 30];

export const SideDetailContext = createContext(null);

export default function SideDetailPanel({
	esFeed,
	onClose,
	expand = false,
	onExpand,
	setSearch,
	allCategories,
	showShimmer,
	setShowShimmer,
	refreshEndDate
}) {
	const classes = useStyles();
	const [imageSpecs, setImageSpecs] = React.useState({
		default: { width: 2550, height: 3300 }
	});
	const [showFBPopup, setShowFBPopup] = React.useState(false);
	const [showFalseNegativeFBPopup, setShowFalseNegativeFBPopup] = React.useState(false);
	const [showFBConfirm, setShowFBConfirm] = React.useState(false);
	const [showRedactions, setShowRedactions] = React.useState(true);
	const [visiblePreviews, setVisiblePreviews] = React.useState([]);
	const [classifierTags, setClassifierTags] = React.useState([]);
	const [feedBackFinding, setFeedbackFinding] = React.useState({});
	const loadedImageRef = useRef({});
	const preloadImageRef = useRef({});
	const [timeStamp, setTimeStamp] = React.useState(0);
	const [totalViolations, setTotalViolations] = React.useState(0);
	const [scrollIndex, setScrollIndex] = React.useState(0);
	const [validFindingFromRedaction, setValidFindingFromRedaction] = React.useState(false);
	const sharingClassifierRef = useRef([]);
	const [cropData, setCropData] = React.useState(null);

	const pickFinding = (classifier, idx, zoom, currentPreviews) => {
		let counter = 0;
		let previews = [];

		currentPreviews.length > 0 ? previews = currentPreviews : previews = feed.previews;
		
		outerLoop: for (const [index, preview] of previews.entries()) {
			for (const [_, finding] of preview?.previewFindings.entries()) {
				if (finding.findingTag == classifier) {
					counter++;
					if (counter == idx) {
						let obj: any = getLocation(
							finding,
							zoom,
							preview.pixelDensity,
							imageSpecs[preview.id]?.width || imageSpecs.default.width,
							imageSpecs[preview.id]?.height || imageSpecs.default.height,
						);
						obj.border = showRedactions ? '3px solid #FFEB3B' : '3px solid #FCAD36';
						obj.borderRadius = '5px';
						obj.page = preview.page;
						setSelectedClassifierObj(obj);
						setPageNo(index + 1);
						if(scrollIndex !== index) {
							setScrollIndex(index);
						}
						break outerLoop;
					}
				}
			}
		}
	};


	const getLocation = (
		previewFinding,
		zoom,
		dpi,
		imageWidth,
		imageHeight
	) => {
		const tl = previewFinding.topLeftCoordinate;
		const br = previewFinding.bottomRightCoordinate;
		const displayW = zoom * 6;
		const screenW = (displayW * dpi) / imageWidth;
		const width = (br.x - tl.x) * screenW + 6;
		const height = (br.y - tl.y) * screenW + 6;
		const left = tl.x * screenW - 3;
		const top = br.y * screenW + 3 - height;
		return { width, height, left, top };
	};

	const getTagContent = (tagText: string, count: number) => {
		if (tagText.length > 19) {
			return (
				<DIHtmlTooltip
					ttcontent={
						<Typography
							variant="body1"
							color="textSecondary"
							className={classNames(classes['d_inline'])}
						>
							<Typography
								variant="body1"
								key={tagText + ' ' + count}
								className={classNames(classes['p_1'])}
							>
								{tagText + ' ' + count}
							</Typography>
						</Typography>
					}
					content={
						<Typography
							variant="body1"
							color="textSecondary"
							className={classNames(
								classes['d_inline'],
								classes['cursor_pointer'],
							)}
						>
							{tagText.slice(0, 16) + '... ' + count}
						</Typography>
					}
				></DIHtmlTooltip>
			);
		} else {
			return tagText + ' ' + count;
		}
	};

	const myRef = useRef(null);
	const executeScroll = () => {
		if (myRef && myRef.current) {
			myRef.current.scrollIntoView();
		}
	};
	const [state, feed, err] = useApiRequest<Feed>(() => {
		setShowExternalUsers(false);
		setShowInternalUsers(false);
		setSelectedClassifier('');
		setSelectedClassifierObj(null);
		setClassiferTotal(0);
		setClassifierIdx(1);
		setZoom(100);
		setPageNo(1);
		setTimeStamp(new Date().getTime());
		return api.getFeedV2(esFeed.feedId);
	}, [esFeed]);

	useEffect(() => {
		if (feed) {
			let tag = null;
			for (let i = 0; i < feed.tags.length; i++) {
				if (!isSharingClassifier(feed.tags[i].text)) {
					tag = feed.tags[i];
					break;
				}
			}
			if (tag != null) {
				setSelectedClassifier(tag.text);
				setClassiferTotal(tag.count);
				setVisiblePreviews(feed.previews.slice(0, -1).map((preview, index) => {
					if (index < 3) {
						loadedImageRef.current[preview.id] = true;
						return {
							...preview,
							redactedURL: `${preview.url}?access_token=${accessToken}&t=${timeStamp}`,
							revealedURL: `${preview.url}/reveal?access_token=${accessToken}&t=${timeStamp}`
						};
					} else {
						return { ...preview, url: '', redactedURL: '', revealedURL: '' };
					}
					
				}));
				setClassifierIdx(1);
				setClassifierTags(feed.tags.filter(tag => !isSharingClassifier(tag.text)));
				sharingClassifierRef.current = feed.tags.filter(tag => isSharingClassifier(tag.text));
				setTotalViolations(feed.tags.reduce((acc, curr) => {
					if(!isSharingClassifier(curr.text)) {
						acc += curr.count
					}
					return acc;
				}, 0));
				loadingDocumentWithAllPreviews(feed.previews, fullDocumentPreviewRef);
			}
		}

		return () => {
			setImageSpecs({
				default: { width: 2550, height: 3300 }
			});
		}
	}, [feed]);

	const isSharingClassifier = (text) => {
		return allCategories && allCategories['SharingClassifiers'].includes(text);
	}

	const generatePreviewWidthAndHeight = (width, height, id) => {
		if (!imageSpecs[id]) {
			setImageSpecs(prev => ({
				...prev,
				[id]: { width: width, height: height }
			}));
		}
	}

	const { api } = useAppToolchain();
	const [showScanlog, setShowScanlog] = React.useState(false);
	const [zoom, setZoom] = React.useState(100);
	const [selectedClassifer, setSelectedClassifier] = React.useState('');
	const [selectedClassiferObj, setSelectedClassifierObj] = React.useState(null);
	const [classifierIdx, setClassifierIdx] = React.useState(1);
	const [classifierTotal, setClassiferTotal] = React.useState(0);
	const [showInternalUsers, setShowInternalUsers] = React.useState(false);
	const [showExternalUsers, setShowExternalUsers] = React.useState(false);
	const [pageNo, setPageNo] = React.useState(1);
	const [feedbackStatus, setFeedbackStatus] = React.useState('success');
	const [imageRefs, setImageRefs] = React.useState({});
	const [showPopOver, setShowPopOver] = React.useState(false);

	const [crop, setCrop] = React.useState(null);
	const [buttonPosition, setButtonPosition] = React.useState({ top: "0%", left: "0%" });
	const [cropEnabled, setCropEnabled] = React.useState(false);
	const [cropIndex, setCropIndex] = React.useState(null);
	const croppedImageRef = React.useRef(null);
	const fullDocumentClassifierRef = React.useRef({});
	const fullDocumentPreviewRef = React.useRef([]);
	const [isViewFullDocumentClicked, setIsViewFullDocumentClicked] = React.useState(false);
	const [isFullDocumentTooltipVisible, setIsFullDocumentTooltipVisible] = React.useState(false);


	useEffect(() => {
		executeScroll();
		updatingSelectedClassifierOnFullDocument(selectedClassifer, classifierIdx, fullDocumentPreviewRef.current, fullDocumentClassifierRef);
	}, [selectedClassifer, classifierIdx]);

	useEffect(() => {
		setSelectedClassifierObj(prev => ({ ...prev, border: showRedactions ? '3px solid #FFEB3B' : '3px solid #FCAD36' }));
	}, [showRedactions, visiblePreviews]);

	const tagsWithReport: Tag[] = feed
		? feed.tags.filter((t: Tag) => t.hasReport)
		: [];

	const [accessToken, setAccessToken] = React.useState('');
	useAPIEffect(() => {
		api.getAccessToken().then((access_token) => {
			setAccessToken(access_token);
		});
	});

	async function loadingDocumentWithAllPreviews(dataSource, fullDocumentPreviewRef) {
		if(isViewFullDocumentClicked) return;
		
		const firstPreviewData = dataSource[0];
		const totalPage = dataSource[dataSource.length - 1].page;
		let pointerIndex = 0;
	
		for (let page = 1; page <= totalPage; page++) {
			if (pointerIndex < dataSource.length - 1 && dataSource[pointerIndex].page === page) {
				fullDocumentPreviewRef.current.push(dataSource[pointerIndex]);
				pointerIndex++;
			} else {
				fullDocumentPreviewRef.current.push({
					expiration: firstPreviewData.expiration,
					id: `image_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`,
					isViewable: firstPreviewData.isViewable,
					page: page,
					pixelDensity: 300,
					previewFindings: [],
					url: `${firstPreviewData.url.split('/previews')[0]}/pages/${page}/download`,
					previewWithoutFindings: true,
				});
			}
		}
	}

	async function updatingSelectedClassifierOnFullDocument(classifier, idx, previews = [], fullDocumentClassifierRef) {
		if(isViewFullDocumentClicked) return;

		let counter = 0;
		
		outerLoop: for (const [index, preview] of previews.entries()) {
			for (const [_, finding] of preview?.previewFindings.entries()) {
				if (finding.findingTag == classifier) {
					counter++;
					if (counter == idx) {
						fullDocumentClassifierRef.current = {
							previewIndex: index,
							page: index + 1,
							classifier
						}
						break outerLoop;
					}
				}
			}
		}
	}

	function handleFullDocumentClick(classifierObj, previews, loadedImageRef, setImageRefs, showRedactions) {
		if(isViewFullDocumentClicked) return;

		setIsViewFullDocumentClicked(true);
		loadedImageRef.current = {};
		preloadImageRef.current = {};
		const timeStamp = new Date().getTime();
		setTimeStamp(timeStamp);
		const currentIndex = classifierObj.previewIndex;

		setVisiblePreviews(previews.map((preview, index) => {
			const baseUrl = `${preview.url}?access_token=${accessToken}&t=${timeStamp}`;
			if (index === currentIndex || index === currentIndex - 1 || index === currentIndex + 1) {
				if (index >= 0 && index < previews.length) {
					loadedImageRef.current[preview.id] = true;
					const revealedUrl = preview?.previewWithoutFindings 
						? baseUrl 
						: `${preview.url}/reveal?access_token=${accessToken}&t=${timeStamp}`;

					preloadImage(showRedactions, baseUrl, revealedUrl);
					return {
						...preview,
						redactedURL: baseUrl,
						revealedURL: revealedUrl,
					};
				}
			}

			return { ...preview, url: '', redactedURL: '', revealedURL: '' };	
		}));

		setImageRefs(
			previews.reduce((acc, value, index) => {
				acc[index] = React.createRef();
				return acc;
			}, {})
		);

		pickFinding(selectedClassifer, classifierIdx, zoom, previews);
	}

	const preloadImage = (showRedactions, baseUrl, revealedURL) => {
        const img = new Image();
        img.src = !showRedactions ? `${baseUrl}` : `${revealedURL}`;
	};

	function resetCropAndFullDocumentState() {
		fullDocumentClassifierRef.current = {}
		fullDocumentPreviewRef.current =[];
		croppedImageRef.current = {};
		setVisiblePreviews([]);
		setIsViewFullDocumentClicked(false);
		setCropIndex(null);
		setCropEnabled(false);
	}

	const ListItem = ({ name, value }) => (
		<div className={classNames(classes['pt_2'])}>
			<Grid container spacing={2} justifyContent="center">
				<Grid item xs={5}>
					<Typography
						variant="body1"
						align="right"
						color="textSecondary"
						style={{ fontWeight: 600 }}
					>
						{name}
					</Typography>
				</Grid>
				<Grid item xs={7}>
					<Typography
						align="left"
						variant="body1"
						color="textSecondary"
					>
						<div className={classes['break_all']}>{value}</div>
					</Typography>
				</Grid>
			</Grid>
		</div>
	);
	if (state == 'RUNNING') {
		return (
			<div
				style={{
					maxHeight: 'calc(100vh - 300px)',
					height: 'calc(100vh - 300px)',
				}}
			>
				<LoadingIndicator message="Loading ..." />
			</div>
		);
	}

	const renderSharePointSite = () => {
		if (feed.cloudType == 'SharepointLibraryConnector') {
			return (
				<ListItem
					name="SharePoint Site"
					value={feed.itemLocation}
				></ListItem>
			);
		}
	};

	return (
		<SideDetailContext.Provider
			value={{
				feedBackFinding,
				setFeedbackFinding,
				visiblePreviews,
				setVisiblePreviews,
				classifierTags,
				setClassifierTags,
				classifierIdx,
				setClassifierIdx,
				classifierTotal,
				setClassiferTotal,
				selectedClassifer,
				setSelectedClassifier,
				selectedClassiferObj,
				setSelectedClassifierObj,
				feedbackStatus,
				setFeedbackStatus,
				setShowFBPopup,
				setShowFBConfirm,
				timeStamp,
				pickFinding,
				pageNo,
				setPageNo,
				accessToken, 
				zoom,
				myRef,
				imageSpecs,
				showRedactions,
				getLocation,
				imageRefs,
				setImageRefs,
				refreshEndDate,
				totalViolations,
				setTotalViolations,
				onExpand,
				onClose,
				scrollIndex,
				setScrollIndex,
				isSharingClassifier,
				validFindingFromRedaction,
				setValidFindingFromRedaction,
				sharingClassifierRef,
				setShowPopOver,
				crop,
				setCrop,
				buttonPosition,
				setButtonPosition,
				cropEnabled,
				setCropEnabled,
				cropIndex,
				setCropIndex,
				setShowFalseNegativeFBPopup,
				cropData,
				setCropData,
				croppedImageRef,
				isViewFullDocumentClicked,
				fullDocumentPreviewRef
			}}
		>
			<div>
				<FeedbackConfirm show={showFBConfirm} closePopup={() => { setShowFBConfirm(false) }}></FeedbackConfirm>
				<FeedbackPopup show={showFBPopup} closePopup={(status) => { if (status) setShowFBConfirm(true); setShowFBPopup(false) }}></FeedbackPopup>
				<FalseNegativeFeedbackPopup show={showFalseNegativeFBPopup} closePopup={(status) => { if (status) setShowFBConfirm(true); setShowFalseNegativeFBPopup(false) }}></FalseNegativeFeedbackPopup>

				<ScanLogPopup
					showScanlog={showScanlog}
					searchText={esFeed.fileName}
					itemId={esFeed.itemId}
					closePopup={() => {
						setShowScanlog(false);
					}}
				></ScanLogPopup>

				<div
					className={classNames(
						classes['p_2'],
						classes['border_bottom_gray'],
					)}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Typography
							variant="h3"
							color="textPrimary"
							style={{
								maxWidth: expand ? 1200 : 340,
								textOverflow: 'ellipsis',
								overflow: 'hidden',
							}}
						>
							{esFeed.fileName}
						</Typography>

						<Box sx={{ flexGrow: 1 }}></Box>
						<IconButton
							aria-label="Close"
							onClick={() => {
								onClose();
								if (expand) {
									onExpand(false);
									refreshEndDate();
									setShowRedactions(true);
								}
								resetCropAndFullDocumentState();
							}}
							edge="end"
							size="small"
						>
							<Close />
						</IconButton>
					</Box>
				</div>
				{(feed != undefined && state == 'RESOLVED' )? (
					<div
						style={{
							maxHeight: 'calc(100vh - 100px)',
							overflowY: 'auto',
							overflowX: 'hidden',
						}}
					>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'row',
							}}
						>
							{expand ? (
								<>
									<Box
										style={
											{
												position: 'relative'
											}
										}
										sx={{
											flexGrow: 1,
										}}
										className={classNames(classes['bg_black'])}
									>
										<div>
											<ImageToolbox
												classifier={selectedClassifer}
												total={classifierTotal}
												current={classifierIdx}
												onDown={() => {
													let idx =
														(classifierIdx + 1) %
														classifierTotal;
													if (idx == 0)
														idx = classifierTotal;
													setClassifierIdx(idx);
													pickFinding(
														selectedClassifer,
														idx,
														zoom,
														visiblePreviews
													);
												}}
												onUp={() => {
													const idx =
														classifierIdx > 1
															? classifierIdx - 1
															: classifierTotal;

													setClassifierIdx(idx);
													pickFinding(
														selectedClassifer,
														idx,
														zoom,
														visiblePreviews
													);
												}}
												onZoomIn={() => {
													if (zoom < 300) {
														setZoom(zoom + 10);
														pickFinding(
															selectedClassifer,
															classifierIdx,
															zoom + 10,
															visiblePreviews
														);
													}
												}}
												onZoomOut={() => {
													if (zoom > 10) {
														setZoom(zoom - 10);
														pickFinding(
															selectedClassifer,
															classifierIdx,
															zoom - 10,
															visiblePreviews
														);
													}
												}}
												zoom={zoom}
												setShowRedactions={setShowRedactions}
												isViewable={feed.isPdfAvailable}
												isCropEnabled ={cropEnabled}
											></ImageToolbox>
										</div>

										<div
											style={{
												height: 'calc(100vh - 180px)',
												maxHeight: 'calc(100vh - 180px)',
												overflow: 'auto',
												paddingTop: 30,
											}}
										>
											{accessToken != '' ? (
												<ImageViewer
													viewingPage={(pageNo) => {
														setPageNo(pageNo);
													}}
													previews={!isViewFullDocumentClicked ? feed.previews.slice(0 , -1) : fullDocumentPreviewRef.current}
													zoom={zoom}
													selectedClassiferObj={
														selectedClassiferObj
													}
													myRef={myRef}
													accessToken={accessToken}
													generatePreviewWidthAndHeight={generatePreviewWidthAndHeight}
													visiblePreviews={visiblePreviews}
													setVisiblePreviews={setVisiblePreviews}
													isViewable={feed.isPdfAvailable}
													setSelectedClassifierObj={setSelectedClassifierObj}
													getLocation={getLocation}
													selectedClassifer={selectedClassifer}
													loadedImageRef={loadedImageRef}
													preloadImageRef={preloadImageRef}
													imageSpecs={imageSpecs}
													classifierIdx={classifierIdx}
													pageNo={pageNo}
													setShowFBPopup={setShowFBPopup}
												></ImageViewer>
											) : null}
										</div>

										{ !cropEnabled && feed.isPdfAvailable && <div
											id='false-negative-popover'
											style={
												{
													position: 'absolute',
													bottom: '20px',
													left: '50%',
    												transform: 'translateX(-50%)',
													backgroundColor: '#191919',
													padding: '5px 10px',
													borderRadius: '5px'
												}
											}
											className={showPopOver ? classes['d_block'] : classes['d_none']}
										>
											<div 
												className={classNames(classes['flex_ver_center'])} style={{gap: 20}}
											>

												<Button
													variant="text"
													className={classNames(classes['flex_ver_center'])}
													sx={{
														color: '#FFFFFF',
														fontWeight: 700,
														fontSize: '16px',
														textTransform: 'none'
													}}
													startIcon={<FeedbackIcon sx={{ color: '#067CC1' }} />}
													onClick={() => {
														setCropEnabled(true);
														setCropIndex(pageNo - 1);
													}}
												>
													Report Issue
												</Button>

												<Tooltip title="The full document is already loaded." 
													placement="top" 
													open={isFullDocumentTooltipVisible}
													disableFocusListener
													disableTouchListener
													slotProps={{
														tooltip: {
															sx: {
																backgroundColor: '#191919',
															}
														}
													}}
												>
      												<span>
													  	<Button
															variant="text"
															className={classNames(classes['flex_ver_center'])}
															sx={{
																color: isViewFullDocumentClicked ? '#7D7D7D61' : '#FFFFFF',
																fontWeight: 700,
																fontSize: '16px',
																textTransform: 'none',
																cursor: isViewFullDocumentClicked ? 'default' : 'pointer',
															}}
															startIcon={<TextSnippetIcon sx={{ color: isViewFullDocumentClicked ? '#7D7D7D61' : '#067CC1' }} />}
															onMouseEnter={() => {
																if (isViewFullDocumentClicked) setIsFullDocumentTooltipVisible(true);
															}}
															onMouseLeave={() => setIsFullDocumentTooltipVisible(false)}
															onClick={() => handleFullDocumentClick(
																fullDocumentClassifierRef.current,
																fullDocumentPreviewRef.current,
																loadedImageRef,
																setImageRefs,
																showRedactions
															)}
														>
															View full document
														</Button>
													</span>
												</Tooltip>
											</div>
										</div>}
									</Box>
									<Box
										sx={{
											width: 200,
											height: 'calc(100vh - 150px)',
											display: 'flex',
											flexDirection: 'column',
										}}
										className={classNames(
											classes['bg_white'],
											classes['border_right_gray'],
										)}
									>
										<section
											className={classNames(!cropEnabled ? classes['d_visible'] : classes['d_hidden'])}
										>
											<div
												className={classNames(
													classes['pl_2'],
													classes['pr_2'],
													classes['pt_2'],
												)}
											>
												<Typography
													variant="h4"
													color="textPrimary"
												>
													Classifiers
												</Typography>
												<Typography
													variant="overline"
													color="textSecondary"
													component="div"
													className={classNames(
														classes['mb_2'],
													)}
												>
													Choose classifiers, and then
													utilize the arrows in the top
													toolbar to navigate through the
													document, allowing you to view
													them seamlessly within the text.
												</Typography>
											</div>
											{classifierTags.map((tag) => {
												if (
													allCategories &&
													!allCategories[
														'SharingClassifiers'
													].includes(tag.text)
												) {
													return (
														<div
															onClick={() => {
																if (
																	tag.text !=
																	selectedClassifer
																) {
																	setSelectedClassifier(
																		tag.text,
																	);
																	setClassiferTotal(
																		tag.count,
																	);
																	setClassifierIdx(
																		1,
																	);
																	pickFinding(
																		tag.text,
																		1,
																		zoom,
																		visiblePreviews
																	);
																}
															}}
															style={{
																backgroundColor:
																	tag.text ==
																		selectedClassifer
																		? '#E4F4FF'
																		: '#FFFFFF',
																padding: 8,
																cursor: 'pointer',
															}}
														>
															<div
																style={{
																	display: 'flex',
																	alignItems:
																		'center',
																}}
															>
																<Chip
																	color="primary"
																	variant="default"
																	label={getTagContent(
																		tag.text,
																		tag.count,
																	)}
																	size="small"
																	style={{
																		...getTagStyle(
																			tag.text,
																		).tagStyle,
																		cursor: 'pointer',
																	}}
																/>
																{tag.text ==
																	selectedClassifer ? (
																	<CheckIcon
																		color="primary"
																		fontSize="small"
																		className={classNames(
																			classes[
																			'ml_2'
																			],
																		)}
																	></CheckIcon>
																) : null}
															</div>
														</div>
													);
												}
											})}
										</section>
										<Box sx={{ flexGrow: 1 }}></Box>
										{feed?.isPdfAvailable && !cropEnabled && <Box
											className={classNames(
												classes['mb_2'],
												classes['ml_2'],
											)}
										>
											<Box sx={{ color: '#8E8D8D' }}>
												<InfoIcon />
											</Box>
											<Typography
												color="textSecondary"
												component="div"
											>
												{showRedactions ? 'Turn off the redaction switch and click on a detection to report incorrect classifications.'
													: 'Click on classifiers to improve future detection accuracy and remove the selected detection.'}
											</Typography>
										</Box>}

										<Typography
											color="textSecondary"
											component="div"
											className={classNames(
												classes['mb_2'],
												classes['ml_2'],
											)}
										>
											Redacted page {pageNo} of{' '}
											{visiblePreviews.length}
										</Typography>
									</Box>
								</>
							) : null}
							<Box
								sx={{
									width: 416,
									paddingLeft: 16,
									height: 'calc(100vh - 150px)',
									overflow: 'auto',
								}}
							>
								{!expand ? (
									<>
										<section
											className={classNames(
												classes['ptb_2'],
												classes['overflow_hidden'],
											)}
										>
											{accessToken != '' ? (
												(feed.previews.length > 0 && classifierTags.length > 0)? (
													<div style={{ position: 'relative' }}>
														{showShimmer && !imageSpecs[feed.previews[0].id] && 
															<div
																style={{
																	height: 200,
																	display: 'block',
																	margin: 'auto',
																	backgroundColor: '#fff',
																	width: '40%',
																	position: 'absolute',
																	left: 0,
																	right: 0,
																	padding: '10px 20px'
																}}
															>
																<Shimmer
																	shimmerWidth={shimmerWidth}
																	skeletonHeight={15}
																	animation="wave"
																	style={{ marginBottom: 6, marginTop: 6 }}
																/>
															</div>
														}
														<img
															onClick={() => {
																onExpand(true);
																pickFinding(selectedClassifer, 1, zoom, visiblePreviews);
															}
															}
															className={classNames(
																classes['h_200'],
																classes[
																'image_center'
																],
																classes[
																'cursor_pointer'
																],
															)}
															src={
																`${feed.previews[0].url}?access_token=${accessToken}&t=${timeStamp}`
															}
															onLoad={(event) => {
																const image = event.target;
																setShowShimmer(false);
																generatePreviewWidthAndHeight(image.naturalWidth, image.naturalHeight, feed.previews[0].id)
															}}
														/>
													</div>
												) : (
													<Typography
														variant="h4"
														color="textPrimary"
														className={
															classes['text_center']
														}
													>
														No Preview Available
													</Typography>
												)
											) : (
												<LoadingIndicator message="Loading this preview" />
											)}
										</section>
										<section
											className={classNames(classes['ptb_2'])}
										>
											<Typography
												variant="h4"
												color="textPrimary"
											>
												Violations
											</Typography>
											<Typography
												variant="overline"
												color="textSecondary"
												component="div"
												className={classNames(
													classes['mb_2'],
												)}
											>
												Occurences of sensitive information
												in this shared file
											</Typography>
											<TagRenderer
												dataItem={feed}
											></TagRenderer>
										</section>
									</>
								) : null}

								<section className={classNames(classes['ptb_2'])}>
									<Typography variant="h4" color="textPrimary">
										Remediation
									</Typography>
									<Typography
										variant="overline"
										color="textSecondary"
									>
										Update file sharing settings or ask the
										owner to do so
									</Typography>
									<ListItem
										name="Owner"
										value={esFeed.ownerNames.map((owner, index) => (
											<Typography
												key={index}
												color="primary"
												className={
													classes['cursor_pointer']
												}
												onClick={() =>
													setSearch('owner : ' + owner)
												}
											>
												{owner}
											</Typography>
										))}
									></ListItem>
									<ListItem
										name="File path"
										value={
											esFeed.filePath.length == 0 ? (
												feed.itemPath
											) : (
												<Typography
													color="primary"
													className={
														classes['cursor_pointer']
													}
													onClick={() =>
														setSearch(
															'filePath : ' +
															esFeed.filePath.join(
																'/',
															),
														)
													}
												>
													{feed.itemPath}
												</Typography>
											)
										}
									></ListItem>
									<ListItem
										name="File History"
										value={
											<Typography
												color="primary"
												className={
													classes['cursor_pointer']
												}
												onClick={() => setShowScanlog(true)}
											>
												View Scan Log
											</Typography>
										}
									></ListItem>
									<ListItem
										name="Source"
										value={esFeed.cloudType}
									></ListItem>

									{renderSharePointSite()}

									<ListItem
										name="Domain"
										value={feed.user.name}
									></ListItem>
									<ListItem
										name="Report"
										value={
											<SecurityReport
												feedId={esFeed.feedId}
												tagsWithReport={tagsWithReport}
												detections={true}
											/>
										}
									></ListItem>
								</section>
								<section className={classNames(classes['ptb_2'])}>
									<Typography variant="h4" color="textPrimary">
										Access
									</Typography>
									<Typography
										variant="overline"
										color="textSecondary"
									>
										How widespread this event is
									</Typography>
									<ListItem
										name="Internal access"
										value={
											feed.permissionUsers.internalUsers
												.length > 0 ? (
												showInternalUsers ? (
													<div>
														{feed.permissionUsers.internalUsers.join(
															', ',
														)}
														<Typography
															color="primary"
															component="span"
															className={classNames(
																classes[
																'cursor_pointer'
																],
																classes['pl_1'],
															)}
															onClick={() => {
																setShowInternalUsers(
																	false,
																);
															}}
														>
															Hide
														</Typography>
													</div>
												) : (
													<Typography
														color="primary"
														className={
															classes[
															'cursor_pointer'
															]
														}
														onClick={() => {
															setShowInternalUsers(
																true,
															);
														}}
													>
														{feed.permissionUsers
															.internalUsers.length +
															' Users'}
													</Typography>
												)
											) : (
												'0 Users'
											)
										}
									></ListItem>

									<ListItem
										name="External access"
										value={
											feed.permissionUsers.externalUsers
												.length > 0 ? (
												showExternalUsers ? (
													<div>
														{feed.permissionUsers.externalUsers.join(
															', ',
														)}
														<Typography
															color="primary"
															component="span"
															className={classNames(
																classes[
																'cursor_pointer'
																],
																classes['pl_1'],
															)}
															onClick={() => {
																setShowExternalUsers(
																	false,
																);
															}}
														>
															Hide
														</Typography>
													</div>
												) : (
													<Typography
														color="primary"
														className={
															classes[
															'cursor_pointer'
															]
														}
														onClick={() => {
															setShowExternalUsers(
																true,
															);
														}}
													>
														{feed.permissionUsers
															.externalUsers.length +
															' Users'}
													</Typography>
												)
											) : (
												'0 Users'
											)
										}
									></ListItem>
									<ListItem
										name="Sharing"
										value={esFeed.sharingLevels.join(', ')}
									></ListItem>
								</section>
								<section className={classNames(classes['ptb_2'])}>
									<Typography variant="h4" color="textPrimary">
										Details
									</Typography>
									<Typography
										variant="overline"
										color="textSecondary"
									>
										Extended file information
									</Typography>

									<ListItem
										name="File type"
										value={feed.mimeType}
									></ListItem>

									<ListItem
										name="Size"
										value={feed.size + 'B'}
									></ListItem>
									<ListItem
										name="Last detected"
										value={moment(esFeed.lastModified).format(
											'LLL',
										)}
									></ListItem>
									<ListItem
										name="Last Modified User"
										value={feed.user.name}
									></ListItem>
									<ListItem
										name="Last modified"
										value={moment(feed.lastModified).format(
											'LLL',
										)}
									></ListItem>
									<ListItem
										name="Created"
										value={moment(feed.created).format('LLL')}
									></ListItem>
								</section>
							</Box>
						</Box>
					</div>
				) : null}
			</div>
		</SideDetailContext.Provider>
	);
}

