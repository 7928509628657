import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Chip } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import { debounce } from '@material-ui/core';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const dataModel = {
  "ContentClassifiers": [
      "Credit Card",
      "Credentials",
      "Passport",
      "License Number",
      "Personal ID",
      "Personal Medical ID",
      "Tax ID",
      "Suspicious",
      "Malicious",
      "Financial ID"
  ],
  "SharingClassifiers": [
      "Public Readable",
      "Public Writeable",
      "External Readable",
      "External Writeable"
  ],
  "CustomClassifiers": [
  ]
};

type SelectedClassifiers = {
  current: string[];
};

type ClassifiersProps = {
  selectedClassifiers: SelectedClassifiers;
  error: boolean;
  setError: (error: boolean) => void;
}

function Classifiers({selectedClassifiers, error, setError}: ClassifiersProps) {
    const { api } = useAppToolchain();
    const { auth } = useAuth();
    const [selectedTags, setSelectedTags] = React.useState([...selectedClassifiers.current]);
    const [data, setData] = React.useState([]);
    const prevClassifiersData = React.useRef([]);
    const [inputValue, setInputValue] = React.useState('');

  React.useEffect(() => {
    api.getPolicyClassifiers(
        auth.accountDetails.account.id
      ).then(res => {
        const customClassifiers = res.data.map(ele => ele.tagName).filter(classifier => classifier != null);
        dataModel['CustomClassifiers'] = getUniqueClassifiers(customClassifiers);
        
        const data = [{ group: '', label: "Any" },
            ...dataModel.ContentClassifiers.map(option => ({ group: 'Content Classifiers', label: option })),
            ...dataModel.SharingClassifiers.map(option => ({ group: 'Sharing Classifiers', label: option })),
            ...dataModel.CustomClassifiers.map(option => ({ group: 'Custom Classifiers', label: option }))
        ];
        prevClassifiersData.current = data;
        setData(data);
      })
}, []);

function getUniqueClassifiers(classifier: string[]) {
  const hash = {};
  classifier.forEach(ele => hash[ele] ? hash[ele] : hash[ele] = true);
  return Object.keys(hash);
}

  const handleBlur = () => {
    if(selectedTags.length === 0) {
      setError(true);
    }else {
      setError(false);
    }
    setInputValue('');
  }

  const handleInputChange = (e, value) => {
    setInputValue(value.trimStart());
  }

  return (
    <>
      <Autocomplete
        multiple
        options={data}
        disableCloseOnSelect
        clearOnBlur={false}
        limitTags={4}
        getLimitTagsText={(tags) => <span style={{fontSize: '12px', color: 'gray', fontWeight: 500}}>{`+${tags} more`}</span>}
        value={selectedTags.map(label => data.find(option => option.label === label))}
        groupBy={(option) => option.group}
        getOptionLabel={(option) => option?.label}
        onInputChange={handleInputChange}
        inputValue={inputValue}
        onChange={(_, newValue) => {
          const value = newValue.map(ele => ele.label);
          setSelectedTags(value);
          selectedClassifiers.current = value;
          setInputValue(inputValue);
        }}
        renderOption={(props, option) => (
          <>
            <li key={option?.label} {...props}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selectedTags.includes(option?.label)}
                sx={{
                  color: 'black',
                  '&.Mui-checked': {
                    color: 'black',
                  },
                }}
              />
              {option?.label}
            </li>
          </>
        )}
        size='small'
        renderInput={(params) => (
          <TextField
            {...params}
            label="Classifiers"
            InputLabelProps={{
              style: { fontSize: '10px', fontWeight: 'bold', lineHeight: 2 }
            }}
            onBlur={handleBlur}
            error={error}
            helperText={error ? 'Atleast one classifier must be selected' : ''}
          />
        )}
        renderTags={(values, getTagProps) => {
          if(values[0] == null) return [];
          return values.map((option, index) => (
            <Chip
              key={option?.label}
              sx={{
                fontSize: '10px',
                height: '20px',
                fontWeight: 500
              }}
              size="small"
              label={option?.label}
              {...getTagProps({ index })}
            />
          ))
        }

        }
      />
    </>
  );
}

export default Classifiers;