import React, { createContext,useState } from 'react';
import { AppToolchain } from 'src/types/applicationTypes';

export const AppToolchainContext = createContext<AppToolchain>(null);
export const AppToolchainConsumer = AppToolchainContext.Consumer;
export const AppToolchainProvider = ({
	children,
	toolchain,
}: React.PropsWithChildren<{
	toolchain: AppToolchain;
}>): React.ReactElement => {
	return (
		<AppToolchainContext.Provider value={ {...toolchain}}>
			{children}
		</AppToolchainContext.Provider>
	);
};
