import { Typography, Chip } from '@barracuda-internal/bds-core';
import { TableCell } from '@material-ui/core';
import React, { useState } from 'react';
import useStyles from 'src/styles/di-theme';
import classNames from 'classnames';
import { Popover } from '@material-ui/core';
import { DIHtmlTooltip } from 'src/components/TooltipColumn';

function MoreRenderer({ tags }) {
	if (tags.length == 0) return <></>;
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState();
	const classes = useStyles();
	const handleClick = (e) => {
		setAnchorEl(e.currentTarget);
		setOpen(!open);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setOpen(!open);
	};

	return (
		<>
			<Typography
				variant="body1"
				color="textSecondary"
				className={classNames(
					classes['d_inline'],
					classes['cursor_pointer'],
				)}
				onClick={handleClick}
			>
				{' '}
				+{tags.length} more
			</Typography>

			<Popover
				open={open}
				onClose={handleClose}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
			>
				<div className={classNames(classes['p_1'])}>
					{tags.map((t) => (
						<Typography
							variant="body1"
							key={t}
							className={classNames(classes['p_1'])}
						>
							{t}
						</Typography>
					))}
				</div>
			</Popover>
		</>
	);
}

export function UserRendererCell({ dataItem: { ownerNames } }) {
	let tagsText = [];
	let count = 0;
	let moreTags = [];
	const classes = useStyles();
	ownerNames.forEach((value) => {
		if (count < 1) {
			tagsText.push(
				<Typography
					variant="body1"
					key={value}
					className={classNames(classes['pr_1'], classes['d_inline'])}
				>
					{value}
				</Typography>,
			);
		} else {
			moreTags.push(value);
		}
		count++;
	});

	return (
		<td>
			{tagsText.map((t) => t)}{' '}
			<MoreRenderer tags={moreTags}></MoreRenderer>
		</td>
	);
}

function PolicyandActionsMoreRenderer({ tags }: {tags: string[]})  {
	if (tags.length == 0) return <></>;
	const classes = useStyles();
	return (
		<>
			<DIHtmlTooltip
				ttcontent={
					<Typography
						variant="body1"
						color="textSecondary"
						className={classNames(classes['d_inline'])}
					>
						{tags.map((t) => (
							<Typography
								variant="body1"
								key={t}
								className={classNames(classes['p_1'])}
							>
								{t}
							</Typography>
						))}
					</Typography>
				}
				content={
					<Typography
						variant="body1"
						color="textSecondary"
						className={classNames(
							classes['d_inline'],
							classes['cursor_pointer'],
						)}
					>
						{' '}
						+{tags.length} more
					</Typography>
				}
			></DIHtmlTooltip>
		</>
	);
}

export function PolicyandActionsRendererCell({dataItem, type}) {
	const data = type === 'policies' ? dataItem?.dataItem?.policies : dataItem?.dataItem?.actions;
	const classes = useStyles();

	if(data == null || data?.length == 0 || data[0] === 'None' || data[0] === 'none') {
		return <td><Typography variant="body1" className={classNames(classes['pr_1'], classes['d_inline'])}>{'-'}</Typography></td>
	}

	const tagsText = [];
	let count = 0;
	const moreTags = [];
	data.forEach((value) => {
		if (count < 2) {
			tagsText.push(value);
		} else {
			moreTags.push(value);
		}
		count++;
	});

	return (
		<td>
			<Typography
					variant="body1"
					className={classNames(classes['pr_1'], classes['d_inline'])}
				>
					{tagsText.join(", ")}
				</Typography>
			{moreTags.length > 0 && <PolicyandActionsMoreRenderer tags={moreTags}></PolicyandActionsMoreRenderer>}
		</td>
	);
}
export function DecisionRenderer({ dataItem: { decision } }) {
	return (
		<TableCell>
			<Chip
				style={{
					margin: '2px',
					background: decision  == 'Violation' ?  '#FFDDDD' : '#DBF6CA',
					border: '0px',
					color: 'black',
				}}
				color="primary"
				variant="default"
				clickable={false}
				label={
					<>
						<Typography component="span">
							{decision}
						</Typography>
					</>
				}
				size="small"
			/>
		</TableCell>
	);
}
