import { Typography, Chip } from '@barracuda-internal/bds-core';
import React from 'react';
import { forOwn } from 'lodash';
import useStyles from 'src/styles/di-theme';
import classNames from 'classnames';
import { DIHtmlTooltip } from 'src/components/TooltipColumn';

export const getTagStyle = (tag) => {
	let tagStyle = {
		margin: '2px',
		background: '#E4F4FF',
		border: '1px #7CBDE6 solid',
		color: 'black',
	};
	let textColor = 'black';

	let color = null;
	switch (tag) {
		case 'Malicious':
		case 'Suspicious': {
			color = '#DE3131'; // Red
			textColor = 'white !important';
			break;
		}
		case 'Credit Card':
		case 'Credentials':
		case 'Passport':
		case 'License Number':
		case 'Personal ID':
		case 'Tax ID':
		case 'MICR Code':
		case 'Personal Medical ID':
		case 'Financial ID': {
			color = '#66DAEE'; //Powder Blue
			break;
		}
		case 'External Readable':
		case 'External Writeable':
		case 'Public Readable':
		case 'Public Writeable': {
			color = '#F2EB7B'; // Yellow
			break;
		}
		default: {
			color = 'gray'; //Gray
			textColor = 'white';
			break;
		}
	}

	if (color) {
		tagStyle = {
			margin: '2px',
			background: color,
			border: '1px solid ' + color,
			color: textColor,
		};
	}

	return { tagStyle, textColor: { color: textColor } };
};

function MoreRenderer({tags = []}) {
	if (tags.length == 0) return <></>;
	const classes = useStyles();
	return (
		<>
			<DIHtmlTooltip
				ttcontent={
					<Typography
						letiant="body1"
						color="textSecondary"
						className={classNames(classes['d_inline'])}
					>
						{tags.map((t) => (
							<Typography
								variant="body1"
								key={t}
								className={classNames(classes['p_1'])}
							>
								{t}
							</Typography>
						))}
					</Typography>
				}
				content={
					<Typography
						variant="body1"
						color="textSecondary"
						className={classNames(
							classes['d_inline'],
							classes['cursor_pointer'],
						)}
					>
						{' '}
						+{tags.length} more
					</Typography>
				}
			></DIHtmlTooltip>
		</>
	);
}

// export const capitalizeFirstWords = (tagText: string): string => {
// 	const words = tagText.split(" ");
// 	for (let i = 0; i < words.length; i++) {
// 		words[i] = words[i][0]?.toUpperCase() + words[i]?.substr(1);
// 	}
// 	return words.join(" ");
// }

export function PolicyTagRendererCell(dataItem) {
	const tagsText = [];
	let count = 0;
	const moreTags = [];
	forOwn(dataItem?.conditions?.findingTags, (tag) => {
		if (count < 3) {
			tagsText.push(
				<Chip
					color="primary"
					variant="default"
					label={`${tag}`}
					key={tag}
					size="small"
					style={getTagStyle(tag).tagStyle}
				/>,
			);
		} else {
			moreTags.push(tag);
		}
		count++;
	});

	return (
				<span>
					{tagsText.map((t) => t)}{' '}
					<MoreRenderer tags={moreTags}></MoreRenderer>
				</span>
	);
}




export function ConvertDateAndTimeCell(timestampStr = '') {
	// Convert string to Date object
	const dateObj = new Date(timestampStr);

	// Define months array
	const months = ["Jan.", "Feb.", "Mar.", "Apr.", "May", "Jun.", "Jul.", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."];

	// Format date
	const formattedDate = months[dateObj.getMonth()] + " " + dateObj.getDate() + ", " + dateObj.getFullYear();
	const formattedTime = formatAMPM(dateObj);

	return (
		<>
			<Typography
                variant="body1"
                color="textPrimary"
                noWrap
              >
                {formattedDate}
              </Typography>
			  <Typography
                variant="body1"
                color="textPrimary"
                noWrap
              >
                {formattedTime}
              </Typography>
		</>
	)
}

// Function to format AM/PM
function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime;
}

function DirectoryOrIdentityMoreRenderer({text, count}) {

	if (text.length  === 0) return <></>;
	const classes = useStyles();
	return (
		<>
			<DIHtmlTooltip
				ttcontent={
					<Typography
						color="textSecondary"
						className={classNames(classes['d_block'])}
					>
						{text}
					</Typography>
				}
				content={
					<Typography
						variant="body1"
						color="textSecondary"
						className={classNames(
							classes['d_inline'],
							classes['cursor_pointer'],
						)}
					>
						{' '}
						+{count} more
					</Typography>
				}
			></DIHtmlTooltip>
		</>
	);
}

function getExclusionData(groupsTextCount, exclusionString, feed, groupsStringHidden) {
	let temp = transformGroupsData(feed?.identity?.exclusion?.groups);
	groupsTextCount += temp.length;
	let exclusionGroupsString = temp.join(', ');

	temp = transformGroupsData(feed?.identity?.exclusion?.users);
	groupsTextCount += temp.length;
	const exclusionUsersString = temp.join(', ');

	exclusionGroupsString = exclusionUsersString.length &&  exclusionGroupsString.length 
								? exclusionGroupsString + ', ' : exclusionGroupsString;

	exclusionString = exclusionGroupsString.length || exclusionUsersString.length 
						?  groupsStringHidden != '' 
						? ', (except ' + exclusionGroupsString + exclusionUsersString + ')'
						: '(except ' + exclusionGroupsString + exclusionUsersString + ')'
						: '';
	return [exclusionString, groupsTextCount];
}

export function DirectoryOrIdentityRenderCell(feed) {
	if(feed == null) return <></>;

	let groupsStringVisible = '';
	let groupsStringHidden = '';
	let exclusionString = '';
	let usersStringVisible = '';
	let usersStringHidden = '';
	let groupsTextCount = 0;
	let usersTextCount = 0;

	if(feed?.identity?.inclusion?.groups[0]?.id === 'All' && feed?.identity?.inclusion?.users[0]?.id === 'All') {
		if(feed?.identity?.exclusion?.groups.length === 0 && feed?.identity?.exclusion?.users.length === 0) {
			return (
				<Typography>
					<Typography>Groups: All groups</Typography>
					<Typography>Users: All users</Typography>
				</Typography>
			)
		}else {
			[exclusionString, groupsTextCount] = getExclusionData(groupsTextCount, exclusionString, feed, groupsStringHidden);
			return (
				<Typography>
					<Typography>{'Groups: All groups'}{' '}</Typography>
					<DirectoryOrIdentityMoreRenderer 
						text={exclusionString} 
						count={groupsTextCount}
					/>
					<Typography>Users: All users</Typography>
				</Typography>
			)
		}
		
	}else if(feed?.identity?.inclusion?.groups[0]?.id === 'All' && feed?.identity?.inclusion?.users.length === 0) {

		if(feed?.identity?.exclusion?.groups.length === 0 && feed?.identity?.exclusion?.users.length === 0) {
			return (
				<Typography>
					<Typography>Groups: All groups</Typography>
				</Typography>
			)
		}else {
			[exclusionString, groupsTextCount] = getExclusionData(groupsTextCount, exclusionString, feed, groupsStringHidden);
			return (
				<Typography>
					<Typography>{'Groups: All groups'}{' '}</Typography>
					<DirectoryOrIdentityMoreRenderer 
						text={exclusionString} 
						count={groupsTextCount}
					/>
				</Typography>
			)
		}

	}
	else if(feed?.identity?.inclusion?.users[0]?.id === 'All' && feed?.identity?.inclusion?.groups.length === 0) {
		if(feed?.identity?.exclusion?.groups.length === 0 && feed?.identity?.exclusion?.users.length === 0) {
			return (
				<Typography>
					<Typography>Users: All users</Typography>
				</Typography>
			)
		}else {
			[exclusionString, groupsTextCount] = getExclusionData(groupsTextCount, exclusionString, feed, groupsStringHidden);
			return (
				<Typography>
					<Typography>{'Users: All users'}{' '}</Typography>
					<DirectoryOrIdentityMoreRenderer 
						text={exclusionString} 
						count={groupsTextCount}
					/>
				</Typography>
			)
		}
		
	}
	else {

	// Gathering inclusion groups string
	if(feed?.identity?.inclusion?.groups[0]?.id === 'All') {
		groupsStringVisible = "Groups: All groups";
	}else {
		const inclusionGroupsData = transformGroupsData(feed?.identity?.inclusion?.groups);
		groupsStringVisible = inclusionGroupsData.length ? 'Groups: ' + inclusionGroupsData.slice(0, 1).join(', ') : '';
		const temp = inclusionGroupsData.slice(1, inclusionGroupsData.length);
		groupsTextCount += temp.length;
		groupsStringHidden = temp.length ? temp.join(', ') : '';

		groupsStringVisible = groupsStringHidden.length && groupsStringVisible.length 
		? groupsStringVisible + ',' : groupsStringVisible;
	}

	// Gathering exclusion groups and users string
	[exclusionString, groupsTextCount] = getExclusionData(groupsTextCount, exclusionString, feed, groupsStringHidden);

	// Gathering inclusion users string
	if(feed?.identity?.inclusion?.users[0]?.id === 'All') {
		usersStringVisible = 'Users: All users';
	}else {
		const inclusionUsersData = transformGroupsData(feed?.identity?.inclusion?.users);
		usersStringVisible = inclusionUsersData.length ? 'Users: ' + inclusionUsersData.slice(0, 1).join(', ') : '';
		const temp = inclusionUsersData.slice(1, inclusionUsersData.length);
		usersTextCount += temp.length;
		usersStringHidden = temp.length ? temp.join(', ') : '';

		usersStringVisible = usersStringHidden.length && usersStringVisible.length 
		? usersStringVisible + ',' : usersStringVisible;
	}

	const groupsText = groupsStringHidden + exclusionString;

	return (
		<Typography>
			<Typography>{groupsStringVisible}{' '}</Typography>
			<DirectoryOrIdentityMoreRenderer 
				text={groupsText} 
				count={groupsTextCount}
			/>
			<Typography>{usersStringVisible}{' '}</Typography>
			<DirectoryOrIdentityMoreRenderer 
				text={usersStringHidden} 
				count={usersTextCount}
			/>
		</Typography>
	)
	}

}

function transformGroupsData(arr) {
	return arr.filter(ele => ele.name != null).map(ele => ele.name).map(ele => ele);
}