import {
	Button,
	DataTable,
	DataTableColumn,
	Alert,
	Typography,
} from '@barracuda-internal/bds-core';
import {
	Avatar,
	Dialog,
	DialogContent,
	DialogTitle,
	List,
	ListItem,
	TableCell,
} from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';
import { orderBy } from '@progress/kendo-data-query';
import React, { ReactElement, useEffect, useState } from 'react';
import FEATURES from 'src/featureMap';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import DeleteWithConfirm from 'src/lib/components/DeleteWithConfirm';
import useAPIEffect from 'src/lib/useAPIEffect';
import withAnalytics from 'src/lib/withAnalytics';
import { ClassifierTypes, ClassifierView } from 'src/types/dataTypes';
import ulog from 'ulog';
import classifierMap from './classifierMap';

const useStyles = makeStyles((theme) => ({
	toolbar: {
		padding: theme.spacing(1),
		textAlign: 'right',
	},
	deleteCell: {
		display: 'flex',
		justifyContent: 'center',
		textAlign: 'center',
	},
}));

export interface ClassifierTableProps {
	accountId: string;
}

function ClassifierTable({ accountId }: ClassifierTableProps): ReactElement {
	const classes = useStyles();
	const { api } = useAppToolchain();

	const [enabledTypes, setEnabledTypes] = useState([]);
	const [error, setError] = useState();
	const [isDirty, setIsDirty] = useState(false);
	const [showStore, setShowStore] = useState(false);
	const [sort, setSort] = useState([]);
	const [limit, setLimit] = useState({});
	const [classifiers, setClassifiers]: [
		ClassifierView[],
		React.Dispatch<ClassifierView[]>,
	] = useState([]);

	const [createClassifier, setCreateClassifier]: [
		ClassifierTypes,
		React.Dispatch<ClassifierTypes>,
	] = useState();

	// The classifier that we are currently editing.
	// Will display in a modal.
	const [editingClassifier, setEditingClassifier]: [
		ClassifierView,
		React.Dispatch<ClassifierView>,
	] = useState();

	const [loadingClassifiers, setLoadingClassifiers] = useState(false);

	useAPIEffect(
		(cancel) => {
			if (cancel) return;

			setLoadingClassifiers(true);
			Promise.all([
				api.getAccountClassifierViews(accountId),
				api.getAccountClassifierTypes(accountId),
			])
				.then((results) => {
					ulog.debug('ClassifierGroup:', results);
					setClassifiers(results[0]);
					setEnabledTypes(results[1]);
					let limits = {};
					results[1].forEach((type) => {
						limits[type.type] = {
							max: type.restrictedCount,
							current: type.count,
						};
					});
					setLimit(limits);
					Object.entries(limits).map(([key, val]) => {
						if (limits[key].current >= limits[key].max) {
							limits[key].disable = true;
						} else {
							limits[key].disable = false;
						}
					});
					setIsDirty(false);
				})
				.catch((err) => {
					setError(err);
				})
				.finally(() => {
					setLoadingClassifiers(false);
				});
		},
		[isDirty],
	);

	useAPIEffect(
		(cancel) => {
			if (cancel || !createClassifier) return;
			let obj: ClassifierView = {
				accountId: accountId,
				created: '',
				disabled: true,
				displayName: '',
				id: '',
				tagName: '',
				type: createClassifier,
				payload: undefined,
				configUrl: '',
				current: limit[createClassifier].current,
				max: limit[createClassifier].max,
			};

			setEditingClassifier(obj);
			setShowStore(false);
			setCreateClassifier(null);
		},
		[createClassifier],
	);

	const EditDialog =
		!!editingClassifier && classifierMap[editingClassifier.type]
			? classifierMap[editingClassifier.type].dialog
			: null;

	const handleClose = () => setEditingClassifier(undefined);
	const handleSave = () => setIsDirty(true);
 
	return (
		<>
			<div className={classes.toolbar}>
				<Button
					onClick={() => setShowStore(true)}
					color="primary"
					variant="contained"
				>
					Add Classifier
				</Button>
			</div>
			{limit['CustomRegexFindingView'] &&
			limit['CustomRegexFindingView'].current >=
				limit['CustomRegexFindingView'].max ? (
					<>
				<Alert color="info">
					Note: You have reached the limit of 10 Regular Expression
					classifiers. To add a new one, please delete or modify an
					existing classifier.
				</Alert>
				<br/>
				</>
			) : null}

			<DataTable
				data={orderBy(
					classifiers.filter(
						(c) =>
							enabledTypes.some((t) => t.type === c.type) &&
							!!classifierMap[c.type],
					),
					sort,
				)}
				onSortChange={(e) => setSort(e.sort)}
				sort={sort}
				sortable
			>
				<DataTableColumn
					cell={({ dataItem }) => (
						<ClassifierTypeCell type={dataItem.type} />
					)}
					field="type"
					title=" "
					width={100}
				/>
				<DataTableColumn
					cell={({ dataItem }) => (
						<TableCell>
							<Typography
								component="span"
								variant="body1"
								color="textPrimary"
							>
								{classifierMap[dataItem.type].name}
							</Typography>
						</TableCell>
					)}
					field="type"
					title="Type"
				/>
				<DataTableColumn field="displayName" title="Name" />
				<DataTableColumn
					cell={({ dataItem }) => (
						<TableCell>
							<Typography
								component="span"
								variant="body1"
								color="textPrimary"
							>
								{dataItem.tagName || 'Not configured yet'}
							</Typography>
						</TableCell>
					)}
					field="tagName"
					title="Label Name"
				/>
				<DataTableColumn
					cell={({ dataItem }) => (
						<TableCell>
							<Typography
								component="span"
								variant="body1"
								color="textPrimary"
							>
								{dataItem.disabled ? 'Disabled' : 'Enabled'}
							</Typography>
						</TableCell>
					)}
					field="disabled"
					title="Status"
				/>
				<DataTableColumn
					cell={({ dataItem }) => (
						<ButtonCell
							classifier={dataItem}
							onEdit={setEditingClassifier}
						/>
					)}
					field="id"
					title=" "
					width={100}
				/>
				<DataTableColumn
					cell={({ dataItem }) => (
						<DeleteCell
							classifier={dataItem}
							onDelete={() => {
								setIsDirty(true);
							}}
						/>
					)}
					field="id"
					sortable={false}
					title=" "
					width={150}
				/>
			</DataTable>
			<Dialog open={showStore} onClose={() => setShowStore(false)}>
				<DialogTitle>
					<Typography
						component="span"
						variant="h3"
						color="textPrimary"
					>
						What kind of classifier would you like to create?
					</Typography>
				</DialogTitle>
				<DialogContent>
					<List>
						{Object.entries(classifierMap).map(([key, val]) => {
							if (limit[key] && !limit[key].disable) {
								return (
									<ListItem
										button
										key={key}
										onClick={withAnalytics(
											FEATURES.settingsClassifierAddClassifierClick,
											() =>
												setCreateClassifier(
													key as ClassifierTypes,
												),
											{
												classifier: key,
											},
										)}
									>
										<Typography
											component="span"
											variant="body1"
											color="textPrimary"
										>
											{val.name}{' '}
											{limit[key] &&
											limit[key].max < 1000 ? (
												<Typography
													component="span"
													variant="body1"
													color="textSecondary"
												>
													{limit[key].current} of{' '}
													{limit[key].max}
												</Typography>
											) : null}
										</Typography>
									</ListItem>
								);
							} else {
								return (
									<ListItem key={key}>
										<Typography
											component="span"
											variant="body1"
											color="textSecondary"
										>
											{val.name}{' '}
											{limit[key] &&
											limit[key].max < 1000 ? (
												<Typography
													component="span"
													variant="body1"
													color="textSecondary"
												>
													{limit[key].current} of{' '}
													{limit[key].max}
												</Typography>
											) : null}
										</Typography>
									</ListItem>
								);
							}
						})}
					</List>
				</DialogContent>
			</Dialog>
			<Dialog
				maxWidth={'sm'}
				open={!!editingClassifier}
				onClose={() => setEditingClassifier(undefined)}
			>
				{!!editingClassifier ? (
					<EditDialog
						accountId={accountId}
						baseClassifier={editingClassifier}
						onClose={handleClose}
						onSave={handleSave}
					></EditDialog>
				) : null}
			</Dialog>
		</>
	);
}

export function ButtonCell({ classifier, onEdit }) {
	return (
		<TableCell>
			<Button
				variant="contained"
				color="primary"
				onClick={withAnalytics(
					FEATURES.settingsClassifierEditButtonClick,
					() => onEdit(classifier),
					{
						type: classifier.type,
						disabled: classifier.disabled,
					},
				)}
			>
				Edit
			</Button>
		</TableCell>
	);
}

export function DeleteCell({ classifier, onDelete }) {
	const { api } = useAppToolchain();
	const classes = useStyles();

	const [doDelete, setDoDelete] = useState(false);
	const [isDeleting, setIsDeleting] = useState(false);

	const handleClick = () => {
		setDoDelete(true);
	};

	useAPIEffect(
		(cancel, signal) => {
			if (!doDelete) return;

			setIsDeleting(true);
			api.del(classifier.configUrl, null, null, false)
				.then(() => {
					if (cancel) return;
					onDelete(classifier);
				})
				.finally(() => {
					if (cancel) return;
					setIsDeleting(false);
				});
		},
		[doDelete],
	);

	return (
		<TableCell>
			<div className={classes.deleteCell}>
				<DeleteWithConfirm
					onClick={withAnalytics(
						FEATURES.settingsClassifierDeleteFirstClick,
						() => {},
						{ classifier: classifier.type },
					)}
					onSecondClick={withAnalytics(
						FEATURES.settingsClassifierDeleteSecondClick,
						handleClick,
						{ classifier: classifier.type },
					)}
					secondMessage="Delete permanently?"
				></DeleteWithConfirm>
			</div>
		</TableCell>
	);
}

export function ClassifierTypeCell({ type }) {
	const Icon = classifierMap[type].icon;
	return (
		<TableCell>
			<Avatar>
				<Icon />
			</Avatar>
		</TableCell>
	);
}

export default ClassifierTable;
