import {
    Button,
    DataTable,
    DataTableColumn,
    Typography
} from '@barracuda-internal/bds-core';
import { makeStyles } from '@material-ui/core/styles';
import { DateTime } from 'luxon';
import React, { ReactElement, useState } from 'react';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
import LoadingIndicator from 'src/lib/components/LoadingIndicator';
import classNames from 'classnames';
import useStyles from 'src/styles/di-theme';
interface Props {}

function AuditLogPage({}: Props): ReactElement {
	const { auth } = useAuth();

	if (!auth) return null;

	return <AuditLogTable />;
}


function AuditLogTable() {
	const { auth } = useAuth();
	const { api } = useAppToolchain();

	const [currentPage, setCurrentPage] = useState(0);

	const classes = useStyles();

	const [status, response] = useApiRequest(
		auth &&
			(() =>
				api.getAccountAudits(
					auth.accountDetails.account.id,
					currentPage,
				)),
		[auth, currentPage],
	);

	if (!response) return <LoadingIndicator />;

	const tableData = response.data.map((log) => {
		return {
			time: log.actionTime,
			category: log.view.category,
			action: log.view.summary,
			by: log?.actor?.email,
		};
	});

	const handleButtonNext = () => setCurrentPage(currentPage + 1);
	const handleButtonBack = () => setCurrentPage(currentPage - 1);
	const handleButtonReset = () => setCurrentPage(0);

	return (
		<div
			className={classNames(
				classes['p_4']
			)}
		>
			<DataTable data={tableData}>
				<DataTableColumn
					cell={({ dataItem }) => {
						return (
							<td>
								<Typography variant="body1">
									{DateTime.fromISO(
										dataItem.time,
									).toLocaleString(DateTime.DATE_FULL)}
								</Typography>
								<Typography variant="body2">
									{DateTime.fromISO(
										dataItem.time,
									).toLocaleString(
										DateTime.TIME_WITH_SHORT_OFFSET,
									)}
								</Typography>
							</td>
						);
					}}
					filterable={false}
					title="Date"
					width="200px"
				/>
				<DataTableColumn
					cell={({ dataItem }) => {
						return (
							<td>
								<Typography>{dataItem.category}</Typography>
							</td>
						);
					}}
					title="Category"
					width="150px"
				/>
				<DataTableColumn
					cell={({ dataItem }) => {
						return (
							<td>
								<Typography style={{ wordWrap: 'break-word' }}>
									{dataItem.action}
								</Typography>
							</td>
						);
					}}
					title="Summary"
				/>
				<DataTableColumn
					cell={({ dataItem }) => {
						return (
							<td>
								<Typography style={{ wordWrap: 'break-word' }}>
									{dataItem.by}
								</Typography>
							</td>
						);
					}}
					title="User"
					width="200px"
				/>
			</DataTable>
			<div>
				<Button disabled={currentPage === 0} onClick={handleButtonBack}>
					Back
				</Button>
				<Button
					disabled={response.data.length < 100}
					onClick={handleButtonNext}
				>
					Forward
				</Button>
				<div></div>
				<Button onClick={handleButtonReset} color="secondary">
					Reset Page
				</Button>
			</div>
		</div>
	);
}

export default AuditLogPage;
