export const highlightToFlatStrategy = (highlight, classHint, componentHint) => {
    let result;
    if (highlight instanceof RegExp) {
        result = [regExpToSAndC(highlight, classHint, componentHint)];
    }
    else if (typeof highlight == "string") {
        result = [stringToSAndC(highlight, classHint, componentHint)];
    }
    else if (typeof highlight == "function") {
        result = [strategyToSAndC(highlight, classHint, componentHint)];
    }
    else if (highlight instanceof Object && "highlight" in highlight) {
        result = customToSAndCs(highlight, classHint, componentHint);
    }
    else if (highlight instanceof Array) {
        if (highlight.length === 2 &&
            typeof highlight[0] === "number" &&
            typeof highlight[1] === "number") {
            result = [rangeToSAndC(highlight, classHint, componentHint)];
        }
        else {
            result = arrayToSAndCs(highlight, classHint, componentHint);
        }
    }
    else {
        throw new TypeError(`Not a Highlight type: ${highlight}`);
    }
    return result;
};
function arrayToSAndCs(highlight, className, component) {
    const sAndCs = highlight.map((h) => highlightToFlatStrategy(h, className, component));
    return Array.prototype.concat.apply([], sAndCs);
}
function strategyToSAndC(strategy, className, component) {
    return {
        strategy,
        component,
        className,
    };
}
function regExpToSAndC(highlight, className, component) {
    const regExpStrategy = (text, callback) => {
        let matchArr, start;
        while ((matchArr = highlight.exec(text)) !== null) {
            start = matchArr.index;
            callback(start, start + matchArr[0].length);
        }
    };
    return {
        strategy: regExpStrategy,
        className,
        component: component,
    };
}
function stringToSAndC(highlight, className, component) {
    const stringStrategy = (text, callback) => {
        const textLower = text.toLowerCase();
        const strLower = highlight.toLowerCase();
        let index = 0;
        while (((index = textLower.indexOf(strLower, index)), index !== -1)) {
            callback(index, index + strLower.length);
            index += strLower.length;
        }
    };
    return {
        strategy: stringStrategy,
        component,
        className,
    };
}
function rangeToSAndC(highlight, className, component) {
    const rangeStrategy = (text, callback) => {
        const low = Math.max(0, highlight[0]);
        const high = Math.min(highlight[1], text.length);
        if (low < high) {
            callback(low, high);
        }
    };
    return {
        strategy: rangeStrategy,
        component,
        className,
    };
}
function customToSAndCs(highlight, className, component) {
    const hl = highlight.highlight;
    className = "className" in highlight ? highlight.className : className;
    component = "component" in highlight ? highlight.component : component;
    return highlightToFlatStrategy(hl, className, component);
}
