import React from 'react';
import { Alert, Typography, Box } from '@mui/material';

function ErrorHandleMessage({errorObj}) {
    if( errorObj == null ) return null;
    let message = errorObj.Detail;
    let code = errorObj.code;
    const getFriendlyMessage = (code) => {
        switch (code) {
            case 400:
                return "Bad Request: The server couldn't understand your request.";
            case 401:
                return "Unauthorized: Please log in to continue.";
            case 403:
                return "Forbidden: You don't have permission to access this resource.";
            case 404:
                return "Not Found: The requested resource couldn't be found.";
            case 409:
                return "Conflict: The request conflicts with the current state of the server.";
            case 500:
                return "Internal Server Error: Something went wrong on our end. Please try again later.";
            case 503:
                return "Service Unavailable: The server is temporarily unavailable. Please try again later.";
            default:
                return "An unexpected error occurred. Please try again later.";
        }
    };
    const getMessage = () => {
        if( message ){
            return message;
        }else if( code ){
            return getFriendlyMessage(code);
        }else{
            return "An unexpected error occurred. Please try again later.";
        }
    }
	return (
        <Box sx={{ mb:2, mx: 'auto', maxWidth: 600 }}>
            {message && (
                <Alert severity="error">
                    <Typography variant="body2">
                        {getMessage()}
                    </Typography>
                </Alert>
            )}
        </Box>
	);
}

export default ErrorHandleMessage;
