import React, { useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Typography,
    Button,
    TextField

} from '@barracuda-internal/bds-core';

import useStyles from 'src/styles/di-theme';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import ErrorHandleMessage from './ErrorHandleMessage';
export default function AddAccount({
    showAdd = false,
    companyName,
    companyId,
    closePopup,
}) {
    const classes = useStyles();
    const { api } = useAppToolchain();
    const [accountName, setAccountName] = useState('');
    const [doAdd, setDoAdd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    useApiRequest(
        doAdd &&
        (() => {
            setLoading(true);
            setError(null);
            return api
                .addAccountToCompany( companyId, accountName.trim() )
                .then((res) => {
                    close(true);
                })
                .catch(async (error)  => {
                    if (error && error.response) {
                        // If the response object exists, get the error message and status code
                        try {
                            const errorData = await error.response.json(); // Extract JSON if available
                            setError(errorData); // Extract message or use fallback
                           // setStatusCode(error.response.status || 500); // Set status cod
                        } catch (err) {
                            setError({message : 'An Unknown error', status : 500});
                        }
                    } else {
                        setError({message : 'An Unknown error', status : 500});
                    }
                }).finally(()=>{
                    setLoading(false);
                    setDoAdd(false)
                })
        }),
        [doAdd]
    );
    const close = (refresh) => {
        setAccountName('');
        setDoAdd(false);
        setError(null);
        closePopup(refresh);
    }

    const handleAccountNameChange = (e) => {
        setAccountName(e.target.value)
    };

    return (
        <div>
            <Dialog open={showAdd} PaperProps={{
                style: {
                    width: '500px', 
                    height: '320px',
                    maxWidth: '500px', 
                    maxHeight: '320px',
                },
            }}>
                <DialogTitle>
                    <Typography variant="h2">
                        Add Tenant for {companyName}
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <ErrorHandleMessage  errorObj={error} ></ErrorHandleMessage>
                    <TextField
                        fullWidth
                        size="small"
                        label="Tenant Name"
                        required
                        onChange={handleAccountNameChange}
                        value={accountName}
                        variant="outlined"
                        inputProps={{ maxLength: 30 }}
                    />

                </DialogContent>
                <DialogActions className={classes['m_1']}>
                    <Button
                        disabled={loading}
                        color="primary"
                        onClick={() => {
                            setLoading(false);
                            close(false);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        isLoading={loading}
                        disabled={accountName.trim() == ''}
                        color="primary"
                        variant="contained"
                        onClick={() => {
                            setDoAdd(true);
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
