import { Button, Typography } from '@barracuda-internal/bds-core';
import useStyles from 'src/styles/di-theme';
import React from 'react';
import Grid from '@material-ui/core/Grid';

function Welcome({ setStep, activeStep }) {
	const classes = useStyles();
	return (
		<>
			<Typography
				variant="h1"
				color="textPrimary"
				gutterBottom
				className={classes['mt_4']}
			>
				Welcome to Data Inspector!
			</Typography>
			<Typography
				variant="subtitle1"
				gutterBottom
				className={classes['mt_2']}
			>
				Barracuda Data Inspector enables you to identify sensitive and
				malicious data that may exist in your environment.
			</Typography>

			<Typography
				variant="subtitle1"
				gutterBottom
				className={classes['mt_2']}
			>
				In order to do that, Barracuda Data Inspector needs to access
				your organization's data, which includes information about all
				users, files, and activity stored in data sources you explicitly
				authorize the product to access.
			</Typography>
			<Typography
				variant="subtitle1"
				gutterBottom
				className={classes['mt_2']}
			>
				Data inspector does not keep or modify your data but does keep
				meta-data such as redactions, events and policy violations.
				Barracuda Data Inspector will retain this meta-data for as long
				as you use the product.
			</Typography>
			<div className={classes['mt_3']}>
				<Grid container direction="row" spacing={1} alignItems="center">
					<Grid item xs={10}></Grid>
					<Grid item xs={2}>
						<Button
							size="large"
							onClick={() => {
								setStep(activeStep + 1);
							}}
							autoFocus
							variant="contained"
							color="primary"
							focusRipple={false}
						>
							Next
						</Button>
					</Grid>
				</Grid>
			</div>
		</>
	);
}

export default Welcome;
