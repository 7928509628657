import { Fade } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement, ReactNode } from 'react';
import Center from 'src/components/Center';
import DILogo from 'src/components/DILogo';
import FullPageError from 'src/components/FullPageError';
import Header from 'src/components/Header';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
import LoadingIndicator from 'src/lib/components/LoadingIndicator';
import BaseLayout from 'src/routes/layouts/BaseLayout';

const useStyles = makeStyles({
	root: {
		// to keep Intercom from being over any buttons
		//paddingBottom: '88px',
	},
});
interface AppLayoutProps {
	children?: ReactNode;
	noSubHeader: boolean;
}

function AppLayout({ children, noSubHeader }: AppLayoutProps): ReactElement {
	const { auth, error } = useAuth();
	const { config } = useAppToolchain();

	const classes = useStyles();

	if (!auth)
		return (
			<BaseLayout>
				<Center>
					<Fade in={true} timeout={2500}>
						<div>
							<>
								<DILogo lightVersion={false} size={512} />
								{error ? (
									<FullPageError message="Authorization failed." />
								) : (
									<LoadingIndicator
										message="Authorizing..."
										loaderSize={100}
									/>
								)}
							</>
						</div>
					</Fade>
				</Center>
			</BaseLayout>
		);

	return (
		<BaseLayout>
			<div className={classes.root}>
				<nav style={{ position: 'relative' }}>
					<Header
						user={auth.user}
						logoutUrl={config.logoutURL}
					/>
				</nav>
				<article>{children}</article>
			</div>
		</BaseLayout>
	);
}

export default AppLayout;
