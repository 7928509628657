import React, { ReactElement, useState } from 'react';
import {
	ConnectorServiceCard,
	ConnectorUsageOptions
} from 'src/components/connectors';
import {
	ConnectorCard,
	ConnectorStatus
} from 'src/components/connectors/ConnectorCard';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
import { DIConnector, DISubscription } from 'src/types/dataTypes';
import sharepoint from '../../static/sharepoint.png';
interface Props extends ConnectorServiceCard {
	usage: ConnectorUsageOptions;
	render?:string
	subtitle?:string
	onStatus?,
	subscription
}

export default function SharepointConnectorCard({
	usage,
	render,
	subtitle,
	onStatus,
	subscription
}: Props): ReactElement {
	const { api } = useAppToolchain();
	const { auth } = useAuth();

	if (!auth || subscription == null) {
		return (
			<ConnectorCard
				logoSrc={sharepoint}
				isLoading={true}
				title="SharePoint"
				subtitle="Entire Tenant"
			></ConnectorCard>
		);
	}

	return (
		<CompanySharePointCard
			subtitle={subtitle}
			subscription={subscription}
			accountId={auth.accountDetails.account.id}
			auth={auth}
			render={render}
			onStatus={onStatus}
		/>
	);
}

function CompanySharePointCard({
	subscription,
	accountId,
	auth,
	render,
	onStatus,
	subtitle = 'Entire Tenant'
}: {
	subscription: DISubscription;
	accountId: string;
	auth:any;
	render?:string;
	onStatus?,
	subtitle?:string;
}) {
	const { api } = useAppToolchain();
	const [createConnector, setCreateConnector] = useState(false);
	
	const [doDisableClick, setDoDisableClick] = useState(false);
	const [disable, setDoDisable] = useState(false);
	const [connectors, setConnectors] = useState<DIConnector[]>([]);
	const [isModifyAllowed, setIsModifyAllowed] = useState(false);

	const { status: connectorStatus } = useApiRequest<DIConnector[]>(
		!!subscription &&
			((signal) =>
				api.getAccountConnectors(
					accountId,
					'SharepointLibraryCollectionConnector',
					{
						signal,
					},
				)),
		[subscription],
		({ result: accountConnectors }) =>{
			if( accountConnectors && accountConnectors[0]){
				let connector = accountConnectors[0];
				setDoDisable(connector.disabled)
				if(onStatus){
					if( connector.provider && connector.provider != null ){
						onStatus(connector.provider?.consented &&  !connector.disabled)
					}
				}
			}
			setConnectors(accountConnectors)
		},
		true,
	);

	useApiRequest(
		connectors && connectors.length >0 &&  connectors[0].id &&  ((signal) => api.getAccountAssignment(auth.accountDetails.account.id, {obj : "/connectors/"+connectors[0].id, operation:"modify"})),
		[connectors],
		([status, resp]) => {
			let currentUserId = auth.user.id;
			let assignments = resp.data
			assignments.forEach( ass => {
				if( ass.subject == currentUserId ){
					setIsModifyAllowed(true);
				}
			})
		}
	);

	const { status: creationStatus, result: createdConnector } =
		useApiRequest<DIConnector>(
			(signal) =>
				createConnector &&
				api.addConnectorToAccountSubscriptions(
					accountId,
					subscription.id,
					{
						type: 'SharepointLibraryCollectionConnector',
						paused: false,
					},
					{ signal },
				),
			[createConnector, accountId, subscription.id],
			({ result: createdConnector, error }) => {
				setCreateConnector(false);
				if (error) return;

				if (createdConnector?.provider?.authorizeUrl) {
					api.getAccessToken().then((token) => {
						const loc = `${createdConnector?.provider?.authorizeUrl}?access_token=${token}&redirectUrl=${window.location.href}`;
						window.location.href = loc;
					});
				}

				setConnectors([...(connectors), createdConnector]);
			},
			true,
		);

		const { status: disableStatus } = useApiRequest<boolean>(
			(signal) =>
				doDisableClick &&
				api.patchConnectorForAccount(accountId, connectors[0].id,[{
					path: '/disabled',
					op: 'replace',
					value: disable,
				}]),
			[doDisableClick],
			() => {
				setDoDisableClick(false);
			},
			true,
		);

	// Three options: no connector, connector, but unauthorized, authorized connector
	let status: ConnectorStatus = 'UNKNOWN';
	if (connectorStatus === 'RESOLVED' && connectors.length) {
		if (connectors[0]?.provider?.consented) {
			status = 'AUTHORIZED';
		} else {
			status = 'UNAUTHORIZED';
		}
	} else {
		status = 'NOT CONNECTED';
	}

	function handleClick() {
		switch (status) {
			case 'NOT CONNECTED':
				setCreateConnector(true);
				break;
			case 'UNAUTHORIZED':
			case 'AUTHORIZED':
				api.getAccessToken().then((token) => {
					const loc = `${connectors[0]?.provider?.authorizeUrl}?access_token=${token}`;
					window.location.href = loc;
				});

			default:
				break;
		}
	}

	function handleDisable(e,checked) {
		setDoDisable( !checked );
		setDoDisableClick(true);
	}

	return (
		<ConnectorCard
			logoSrc={sharepoint}
			isLoading={
				creationStatus === 'RUNNING' || disableStatus === 'RUNNING'
			}
			title="SharePoint"
			subtitle={subtitle}
			status={status}
			onSubmitButtonClick={handleClick}
			onDisableClick={handleDisable}
			isDisabled={disable}
			canModify={isModifyAllowed}
			render={render}
		></ConnectorCard>
	);
}
