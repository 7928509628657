import React, { useContext, useState, useEffect, useRef } from 'react';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
    Typography,
    Button,
    Box,
    IconButton,
} from '@barracuda-internal/bds-core';
import cudaLogo from 'src/static/barracuda_logo_text.svg';
import Close from '@mui/icons-material/Close';
import { TextField, styled } from '@material-ui/core';
import useStyles from 'src/styles/di-theme';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import { SideDetailContext } from './SideDetailPanel';
import useAuth from 'src/hooks/useAuth';
import LoadingIndicator from 'src/lib/components/LoadingIndicator';

import{ convertToPixelCrop } from "react-image-crop";

const StyledTextField = styled(TextField)({
    '&.MuiFormControl-marginNormal': {
        margin: '0px',
    }
});

export default function FalseNegativeFeedbackPopup({
    show = false,
    closePopup = (state) => { },
}) {
    const {
        crop,
        setCrop,
        cropData,
        setCropEnabled,
        setCropIndex,
        croppedImageRef,
        setFeedbackStatus,
        setShowFBConfirm
    } = useContext(SideDetailContext);

    const classes = useStyles();
    const { api } = useAppToolchain();
    const { auth } = useAuth();
    const [loading, setLoading] = useState(false);
    const previewCanvasRef = useRef();

    const [isPopupReady, setPopupReady] = useState(false);
    const [feedback, setFeedback] = useState("");
    const [error, setError] = useState(false);
  
    useEffect(() => {
        if (show) {
            setTimeout(() => setPopupReady(true), 0);
        } else {
            setPopupReady(false);
        }
    }, [show]);
    
    useEffect(() => {
        if (isPopupReady && crop && croppedImageRef.current && previewCanvasRef.current) {
            setCanvasPreview(
                croppedImageRef.current,
                previewCanvasRef.current,
                convertToPixelCrop(
                    crop,
                    croppedImageRef.current.width,
                    croppedImageRef.current.height
                )
            );
        }
    }, [isPopupReady, crop]);

    const submitFalseNegativeFeedback = async () => {
        if (feedback.trim().length === 0) {
            setError(true);
            return;
        }

        setLoading(true);
        const {topLeftCoordinate, bottomRightCoordinate, imageUrl, page, previewWithoutFindings} = cropData;
        const splitUrl = imageUrl.split('api');
        const urlWithSnapshotID = previewWithoutFindings
                                    ? splitUrl[splitUrl.length - 1].split('/page')[0]
                                    : splitUrl[splitUrl.length - 1].split('/preview')[0];
        const { id: userId } = auth.user;

        const reqPayload = {
            category: "FalseNegative",
            subCategory: "DetectionMissing",
            details: feedback,
            pageNumber: page,
            userId,
            findingId: null,
            findingType: null,
            findingClassification: null,
            topLeftCoordinate,
            bottomRightCoordinate
        };
    
        try {
            await api.submitRedactionFeedback(urlWithSnapshotID, reqPayload);
            setFeedbackStatus('falseNegativeSuccess');
            setShowFBConfirm(true);
        } catch (err) {
            setFeedbackStatus('error');
            setShowFBConfirm(true);
            console.error(err.message);
        } finally {    
            setLoading(false);
            resetFormState();
        }
    };

    const resetFormState = () => {
        setCropEnabled(false);
        setCrop(null);
        setCropIndex(null);
        closePopup(false); 
        croppedImageRef.current = null;
        previewCanvasRef.current = null;
        setFeedback("");
    }

    const setCanvasPreview = (
		image,
		canvas,
		crop
	  ) => {
		const ctx = canvas.getContext("2d");
		if (!ctx) {
		  throw new Error("No 2d context");
		}
		const pixelRatio = window.devicePixelRatio;
		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
	  
		canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
		canvas.height = Math.floor(crop.height * scaleY * pixelRatio);
	  
		ctx.scale(pixelRatio, pixelRatio);
		ctx.imageSmoothingQuality = "high";
		ctx.save();
	  
		const cropX = crop.x * scaleX;
		const cropY = crop.y * scaleY;
	  
		ctx.translate(-cropX, -cropY);
		ctx.drawImage(
		  image,
		  0,
		  0,
		  image.naturalWidth,
		  image.naturalHeight,
		  0,
		  0,
		  image.naturalWidth,
		  image.naturalHeight
		);
	  
		ctx.restore();
	  };

      const handleInfoChange = (event) => {
        setFeedback(event.target.value);
      }
      
      const handleBlur = () => {
        const containsOnlySpace = /^\s+$/.test(feedback);
        if(feedback.length === 0 || containsOnlySpace) {
            setError(true);
        }else {
            setError(false);
        }
    
        setFeedback(feedback.trim());
      }

    return (
        <div>
            <Dialog open={show}>
                <DialogTitle>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                        }}
                    >
                        <img
                            src={cudaLogo}
                            alt="Barracuda Data Inspector"
                            height={'36px'}
                        />

                        <Box sx={{ flexGrow: 1 }}></Box>
                        <IconButton
                            aria-label="Close"
                            onClick={() => {
                                closePopup(false);
                                resetFormState();
                                setError(false);
                            }}
                            edge="end"
                            size="small"
                        >
                            <Close />
                        </IconButton>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Typography variant="h2" style={{fontFamily: 'Roboto', fontWeight: 400, fontSize: '32px', marginBottom: '20px'}}>
                        Detection feedback
                    </Typography>
                    <Typography variant="body1">
                        Thank you for helping us improve the accuracy of our data classification.
                    </Typography>

                        <div
                            style={{
                            width: 'auto',
                            maxHeight: '225px',
                            overflow: 'scroll',
                            border: "1px solid #DDDDDD",
                            margin: "10px 0 20px 0"
                            }}
                        >
                            <canvas
                                ref={previewCanvasRef}
                                style={{
                                    width: '100%'
                                }}
                            />
                        </div>

                        <StyledTextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            multiline
                            minRows={3}
                            helperText="Please provide details about what was missed or what should have been detected."
                            FormHelperTextProps={{
                                style: {
                                    color: error ? '#DE3131' : '#828282',
                                    fontWeight: error ? 800 : 400,
                                },
                            }}
                            onChange={handleInfoChange}
                            onBlur={handleBlur}
                            value={feedback}
                            error={error}
                            size="small"
                            maxRows={3}
                        />
                        
                </DialogContent>

                {loading && <LoadingIndicator style={{padding: '10px'}} message="Submitting feedback" />}
                {!loading && <DialogActions className={classes['m_1']}>
                    <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        disabled={false}
                        onClick={submitFalseNegativeFeedback}
                    >
                        Submit
                    </Button>
                </DialogActions>}
            </Dialog>
        </div>
    );
}
