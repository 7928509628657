import { DependencyList, useEffect } from 'react';

/**
 * @deprecated Use useApiRequest instead
 */
function useAPIEffect(
	apiEffect: (boolean, AbortSignal) => void | React.EffectCallback,
	dependencies: DependencyList = [],
): [() => void] {
	const controller = new AbortController();
	const cancel = () => controller.abort();

	useEffect(() => {
		let cleanup: void | React.EffectCallback;

		try {
			cleanup = apiEffect(controller.signal.aborted, controller.signal);
		} catch (error) {
			if (error.name !== 'AbortError') throw error;
		}

		return () => {
			cancel();
			if (cleanup) cleanup();
		};
	}, dependencies);

	return [cancel];
}

export default useAPIEffect;
