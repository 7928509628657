import { Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import {
    Magnifier,
    MOUSE_ACTIVATION,
    TOUCH_ACTIVATION
} from 'react-image-magnifiers';
import CodeusErrorBoundary from 'src/components/CodeusErrorBoundary';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAPIEffect from 'src/lib/useAPIEffect';
import {Feed, Preview, Tag} from 'src/types/dataTypes';
import { ClassificationCell } from './ClassificationCell';
import { CloudTypeCell } from './CloudTypeCell';
import { DateCell } from './DateCell';
import { UserCell } from './UserCell';
import { SecurityReport } from 'src/components/SecurityReport';

const useStyles = makeStyles(theme => ({
	root: ({ inModal }: { inModal: boolean }) => ({
		display: 'flex',
		flex: 1,
		// This is to avoid clashing with intercom
		height: inModal ? '75vh' : '85vh',
		position: 'relative',
		overflow: 'hidden',
	}),
	sidebar: {
		position: 'relative',
		display: 'flex',
		flex: '0 0 300px',
		flexDirection: 'column',
		backgroundColor: '#f7f7f7',
		boxShadow: 'rgba(0,0,0,.25) 0 0 35px',
		zIndex: 2,
		padding: '8px',
		'& ul': {
			listStyle: 'none',
			margin: 0,
			padding: 0,
		},
	},
	column: {
		display: 'flex',
	},
	hidden: {
		display: 'none',
	},
	navigationButton: {
		background: 'none',
		margin: 0,
		border: 'none',
		outline: 'none',
		'& svg': {
			fontSize: '6em',
		},
	},
	previewControls: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'row',
		height: '100%',
	},
	previewContainer: {
		maxHeight: '80%',
		height: '80%',
	},
	previewWrapper: {
		alignItems: 'center',
		display: 'flex',
		justifyContent: 'center',
		minWidth: '640px',
		'& img': {
			width: 'auto',
			height: 'auto',
			boxShadow: '0px 0px 35px rgba(0, 0, 0, 0.25)',

			// These would change zoom levels
			maxWidth: '75%',
			maxHeight: '100vh',
		},
	},
	previewInnerWrapper: {
		height: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	shadowText: {
		color: 'white',
		textShadow: 'black 0 0 5px',
	},
	loading: {
		animation: 'alertPulse 4s ease-out',
		animationIterationCount: 'infinite',
		background: '#gray',
	},
	viewport: {
		flex: 1,
		flexDirection: 'column',
		height: '100%',
		position: 'relative',
		backgroundColor: '#7f7f82',
		overflowY: 'auto',
		padding: theme.spacing(4),
	},
	table: {
		fontSize: '13px',
		borderCollapse: 'collapse',
		'& th': {},
		'& th, & td': {
			textAlign: 'left',
			borderBottom: 'thin solid lightgray',
			padding: '8px',
		},
	},
	comingSoonWrapper: {
		position: 'absolute',
		bottom: 0,
		left: 24,
		'& span': {
			position: 'absolute',
			left: 32,
			top: 32,
			fontSize: '4.5em',
			transform: 'rotate(-45deg)',
			color: 'black',
			opacity: '.25',
			fontWeight: 'bold',
		},
	},
}));

export function CaseViewer({
	caseData,
	inModal = false,
	startingPage = 1,
}: {
	caseData: Feed;
	inModal?: boolean;
	startingPage?: number;
}): React.ReactElement {

	const feedId = caseData.id;
	const tags = caseData.tags;

	// If there is a report with a snapshot, we will take the first one
	// we find and display a button to show the report.
	const tagsWithReport: Tag[] = tags.filter((t: Tag) => t.hasReport);

	if (!caseData.previews.length)
		return <p>There are no previews of this case</p>;

	// This is the number of images that will be loaded
	// in either direction from the current page.
	const PRELOAD_COUNT = 2;

	const getVisiblePreviews = basePageNumber => {
		const firstVisiblePreview = Math.max(
			basePageNumber - PRELOAD_COUNT - 1,
			0,
		);

		let lastVisiblePreview = Math.min(
			basePageNumber + PRELOAD_COUNT,
			caseData.previews.length,
		);
		if (lastVisiblePreview < 0) lastVisiblePreview = 0;

		// console.log(caseData.previews);
		console.log(
			`Base is ${basePageNumber}... Will load between ${firstVisiblePreview} and ${lastVisiblePreview}`,
		);

		return caseData.previews.slice(firstVisiblePreview, lastVisiblePreview);
	};

	// This is the first page we want to display to the user.
	// To do this, we will create placeholders for every preview
	// leading up to the one we want to see, but the images in those
	// placeholders will only be loaded when the user
	// scrolls into proximity of those containers.
	let realStartingPage = startingPage;
	if (startingPage > caseData.previews.length || startingPage < 1) {
		realStartingPage = 1;
	}

	const [focusPage, setFocusPage] = useState(realStartingPage);

	// Creates a ref to be used for each page, even if the page
	// is not currently in use. This will create a consistent
	// reference for us to use for smart scrolling, etc...
	const [pageRefs] = useState(
		caseData.previews.reduce((acc, preview) => {
			acc[preview.id] = React.createRef();
			return acc;
		}, {}),
	);

	const handleInView = (page: Preview, entry: IntersectionObserverEntry) => {
		if (entry.isIntersecting) {
			const pageIndex = caseData.previews.findIndex(
				p => p.id === page.id,
			);

			if (pageIndex !== -1) setFocusPage(pageIndex + 1);
		}
	};

	// Scroll to the starting page
	// TODO : Enable along with routing to a page
	// useEffect(() => {
	// 	// FIX ME: This doesn't work for all ranges
	// 	const targetIndex = Math.max(firstPage - PRELOAD_COUNT + 1, 1);
	// 	console.log(
	// 		firstPage,
	// 		firstVisiblePreview,
	// 		targetIndex,
	// 		visiblePreviews,
	// 	);

	// 	const target = visiblePreviews[targetIndex].id;
	// 	const targetRef = visiblePreviewRefs[target].current;
	// 	targetRef.scrollIntoView({
	// 		behavior: 'smooth',
	// 		block: 'center',
	// 		inline: 'nearest',
	// 	});
	// }, []);

	const classes = useStyles({ inModal });

	return (
		<div className={classes.root}>
			<div className={`${classes.viewport}`}>
				{getVisiblePreviews(focusPage).map(page => (
					<CaseViewerPageWrapper
						key={page.id}
						page={page}
						pageRef={pageRefs[page.id]}
						handleInView={handleInView}
					/>
				))}
			</div>

			<aside className={`${classes.column} ${classes.sidebar}`}>
				<table className={classes.table}>
					<tbody>
						<tr>
							<th>User</th>
							<UserCell user={caseData.user} />
						</tr>
						<tr>
							<th>Date Modified</th>
							<DateCell lastModified={caseData.lastModified} />
						</tr>
						<tr>
							<th>Classifications</th>
							<ClassificationCell tags={caseData.tags} />
						</tr>
						<tr>
							<th>Source</th>
							<CloudTypeCell cloudType={caseData.cloudType} />
						</tr>
						<tr>
							<th>Item Path</th>
							<td>{caseData.itemPath}</td>
						</tr>
						{tagsWithReport.length ?
							<tr>
								<th>Report</th>
								<td>
									<SecurityReport feedId={feedId} tagsWithReport={tagsWithReport}/>
								</td>
							</tr>
							: null
						}
					</tbody>
				</table>
			</aside>
		</div>
	);
}

const caseViewerPageWrapperStyles = makeStyles(theme => {
	const headerSize = theme.spacing(6);
	return {
		root: {
			backgroundColor: 'white',
			marginBottom: theme.spacing(4),
			position: 'relative',
			padding: headerSize,
			objectFit: 'contain',
		},
		header: {
			position: 'absolute',
			width: '100%',
			top: 0,
			left: 0,
			height: headerSize,
		},
		magnifier: {
			height: '100%',
			minHeight: '50vh',
			position: 'relative',
			'& > div': {
				height: '100%',
				'& > img': {
					objectFit: 'contain',
					margin: '0 auto',

					// Has to be !important because the zoom library adds styling
					height: '90% !important',
					width: '90% !important',
				},
			},
		},
		expired: {
			height: '90%',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		loader: {
			position: 'absolute',
			left: 0,
			width: '100%',
			height: '100%',
			zIndex: 2,
			backgroundColor: 'white',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
	};
});

/**
 * This component creates the actual area previews are constrained to.
 */
export function CaseViewerPageWrapper({
	page,
	pageRef,
	handleInView,
}: {
	page: Preview;
	pageRef: React.RefObject<Element>;
	handleInView: (page: Preview, entry: IntersectionObserverEntry) => void;
}): React.ReactElement {
	const classes = caseViewerPageWrapperStyles();
	const [isLoaded, setIsLoaded] = useState(false);
	const [authedPageUrl, setAuthedPageUrl] = useState<string>();

	const { api, config } = useAppToolchain();

	useEffect(() => {
		const observer = new IntersectionObserver(
			([entry]) => {
				handleInView(page, entry);
			},
			{
				rootMargin: '200px',
				threshold: 0.3,
			},
		);

		observer.observe(pageRef.current);
		const refCopy = pageRef.current;

		return () => {
			observer.unobserve(refCopy);
		};
	}, []);

	const expired = page.expiration && new Date(page.expiration) < new Date();

	useAPIEffect(() => {
		api.getAccessToken().then((access_token) => {
			const url = new URL(page.url);
			const params = new URLSearchParams(url.search.slice(1));

				// add access token to query params
				params.append('access_token', access_token);

				// add the query params back to the url
				url.search = params.toString();

				// save the authed page url
				setAuthedPageUrl(url.href);
			});
	});

	return (
		<Paper className={classes.root} elevation={12} ref={pageRef}>
			<>
				<header className={classes.header}>Preview {page.id}</header>
				<Paper elevation={1} style={{ position: 'relative' }}>
					<CodeusErrorBoundary>
						{expired ? (
							<div className={classes.expired}>
								<h1>This preview has expired!</h1>
							</div>
						) : (
							<>
								{!isLoaded && (
									<div className={classes.loader}>
										<h1>Your Preview Is Loading</h1>
									</div>
								)}
								<Magnifier
									className={classes.magnifier}
									imageSrc={authedPageUrl}
									onImageLoad={() => setIsLoaded(true)}
									mouseActivation={MOUSE_ACTIVATION.CLICK}
									touchActivation={TOUCH_ACTIVATION.TAP}
									dragToMove={true}
								></Magnifier>
							</>
						)}
					</CodeusErrorBoundary>
				</Paper>
			</>
		</Paper>
	);
}

export default CaseViewer;
