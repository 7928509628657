import React from 'react';
import {
	makeStyles,
	createStyles,
	SvgIcon,
	SvgIconProps,
} from '@material-ui/core';

export type BarracudaIconProps = SvgIconProps;

export const useStyles = makeStyles(
	createStyles({
		primaryFill: {
			fill: '#333740',
		},
	}),
);

export function BarracudaIcon(props: BarracudaIconProps) {
	const classes = useStyles(props);

	return (
		<SvgIcon id="icon-barracuda" viewBox="0 0 24 24" {...props}>
			<g>
				<path
					className={classes.primaryFill}
					d="M5.42,20H1.09c0-6.75,1.84-11.04,1.84-11.04C2.34,16.22,5.42,20,5.42,20z M22.91,4c0,0-13.75,5.33-16.29,16
                        h11.57C18.19,20,9.87,17.86,22.91,4z"
				/>
			</g>
		</SvgIcon>
	);
}
