import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import msLogo from 'src/static/ms-logo.svg';

// see: https://docs.microsoft.com/en-us/azure/active-directory/develop/howto-add-branding-in-azure-ad-apps
const useStyles = makeStyles({
	root: ({ disabled }: { disabled: boolean }) => ({
		padding: '12px',
		display: 'flex',
		flexDirection: 'row',
		border: '1px solid #8C8C8C',
		background: disabled ? '#BCBCBC' : '#FFFFFF',
		color: '#5E5E5E',
		fontSize: '15px',
		fontWeight: 600,
		justifyContent: 'center',
		alignItems: 'center',
		margin: '12px',
		cursor: disabled ? 'not-allowed' : 'pointer',
	}),
	
});

export default function MicrosoftSignInButton(
	props: React.DetailedHTMLProps<
		React.ButtonHTMLAttributes<HTMLButtonElement>,
		HTMLButtonElement
	>
): React.ReactElement {
	const classes = useStyles({ disabled: props?.disabled });
	return (
		<button className={classes.root} {...props}>
			<span>Sign In With Barracuda</span>
		</button>
	);
}
