import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    DataTable,
    DataTableColumn,
    DataTableToolbar,
    Typography
} from '@barracuda-internal/bds-core';
import { Container, MenuItem, Select, TableCell } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement, useState } from 'react';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
import { roleMap } from 'src/routes/settings/users/roleMap';
import { UserAssignment, UserDetail } from 'src/types/dataTypes';
import { isBCCRole } from 'src/utils/auth';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
	root: {
		margin: '16px',
	},
}));

interface Props {
	onCancel: () => void;
	onChange: (userDetail: UserDetail) => void;
	onSave: () => void;
	userDetail: UserDetail;
}

interface UserForm {
	accountRoles: { [K in keyof typeof roleMap]: boolean };
}

function EditUserDialog({
	onCancel,
	onChange,
	userDetail,
}: Props): ReactElement {
	const { api } = useAppToolchain();
	const { auth } = useAuth();
	const classes = useStyles();

	const [doDelete, setDoDelete] = useState<UserAssignment>();
	const [localUserDetail, setLocalUserDetail] =
		useState<UserDetail>(userDetail);

	const assignedRoles = localUserDetail.assignments.filter((a) =>
		a.domain.startsWith(`/accounts/${auth.accountDetails.account.id}`),
	);

	const unassignedRoles = Object.keys(roleMap).filter(
		(rm) => !assignedRoles.some((ar) => (ar.role === rm )) && !isBCCRole(rm)
	);

	const handleAssignment = (assignment: UserAssignment) => {
		localUserDetail.assignments.push(assignment);
		setLocalUserDetail({
			...localUserDetail,
		});
		onChange(localUserDetail);
	};

	const handleDelete = (role: string) => {
		const deleteRoles = assignedRoles.filter((r) => r.role === role);
		deleteRoles.length && setDoDelete(deleteRoles[0]);
	};

	useApiRequest(
		!!doDelete &&
			((signal) =>
				api.deleteUserAssignment(
					auth.accountDetails.account.id,
					doDelete,
					{
						signal,
					},
				)),
		[doDelete],
		([_, __, err]) => {
			const newLocalDetail = {
				...localUserDetail,
				assignments: localUserDetail.assignments.filter(
					(a) => a.role !== doDelete.role,
				),
			};
			setLocalUserDetail(newLocalDetail);
			onChange(newLocalDetail);
			setDoDelete(null);
		},
	);

	const RoleCell = ({ dataItem }) => {
		return <TableCell>{roleMap[dataItem.role]}</TableCell>;
	};

	const ControlCell = ({ dataItem }) =>  (


		
		<TableCell>
			{
				!isBCCRole(dataItem.role) ? <Button
				onClick={() => handleDelete(dataItem.role)}
				color="secondary"
			>
				Remove
			</Button> : null
			}
			
		</TableCell>
	);

	return (
		<Container>
			<Card className={classes.root}>
				<CardHeader
					title={userDetail.user.name}
					subheader={userDetail.user.email}
				/>
				<CardContent>

					<Typography variant="h6">Permissions</Typography>
					<Alert color="info">
					User roles originate from Barracuda Cloud Control and are modifiable within the Barracuda Cloud Control System.
					</Alert>
					<DataTable data={assignedRoles}>
						<DataTableToolbar>
							<RoleSelector
								unassignedRoles={unassignedRoles}
								userDetail={localUserDetail}
								onSave={handleAssignment}
							/>
						</DataTableToolbar>
						<DataTableColumn
							field="role"
							cell={RoleCell}
						></DataTableColumn>
						<DataTableColumn
							field="role"
							title=""
							cell={ControlCell}
							width="150px"
						></DataTableColumn>
					</DataTable>
					{/* {Object.entries(roleMap).map(([key, value]) => {
							return (
								<FormControlLabel
									key={key}
									label={value}
									control={
										<Checkbox
											name={key}
											onChange={handleAssignmentChange}
											checked={
												formState.accountRoles[key]
											}
										/>
									}
								></FormControlLabel>
							);
						})} */}
				</CardContent>
				<CardActions>
					<Button color="secondary" onClick={onCancel}>
						Close
					</Button>
				</CardActions>
			</Card>
		</Container>
	);
}

function RoleSelector({
	onSave,
	unassignedRoles,
	userDetail,
}: {
	onSave: (result: UserAssignment) => void;
	unassignedRoles: string[];
	userDetail: UserDetail;
}) {
	const { api } = useAppToolchain();
	const { auth } = useAuth();

	const [doSave, setDoSave] = useState(false);
	const [selectedRole, setSelectedRole] = useState('');

	const [saveStatus] = useApiRequest(
		() =>
			doSave &&
			api.addUserAssignment(auth.accountDetails.account.id, {
				role: selectedRole,
				subject: userDetail.user.id,
			}),
		[doSave],
		([_, res]) => {
			setDoSave(false);
			setSelectedRole('');
			res && onSave(res);
		},
	);

	const handleChange = (e) => setSelectedRole(e.target.value);
	const handleSave = () => {
		setDoSave(true);
	};

	if (!unassignedRoles.length) return null;

	return (
		<div>
			<Select value={selectedRole} onChange={handleChange} displayEmpty>
				<MenuItem value="" disabled>
					Choose A Role
				</MenuItem>
				{unassignedRoles.map((role) => (
					<MenuItem value={role} key={role}>
						{roleMap[role]}
					</MenuItem>
				))}
			</Select>
			<Button
				type="button"
				onClick={handleSave}
				isLoading={saveStatus === 'RUNNING'}
				disabled={saveStatus === 'RUNNING' || !selectedRole}
			>
				Add
			</Button>
		</div>
	);
}

export default EditUserDialog;
