import React from 'react';
import { render } from 'react-dom';
import ulog from 'ulog';
import App from './App';
import AppLoader from './AppLoader';

// Sets global logging levels
ulog.level = process.env.NODE_ENV === 'development' ? ulog.DEBUG : ulog.ERROR;

render(<AppLoader app={App} />, document.getElementById('main'));
