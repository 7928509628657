import React, { ReactElement, useEffect } from 'react';
import { useAppToolchain } from 'src/hooks/useAppToolchain';

interface Props {}

function OidcSignout({}: Props): ReactElement {
	const { oidcUserManager } = useAppToolchain();

	useEffect(() => {
		oidcUserManager.getUser().then(user => {
			let args = {};
			if (user) {
				args = {
					id_token_hint: user.id_token,
				};
			}

			oidcUserManager.clearStaleState();
			oidcUserManager.removeUser();

			oidcUserManager.signoutRedirect(args);
		});
	}, []);

	return <p>Logging you out...</p>;
}

export default OidcSignout;
