import { ApiNetworkFunctions, PagedResponse } from 'src/networking/api/apiTypes';
import { User } from 'src/types/dataTypes';

export interface AuditDetails {
	id: string;
	action: string;
	target: string;
	actor: AuditActor;
	actionTime: string;
	endpoint: string;
	details: {
		operations: AuditOperation[];
	};
	error?: string;
	comments?: string[];
	view?: {
		category: string;
		filter: boolean;
		summary: string;
	};
}

export interface AuditActor extends User {
	remoteId: string;
}

export interface AuditOperation {
	value: string;
	path: string;
	op: string;
}

export function getAccountAudits(
	api: ApiNetworkFunctions,
	accountId: string,
	pageSize: number = 100,
	pageNumber: number = 0,
	options?: RequestInit,
): Promise<PagedResponse<AuditDetails>> {
	return api.get<PagedResponse<AuditDetails>>(
		`/accounts/${accountId}/audits`,
		{ pageSize, pageNumber },
		options,
	);
}
