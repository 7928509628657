import { Avatar, Chip, TableCell } from '@material-ui/core';
import React from 'react';
import { Tag } from 'src/types/dataTypes';


export interface ClassificationCellProps {
	tags: Tag[];
	handleClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export function ClassificationChipList({
	handleClick,
	tags,
}: ClassificationCellProps): React.ReactElement {
	return (
		<ul>
			{tags.map(tag => (
				<li key={tag.classification}>
					<Chip
						avatar={
							<Avatar style={{ backgroundColor: 'white' }}>
								{tag.count}
							</Avatar>
						}
						clickable={true}
						label={tag.text}
						onClick={handleClick}
						size="small"
						style={{
							backgroundColor: tag.fillColor,
							color: 'white',
							fontStyle: 'bold',
							margin: '2px',
						}}
					/>
				</li>
			))}
		</ul>
	);
}

export function ClassificationCell(
	props: ClassificationCellProps,
): React.ReactElement {
	return (
		<TableCell align="left">
			<ClassificationChipList {...props} />
		</TableCell>
	);
}
