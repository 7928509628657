import { Button, Typography } from '@barracuda-internal/bds-core';
import Switch, { SwitchLabel } from '@barracuda-internal/bds-core/dist/Switch';
import TextField from '@barracuda-internal/bds-core/dist/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
import LoadingIndicator from 'src/lib/components/LoadingIndicator';
import { CodeusAccountConfig } from 'src/types/dataTypes';
import ulog from 'ulog';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

interface FormState {
	sendDigests: boolean;
	sendEndUserAlerts: boolean;
	showSenders: boolean;
	emailDestinations: string;
	sendRemediationAlerts: boolean;
	alertsBatchingHourlyInterval: number
}

const useStyles = makeStyles((theme) => ({
	section: {
		padding: '16px',
		'& div': {
			marginTop: '8px',
		},
	},
}));

function formToConfig(
	originalConfig: CodeusAccountConfig,
	formState: FormState,
): CodeusAccountConfig {
	const config = {
		...originalConfig,
		alertConfiguration: {
			...originalConfig.alertConfiguration,
			sendEndUserAlerts: formState.sendEndUserAlerts,
		},
		digestConfiguration: {
			...originalConfig.digestConfiguration,
			sendDigests: formState.sendDigests,
		},
	};

	if (
		formState.sendDigests &&
		formState.showSenders &&
		formState.emailDestinations
	) {
		config.digestConfiguration.emailDestinations =
			formState.emailDestinations.trim().split('\n');
	}

	return config;
}

export function EmailSettingsForm() {
	const { api } = useAppToolchain();
	const { auth } = useAuth();
	const classes = useStyles();

	const [doSave, setDoSave] = useState(false);
	const [validEmail, setValidEmail] = useState(true);
	const [formState, setFormState] = useState<FormState>();
	const [originalConfig, setOriginalConfig] = useState<CodeusAccountConfig>();

	const [showResultBanner, setShowResultBanner] = useState(false);
	const [showValidationBanner, setShowValidationBanner] = useState(false);

	const checkValidEmails =  (emails: string[]) => {
		
		const regexPattern = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
		for(let index in emails) {
			if(!regexPattern.test(emails[index])) {
				setValidEmail(false);
				return;
			}
		}
		if( emails.length == 0 ){
			setValidEmail(false);
		}else{
			setValidEmail(true);
		}
	};

	const [configDownloadState] = useApiRequest<CodeusAccountConfig>(
		(signal) =>
			api.getAccountConfig(auth.accountDetails.account.id, { signal }),
		[],
		([_, configResult, configErr]) => {
			ulog.debug('SETTINGS: Config retrieved', configResult, configErr);

			setOriginalConfig(configResult);
			setFormState({
				sendDigests: configResult.digestConfiguration.sendDigests,
				sendEndUserAlerts:
					configResult.alertConfiguration.sendEndUserAlerts,
				sendRemediationAlerts:
					configResult.alertConfiguration.sendRemediationAlerts,
				alertsBatchingHourlyInterval:
					configResult.alertConfiguration.alertsBatchingHourlyInterval,
				showSenders:
					!!configResult.digestConfiguration?.emailDestinations
						?.length,
				emailDestinations: configResult.digestConfiguration
					?.emailDestinations
					? configResult.digestConfiguration.emailDestinations.join(
							'\n',
					  )
					: '',
			});
		},
	);

	const [saveState, saveResult, saveError] =
		useApiRequest<CodeusAccountConfig>(
			doSave &&
				((signal) => {
					const patchObj = [
						{
							path: '/digestConfiguration/sendDigests',
							op: 'replace',
							value: formState.sendDigests,
						},
						{
							path: '/alertConfiguration/sendEndUserAlerts',
							op: 'replace',
							value: formState.sendEndUserAlerts,
						},
						{
							path: '/alertConfiguration/sendRemediationAlerts',
							op: 'replace',
							value: formState.sendRemediationAlerts,
						},
						{
							path: '/alertConfiguration/alertsBatchingHourlyInterval',
							op: 'replace',
							value: formState.alertsBatchingHourlyInterval,
						},
						{
							path: '/digestConfiguration/emailDestinations',
							op: 'replace',
							value:
								formState.sendDigests &&
								formState.emailDestinations &&
								formState.showSenders
									? formState.emailDestinations.trim().split('\n').map(s => s.trim())
									: [],
						},
					];

					return api.saveAccountConfig(
						auth.accountDetails.account.id,
						patchObj,
						{ signal },
					);
				}),
			[doSave],
			([_, saveResult, saveErr]) => {
				ulog.debug('SETTINGS: Save resulted in', saveResult, saveErr);

				setShowResultBanner(true);
				setDoSave(false);

				const cancel = setTimeout(
					() => setShowResultBanner(false),
					5000,
				);
				return () => clearTimeout(cancel);
			},
		);

	if (configDownloadState === 'RUNNING' || !formState)
		return <LoadingIndicator />;

	const handleSendDigestChange = (e: React.ChangeEvent<HTMLInputElement>) =>
		setFormState({ ...formState, sendDigests: e.target.checked });

	const handleAlertChange = (e: React.ChangeEvent<HTMLInputElement>) =>
		setFormState({ ...formState, sendEndUserAlerts: e.target.checked });

	const handleRemediationAlertChange = (e: React.ChangeEvent<HTMLInputElement>) =>
		setFormState({ ...formState, sendRemediationAlerts: e.target.checked });

	const handleEmailChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
		checkValidEmails(e.target.value.trim().split("\n").map(s => s.trim()));
		setFormState({ ...formState, emailDestinations: e.target.value });

	};
	const handleShowSenders = (e: React.ChangeEvent<HTMLInputElement>) => {
		checkValidEmails(	formState.emailDestinations ? formState.emailDestinations.trim().split('\n').map(s => s.trim()) : []);
		setFormState({ ...formState, showSenders: e.target.checked });
	};
	const handleBatchingIntervalChange = (e: SelectChangeEvent<string>) => {
		setFormState({ ...formState, alertsBatchingHourlyInterval: parseInt(e.target.value, 10) });
	};

	const handleSaveClick = () => {
		if( !formState.showSenders || ( formState.showSenders &&  validEmail) ) {
			setShowValidationBanner(false);
			setDoSave(true);
		} else {
			setShowResultBanner(false);
			setShowValidationBanner(true);
		}
	};

	return (
		<>
			<Typography variant="h5">Administrator Emails</Typography>
			{showResultBanner && saveState === 'RESOLVED' && (
				<Alert severity="success">Save successful!</Alert>
			)}
			{showResultBanner && saveState === 'REJECTED' && (
				<Alert severity="error">Save failed!</Alert>
			)}
			{showValidationBanner && !validEmail && (
				<Alert severity="error">Please enter valid email addresses</Alert>
			)}
			<section className={classes.section}>
				<span data-intercom-target="Send Admin Email Digests Toggle">
					<SwitchLabel
						label={'Send a daily email digest to all admins'}
						control={
							<Switch
							color="primary"
								checked={formState.sendDigests || false}
								onChange={handleSendDigestChange}
							/>
						}
					/>
				</span>
				{formState.sendDigests ? (
					<div>
						<span data-intercom-target="Send Custom Digest Toggle">
							<SwitchLabel
								label={'Additional recipients for the daily email digest'}
								control={
									<Switch
									color="primary"
										checked={formState.showSenders}
										onChange={handleShowSenders}
									/>
								}
							/>
						</span>
					</div>
				) : null}
				{formState.showSenders && formState.sendDigests ? (
					<div>
						<div>
							<TextField
								multiline
								fullWidth
								helperText="Put each email on its own line."
								label="Admin email addresses to receive daily digest"
								placeholder=""
								onChange={handleEmailChange}
								value={formState.emailDestinations}
							/>
						</div>
					</div>
				) : null}
			</section>
			<Typography variant="h5">End-User Notifications</Typography>
			<section className={classes.section}>
				<div data-intercom-target="Send End User Email Toggle">
					<SwitchLabel
						label={'Send violation alerts to end users'}
						control={
							<Switch
							color="primary"
								checked={formState.sendEndUserAlerts || false}
								onChange={handleAlertChange}
							/>
						}
					/>
				</div>

				<div data-intercom-target="Send End User Remediation Toggle">
					<SwitchLabel
						label={'Send remediation notifications to end users'}
						control={
							<Switch
							color="primary"
								checked={formState.sendRemediationAlerts || false}
								onChange={handleRemediationAlertChange}
							/>
						}
					/>
				</div>
			</section>

			<Typography variant="h5">Enable batching in end-user notifications with interval</Typography>
			<section className={classes.section}>
				<FormControl sx={{ m: 1, minWidth: 300 }} size="small">
					<Select
						labelId="batch-select-label"
						id="batch-simple-select"
						value={ String(formState.alertsBatchingHourlyInterval) }
						onChange={handleBatchingIntervalChange}
						disabled ={(!formState.sendRemediationAlerts && !formState.sendEndUserAlerts)}
					>
						<MenuItem value={0}>Realtime</MenuItem>
						<MenuItem value={1}>1 hour</MenuItem>
						<MenuItem value={3}>3 hours</MenuItem>
						<MenuItem value={6}>6 hours</MenuItem>
						<MenuItem value={12}>12 hours</MenuItem>
					</Select>
				</FormControl>
			</section>

			<section>
				<footer>
					<Button onClick={handleSaveClick}
					variant="contained"
					color="primary">Save</Button>
				</footer>
			</section>
		</>
	);
}
