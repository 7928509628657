import React from 'react';
import { CompositeDecorator } from "draft-js";
import { highlightToFlatStrategy } from "./highlightToFlatStrategy";
import { getMatches } from "./getMatches";
import { breakSpansByBlocks } from "./breakSpansByBlocks";
export class DecoratorFactory {
    constructor() {
        this.map = new Map();
        this.decoratorCache = new Map();
    }
    updateBlockSpans(blockSpans) {
        this.map.clear();
        for (const blockSpan of blockSpans) {
            const m = this.map.get(blockSpan.component) || new Map();
            this.map.set(blockSpan.component, m);
            const mm = m.get(blockSpan.className) || new Map();
            m.set(blockSpan.className, mm);
            const mmm = mm.get(blockSpan.blockKey) || new Map();
            mm.set(blockSpan.blockKey, mmm);
            mmm.set(blockSpan.spanStart - blockSpan.blockStart, blockSpan);
        }
    }
    componentFactory(component, className) {
        if (component == undefined) {
            return React.memo((props) => {
                return (React.createElement("mark", { className: className }, props.children));
            }, (a, b) => a.decoratedText == b.decoratedText);
        }
        else {
            return (props) => {
                var _a, _b, _c;
                const block = (_c = (_b = (_a = this.map
                    .get(component)) === null || _a === void 0 ? void 0 : _a.get(className)) === null || _b === void 0 ? void 0 : _b.get(props.blockKey)) === null || _c === void 0 ? void 0 : _c.get(props.start);
                if (!block || !block.component) {
                    throw new Error("RHWTAInternalError");
                }
                return React.createElement(block.component, Object.assign({}, block), props.children);
            };
        }
    }
    strategyFactory(component, className) {
        return (block, callback) => {
            var _a, _b, _c;
            const blockSpans = ((_c = (_b = (_a = this.map
                .get(component)) === null || _a === void 0 ? void 0 : _a.get(className)) === null || _b === void 0 ? void 0 : _b.get(block.getKey())) === null || _c === void 0 ? void 0 : _c.values()) || [];
            for (const blockSpan of blockSpans) {
                const start = blockSpan.spanStart - blockSpan.blockStart;
                const end = blockSpan.spanEnd - blockSpan.blockStart;
                callback(start, end);
            }
        };
    }
    toDecorators(blockSpans) {
        var _a;
        const decorators = [];
        this.updateBlockSpans(blockSpans);
        for (const [component, m] of this.map.entries()) {
            for (const [className] of m.entries()) {
                let decorator = (_a = this.decoratorCache.get(component)) === null || _a === void 0 ? void 0 : _a.get(className);
                if (!decorator) {
                    decorator = {
                        component: this.componentFactory(component, className),
                        strategy: this.strategyFactory(component, className),
                    };
                    let c = this.decoratorCache.get(component) || new Map();
                    this.decoratorCache.set(component, c);
                    c.set(className, decorator);
                }
                decorators.push(decorator);
            }
        }
        return decorators;
    }
    create(contentState, highlight, text) {
        text = text || contentState.getPlainText();
        const sc = highlightToFlatStrategy(highlight);
        const matches = getMatches(text, sc);
        const blockSpans = breakSpansByBlocks(contentState, matches, text);
        const decorators = this.toDecorators(blockSpans);
        const draftDecorators = new CompositeDecorator(decorators);
        return draftDecorators;
    }
}
