import {
	Switch,
	SwitchLabel,
	TextField,
	Button,
} from '@barracuda-internal/bds-core';
import { Alert } from '@material-ui/lab';
import React, { ReactElement, useEffect, useState } from 'react';
import FullPageError from 'src/components/FullPageError';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAPIEffect from 'src/lib/useAPIEffect';
import { ClassifierRegexView } from 'src/types/dataTypes';
import ulog from 'ulog';
import { EditViewDialogProps } from './EditViewDialog';
import { ClassifierTypes } from 'src/types/dataTypes';
import { Container } from '@material-ui/core';
import { Typography, Box, IconButton } from '@barracuda-internal/bds-core';
import Close from '@mui/icons-material/Close';
import { useApiRequest } from 'src/hooks/useApi';
import { HighlightWithinTextarea } from 'react-highlight-within-textarea';
import useStyles from 'src/styles/di-theme';
import classNames from 'classnames';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { validate } from 'src/components/Validation';
import { set } from 'react-ga';
interface EditRegexDialogProps extends EditViewDialogProps {}

function EditRegexDialog({
	baseClassifier,
	onClose,
	onSave,
}: EditRegexDialogProps): ReactElement {
	const classes = useStyles();
	const { api } = useAppToolchain();

	const [isLoading, setIsLoading] = useState(false);
	const [formState, setFormState] = useState({
		displayName: '',
		tagName: '',
		disabled: true,
		pattern: '',
		testString: '',
	});

	const [doSave, setDoSave] = useState(false);
	const [doGet, setDoGet] = useState(true);
	const [doTest, setDoTest] = useState(false);
	const [isSaving, setIsSaving] = useState(false);
	const [testString, setTestString] = useState('');
	const [boundaries, setBoundaries] = useState([[0, 0]]);
	const [testError, setTestError] = useState('');
	const [limit, setLimit] = useState({
		current: 0,
		max: 0,
	});

	const [uiError, setUIError] = useState({
		displayName: { error: false, msg: '' },
		tagName: { error: false, msg: '' },
		pattern: { error: false, msg: '' },
		testString: { error: false, msg: '' },
	});
	const [uiState, setUIState] = useState('initial');

	const [classifier, setClassifier]: [
		ClassifierRegexView,
		React.Dispatch<ClassifierRegexView>,
	] = useState();

	const [loadError, setLoadError]: [Error, React.Dispatch<Error>] =
		useState();
	const [saveError, setSaveError]: [Error, React.Dispatch<Error>] =
		useState();

	useApiRequest<any>(() => {
		if (doTest) {
			if (!checkForUIValidation()) {
				setDoTest(false);
				setUIState('ui_validation_failed');
				return;
			}
			setUIState('testing');
			setTestError('');
			setBoundaries([[0, 0]]);
			return api
				.testRegex(
					baseClassifier.accountId,
					formState.pattern,
					testString,
					formState.displayName,
					formState.tagName,
				)
				.then((res) => {
					if (res.status) {
						let boundaries = res.findings.map((finding: any) => {
							return [
								finding.location.byteRange.start,
								finding.location.byteRange.end,
							];
						});
						if (boundaries.length > 0) {
							setBoundaries(boundaries);
							setUIState('test_success');
						} else {
							setUIState('test_failed_no_matches');
						}
					} else {
						setUIState('test_failed');
						setTestError(res.message);
					}
				})
				.catch((err) => {
					setUIState('test_failed');
					setTestError(err.message);
				})
				.finally(() => {
					setDoTest(false);
				});
		}
	}, [doTest]);

	useAPIEffect(
		(cancel) => {
			if (cancel) return;
			api.getAccountClassifierTypes(baseClassifier.accountId)
				.then((results) => {
					const customRegexFindingView = results.find(
						(result) => result.type === 'CustomRegexFindingView',
					);
					if (customRegexFindingView) {
						setLimit({
							max: customRegexFindingView.restrictedCount,
							current: customRegexFindingView.count + 1,
						});
					}
				})
				.catch((err) => {})
				.finally(() => {});
		},
		[uiState],
	);

	useAPIEffect(
		(cancelled) => {
			if (!doGet) return;
			setIsLoading(true);
			setClassifier(baseClassifier as ClassifierRegexView);
			if (baseClassifier.id != '') {
				api.get<{ data: ClassifierRegexView }>(
					baseClassifier.configUrl,
					{},
					{},
					false,
				)
					.then((res) => {
						if (cancelled) return;

						setClassifier(res.data);
						setFormState({
							...formState,
							displayName: res.data.displayName || '',
							tagName: res.data.tagName || '',
							disabled: res.data.disabled,
							pattern: res.data.pattern || '',
						});
					})
					.catch((err) => {
						setLoadError(err);
					})
					.finally(() => {
						if (cancelled) return;
						setIsLoading(false);
						setDoGet(false);
					});
			}
		},
		[doGet],
	);

	const checkForUIValidation = () => {
		
		const displayNameError = validate({ value: formState.displayName });
		setUIError((prevState) => ({
			...prevState,
			displayName: {
				error: Boolean(displayNameError),
				msg: displayNameError,
			},
		}));

		const tagNameError = validate({ value: formState.tagName });
		setUIError((prevState) => ({
			...prevState,
			tagName: { error: Boolean(tagNameError), msg: tagNameError },
		}));
		const patternError = validate({
			value: formState.pattern,
			minlength: 4,
		});
		setUIError((prevState) => ({
			...prevState,
			pattern: { error: Boolean(patternError), msg: patternError },
		}));

		const testStringError = validate({ value: testString });
		setUIError((prevState) => ({
			...prevState,
			testString: {
				error: Boolean(testStringError),
				msg: testStringError,
			},
		}));
		const hasUIError =
			Boolean(displayNameError) ||
			Boolean(tagNameError) ||
			Boolean(patternError) ||
			Boolean(testStringError);
		return !hasUIError;
	};

	useAPIEffect(
		(cancelled) => {
			if (!doSave) return;
			setSaveError(null);

			if (uiState != 'test_success') {
				setDoSave(false);
				if (uiState == 'test_required') {
					setUIState('test_required_show_error');
				} else if (uiState == 'initial' || uiState == 'ui_validation_failed') {
					if (checkForUIValidation()) {
						setUIState('test_required_show_error');
					} else {
						setUIState('ui_validation_failed');
					}
				}
				return;
			}else{
				//capture changes after test is success
				setDoSave(false);
				if (!checkForUIValidation()) {
					setUIState('ui_validation_failed');
					return;
				}
			}
			const patchObj = [
				{
					path: '/displayName',
					op: 'replace',
					value: formState.displayName,
				},
				{
					path: '/disabled',
					op: 'replace',
					value: formState.disabled,
				},
				{
					path: '/tagName',
					op: 'replace',
					value: formState.tagName,
				},
				{
					path: '/pattern',
					op: 'replace',
					value: formState.pattern,
				},
			];

			setIsSaving(true);
			const save = (id) => {
				api.updateRegexClassifier(
					baseClassifier.accountId,
					id,
					patchObj,
				)
					.then((res) => {
						if (cancelled) return;
						cancelled = true;
						onSave && onSave();
						onClose && onClose();
						setDoGet(true);
					})
					.catch((err) => {
						if (cancelled) return;
						ulog.error(err);
						setSaveError(err);
					})
					.finally(() => {
						if (cancelled) return;
						setDoSave(false);
						setIsSaving(false);
					});
			};
			if (baseClassifier.id == '') {
				api.addClassifierView(
					baseClassifier.accountId,
					baseClassifier.type as ClassifierTypes,
				)
					.then((res) => {
						save(res.id);
					})
					.catch((err) => {
						if (cancelled) return;
						ulog.error(err);
						setSaveError(err);
					});
			} else {
				save(baseClassifier.id);
			}
		},
		[doSave],
	);

	if (!!loadError)
		return <FullPageError message="An error occured while loading." />;

	if (!classifier) return null;

	const handleDisplayNameChange = (e) => {
		setFormState({ ...formState, displayName: e.target.value });
		const error = validate({ value: e.target.value });
		setUIError((prevState) => ({
			...prevState,
			displayName: { error: Boolean(error), msg: error },
		}));
	};
	const handleLabelInputChange = (e) => {
		setFormState({ ...formState, tagName: e.target.value });
		const error = validate({ value: e.target.value });
		setUIError((prevState) => ({
			...prevState,
			tagName: { error: Boolean(error), msg: error },
		}));
	};

	const handleRegexInputChange = (e) => {
		setBoundaries([[0, 0]]);
		if (uiState == 'test_success') setUIState('test_required');
		setFormState({ ...formState, pattern: e.target.value });
		const error = validate({ value: e.target.value, minlength: 4 });
		setUIError((prevState) => ({
			...prevState,
			pattern: { error: Boolean(error), msg: error },
		}));
	};
	const handleDisableChange = (e) => {
		const newState = {
			...formState,
			disabled: !e.target.checked,
		};
		setFormState(newState);
	};

	const handleSubmit = (e) => e.preventDefault();
	return (
		<Container component="form" onSubmit={handleSubmit}>
			<header className={classNames(classes['pt_2'], classes['pb_2'])}>
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
					}}
				>
					{baseClassifier.id == '' ? (
						<div>
							<Typography
								variant="h4"
								color="textPrimary"
								component="span"
								className={classNames(classes['pr_1'])}
							>
								Add Regular Expression
							</Typography>
							<Typography
								variant="h4"
								color="textSecondary"
								component="span"
							>
								{limit.current} of {limit.max}
							</Typography>
						</div>
					) : (
						<Typography variant="h4" color="textPrimary">
							Edit Regular Expression
						</Typography>
					)}

					<Box sx={{ flexGrow: 1 }}></Box>
					<IconButton
						aria-label="Close"
						onClick={() => {
							onClose();
						}}
						edge="end"
						size="small"
					>
						<Close />
					</IconButton>
				</Box>
			</header>
			<div className={classNames(classes['pb_2'])}>
				{!!saveError ? (
					<Alert color="error">
						Error saving form! Please wait a few moments and try
						again.
					</Alert>
				) : uiState == 'test_required_show_error' ? (
					<Alert color="error">
						Please test regex using test string before saving.
					</Alert>
				) : uiState == 'test_failed' ? (
					<Alert color="error">
						Invalid result from the regular expression. Please check
						your regular expression and test string.
					</Alert>
				) : uiState == 'test_failed_no_matches' ? (
					<Alert color="error">
						No matches found in the test string. Please check your
						regular expression and test string.
					</Alert>
				) : (
					<Alert color="info" className={classNames(classes['mb_2'])}>
						Note: You can create up to 10 Regular Expression
						classifiers. Please manage your classifiers accordingly.
					</Alert>
				)}

				<div>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							alignItems: 'center',
						}}
					>
						<Typography color="textPrimary">Status</Typography>
						<Box sx={{ flexGrow: 1 }}></Box>
						<SwitchLabel
							label={!formState.disabled ? 'Enabled' : 'Disabled'}
							labelPlacement="start"
							size="small"
							control={
								<Switch
									checked={!formState.disabled}
									onChange={handleDisableChange}
									color="primary"
								/>
							}
						/>
					</Box>
					<TextField
						fullWidth
						size="small"
						label="Classifier Name"
						required
						error={uiError.displayName.error}
						helperText={uiError.displayName.msg}
						onChange={handleDisplayNameChange}
						value={formState.displayName}
						variant="outlined"
						className={classNames(classes['mb_3'], classes['mt_2'])}
						inputProps={{ maxLength: 64 }}
					/>
					<TextField
						fullWidth
						label="Label Name"
						error={uiState != 'initial' && uiError.tagName.error}
						size="small"
						required
						helperText={
							uiState != 'initial' && uiError.tagName.error
								? uiError.tagName.msg
								: 'This label will display in your feed when matching text is found.'
						}
						margin="none"
						onChange={handleLabelInputChange}
						value={formState.tagName}
						variant="outlined"
						className={classes['mb_1']}
						inputProps={{ maxLength: 64 }}
					/>
					<TextField
						fullWidth
						label="Regular Expression"
						size="small"
						required
						error={
							(uiState != 'initial' && uiError.pattern.error) ||
							uiState == 'test_failed'
						}
						helperText={
							uiState != 'initial' && uiError.pattern.error
								? uiError.pattern.msg
								: uiState == 'test_failed'
								? "Your pattern has one or more errors, please refer to the 'Match Information' section for more details"
								: 'Input your regular expression compatible with the Google RE2 syntax.'
						}
						margin="normal"
						onChange={handleRegexInputChange}
						value={formState.pattern}
						variant="outlined"
						className={classes['mb_2']}
					/>

					<div
						className={
							uiState != 'initial' && uiError.testString.error
								? classNames(
										classes['textarea_highlight'],
										classes['textarea_error'],
										classes['mb_2'],
								  )
								: classNames(
										classes['textarea_highlight'],
										classes['mb_2'],
								  )
						}
					>
						<HighlightWithinTextarea
							value={testString}
							highlight={boundaries}
							onChange={(value) => {
								if (value != testString) {
									setBoundaries([[0, 0]]);
									if (uiState == 'test_success')
										setUIState('test_required');
								}
								setTestString(value);
								const error = validate({ value: value });
								setUIError((prevState) => ({
									...prevState,
									testString: {
										error: Boolean(error),
										msg: error,
									},
								}));
							}}
							placeholder="Test String"
						/>
						{uiState != 'initial' && uiError.testString.error ? (
							<p className="MuiFormHelperText-root  Mui-error MuiFormHelperText-contained MuiFormHelperText-filled MuiFormHelperText-marginDense">
								{uiError.testString.msg}. Enter the string to
								test against the regular expression.
							</p>
						) : (
							<p className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-filled MuiFormHelperText-marginDense">
								Enter the string to test against the regular
								expression.
							</p>
						)}
					</div>

					<div className={classNames(classes['mb_2'])}>
						{uiState == 'test_success' ||
						uiState == 'test_failed' ||
						uiState == 'test_failed_no_matches' ||
						uiState == 'saving' ? (
							<>
								<Typography className={classes['mb_1']}>
									<b>Match Information</b>
								</Typography>
								{uiState == 'test_failed' ? (
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
										}}
									>
										<ErrorIcon
											fontSize="small"
											color="error"
										></ErrorIcon>
										<Typography
											color="error"
											className={classes['ml_1']}
										>
											{testError}
										</Typography>
									</Box>
								) : uiState == 'test_failed_no_matches' ? (
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
										}}
									>
										<ErrorIcon
											fontSize="small"
											color="error"
										></ErrorIcon>
										<Typography
											color="error"
											className={classes['ml_1']}
										>
											Does not match the test string
										</Typography>
									</Box>
								) : (
									<Box
										sx={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
										}}
									>
										<CheckCircleIcon
											fontSize="small"
											color="success"
										></CheckCircleIcon>
										<Typography
											color="textPrimary"
											className={classes['ml_1']}
										>
											Found <b>{boundaries.length}</b>{' '}
											match
											{boundaries.length > 1 ? 'es' : ''}
										</Typography>
									</Box>
								)}
							</>
						) : null}
					</div>
				</div>
				<footer className={classNames(classes['text_right'])}>
					<Button
						color="primary"
						size="small"
						onClick={() => {
							setDoTest(true);
						}}
						isLoading={uiState == 'testing'}
					>
						Test Match
					</Button>
					<Button
						onClick={onClose}
						variant="outlined"
						color="primary"
						size="small"
						className={classNames(classes['ml_2'])}
					>
						Cancel
					</Button>
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							setDoSave(true);
						}}
						size="small"
						isLoading={uiState == 'testing' || uiState == 'saving'}
						className={classNames(classes['ml_2'])}
					>
						{baseClassifier.id == '' ? 'Add' : 'Save'}
					</Button>
				</footer>
			</div>
		</Container>
	);
}

export default EditRegexDialog;
