import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import PolicyRowRender from './PolicyRowRender';
import { Policy } from 'src/types/policyTypes';
import { Typography } from '@material-ui/core';
import LoadingIndicator from 'src/lib/components/LoadingIndicator';

interface GridPanelProps {
  policyFeeds: Policy[];
  setPolicyFeeds: React.Dispatch<React.SetStateAction<Policy[]>>;
  status: string;
}

export default function GridPanel({policyFeeds, setPolicyFeeds, status}: GridPanelProps) {
  const moveFeeds = (dragIndex: number, hoverIndex: number) => {
    setPolicyFeeds((prevFeeds) => {
        const feeds = [...prevFeeds];
        [feeds[dragIndex], feeds[hoverIndex]] = [feeds[hoverIndex], feeds[dragIndex]];
        return feeds;
      });

    };



  return (
    <>
    <TableContainer component={Paper}
        style={{
            borderTop: 0,
            borderLeft: 0,
            borderRight: 0,
            marginTop: '30px',
            fontWeight: 500,
            boxShadow: 'none'
        }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style = {{width: '80px', textAlign: 'left'}}>Priority</TableCell>
            <TableCell style = {{width: '180px', textAlign: 'left'}} align="right">Policy name</TableCell>
            <TableCell style = {{width: '250px', textAlign: 'left'}} align="right">Classifier</TableCell>
            <TableCell style = {{width: '180px', textAlign: 'left'}} align="right">Directory/Identity</TableCell>
            <TableCell style = {{width: '130px', textAlign: 'left'}} align="right">Authorship</TableCell>
            <TableCell style = {{width: '130px', textAlign: 'left'}} align="right">Rule action</TableCell>
            <TableCell style = {{width: '130px', textAlign: 'left'}} align="right">Created by</TableCell>
            <TableCell style = {{width: '130px', textAlign: 'left'}} align="right">Last modified</TableCell>
            <TableCell style = {{width: '150px', textAlign: 'left'}} align="right">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {policyFeeds.length > 0 && policyFeeds.map((feed, index) => (
            <PolicyRowRender 
              feed={feed} 
              id={feed.id} 
              index={index} 
              key={feed.id} 
              moveFeeds={moveFeeds} 
              setPolicyFeeds={setPolicyFeeds}
            />
          ))}
        </TableBody>
        
      </Table>
    </TableContainer>
    {status === 'RUNNING' && <LoadingIndicator message="Loading Policies ..." />}
    {policyFeeds.length === 0 && status !== 'RUNNING' && <Typography style={{textAlign: 'center', marginTop: '20px'}}>No results found</Typography>}
    <div style={{height: '50px'}}></div>
    </>
  );
}