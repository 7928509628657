import React, { ReactElement } from 'react';
import RequestPIConsent from 'src/components/data/RequestPIConsent';
import AccountUpdate from 'src/components/data/AccountUpdate';
import SavedData from 'src/components/data/SavedData';
import CookieSetting from 'src/components/data/CookieSetting';
import useAuth from 'src/hooks/useAuth';
import AccountRegion from 'src/routes/settings/AccountRegion';
import { Typography } from '@barracuda-internal/bds-core';
import useStyles from 'src/styles/di-theme';
import classNames from 'classnames';
interface Props { }

function DataSettings({ }: Props): ReactElement {
	const { auth } = useAuth();
	const classes = useStyles();
	return (
		<div
			className={classNames(
				classes['pt_4'],
				classes['m_auto'],
				classes['w_max_720'],
			)}
		><Typography
			variant="h1"
			color="textPrimary"
			className={classNames(classes['mb_2'], classes['border_bottom_gray'])}
		>
				Manage Tenant
			</Typography>
			{auth?.isFullAdmin() ? (
				<div
					className={classNames(
						classes['mb_2'],
						classes['pt_2'],
					)}
				>

					<AccountUpdate />
				</div>) : null}
			<div
				className={classNames(
					classes['mb_2'],
					classes['pt_2'],
				)}
			>

				<RequestPIConsent />
			</div>
			{auth?.isFullAdmin() ? (
				<div
					className={classNames(
						classes['mb_4'],
						classes['pt_2'], classes['border_top_gray']
					)}
				>

					<AccountRegion />
					<br></br>

					<div
						className={classNames(
							classes['mb_2'],
							classes['border_top_gray'],
							classes['pt_2'],
						)}
					>
						<SavedData></SavedData>
					</div>
				</div>
			) : null}

			<div
				className={classNames(
					classes['mb_2'],
					classes['border_top_gray'],
					classes['pt_2'],
				)}
			>
				<CookieSetting></CookieSetting>
			</div>
		</div>
	);
}

export default DataSettings;
