import { makeStyles } from '@material-ui/core/styles';
import '@progress/kendo-theme-material/dist/all.css';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import mixpanel from 'mixpanel-browser';
import Oidc, { UserManager } from 'oidc-client';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga';
import {
	BrowserRouter as Router,
	Redirect,
	Route,
	Switch
} from 'react-router-dom';
import { AppToolchainProvider } from 'src/components/providers/AppToolchainProvider';
import  AppErrorPage  from 'src/routes/AppErrorPage';
import { makeAPI } from 'src/networking/api';
import AppRoute from 'src/routes/AppRoute';
import CaseRoute from 'src/routes/CaseRoute';
import DetectionRoute from 'src/routes/DetectionRoute';
import ScanlogRoute from 'src/routes/ScanLogsRoute';
import AuditlogRoute from './routes/AuditLogRoute';
import LoginPage from 'src/routes/LoginPage';
import OidcSignin from 'src/routes/OidcSignin';
import OidcSigninRenew from 'src/routes/OidcSigninRenew';
import OidcSignout from 'src/routes/OidcSignout';
import OnboardingRoute from 'src/routes/OnboardingRoute';
import SettingsRoute from 'src/routes/SettingsRoute';
import ReportsRoute from './routes/report/ReportsRoute';
import { AppConfigOptions } from 'src/types/applicationTypes';
import ulog from 'ulog';
import CookieSettingContButton from 'src/components/CookieSettingContButton';
import LoginAsSupport from './routes/LoginAsSupport';
import LoginLandingPage from './routes/LoginLandingPage';

const useStyles = makeStyles(() => ({
	'@global': {
		'html, body, #main, #main > main': {
			height: '100%',
			position: 'relative',
			overflowY : 'hidden'
		},
	},
}));

function App({ config }: { config: AppConfigOptions }): React.ReactElement {
	useStyles();

	// We have to save this as state here so we can use it
	// after an actual render has taken place (which will change the window location)
	const [searchParams] = useState(
		new URLSearchParams(window.location.search),
	);

	const [oidcUserManager] = useState<UserManager>(
		new UserManager({
			authority: `${config.oidcAuthority}`,
			userStore: new Oidc.WebStorageStateStore({
				store: window.localStorage,
			}),
			client_id: 'webui',
			redirect_uri: `${config.oidcRedirectUri}`,
			response_type: 'code',
			scope: 'frontendapi openid',
			post_logout_redirect_uri: `${config.oidcPostLogoutRedirectUri}`,
			automaticSilentRenew: true,
			silent_redirect_uri: `${config.oidcSigninRenewUri}`,
			loadUserInfo: true,
		}),
	);

	if (config.mixpanelProjectCode) {
		mixpanel.init(config.mixpanelProjectCode);
	}
	useEffect(() => {
		// Setup Mixpanel
	}, [config.mixpanelProjectCode]);

	useEffect((): void => {
		// Set up Sentry
		ulog.debug('Starting Sentry.');
		Sentry.init({
			autoSessionTracking: true,
			dsn: config.sentryDsn,
			environment: config.codeusEnvironment,
			release: process.env.REACT_APP_VERSION,
			integrations: [new Integrations.BrowserTracing()],

			// We recommend adjusting this value in production, or using tracesSampler
			// for finer control
			tracesSampleRate: 1.0,
		});
	}, [config.sentryDsn, config.codeusEnvironment]);

	useEffect(() => {
		// Set up Google Analytics
		if (config.googleTrackingCode) {
			ReactGA.initialize(config.googleTrackingCode, {
				gaOptions: {
					siteSpeedSampleRate: 100,
				},
			});
		}

		const campaignSource = searchParams.get('utm_source');
		const campaignMedium = searchParams.get('utm_medium');
		const campaignName = searchParams.get('utm_campaign');

		if (window?.ga && (campaignSource || campaignMedium || campaignName)) {
			ulog.debug(
				'Setting campaign info:',
				campaignSource,
				campaignMedium,
				campaignName,
			);

			const campaign_information = {
				campaignSource,
				campaignMedium,
				campaignName,
			};

			ReactGA.set(campaign_information);
		}
	}, [config.googleTrackingCode]);

	useEffect(() => {
		if (config.cookielawDomainId) {
			setTimeout( () => {
				var script = document.createElement('script');
				script.type = 'text/javascript';
				script.setAttribute(
					'src',
					'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
				);
				script.setAttribute('charset', 'UTF-8');
	
				// Change to their data-domain-script
				script.setAttribute('data-domain-script', config.cookielawDomainId);
				document.getElementsByTagName('head')[0].appendChild(script);
			
			},1000)
		}
	}, [config.cookielawDomainId]);

	useEffect(() => {
		window.onbeforeunload = function () {
			window.sessionStorage.clear();
		};

		window.onunhandledrejection = (err: PromiseRejectionEvent) => {
			if (
				err.reason?.status === 401 &&
				window.location.pathname !== '/login'  &&
				window.location.pathname !== '/bcclogin'
			) {
				Sentry.configureScope((scope) => scope.setUser(null));
				oidcUserManager.clearStaleState();
				sessionStorage.clear();
				window.location.href = '/bcclogin';
			}
		};
	}, []);

	// This is the API object which we will use throughout the application.
	// It is pre-bound to all API actions, so we should not need to import
	// any action or http modules directly.
	const api = makeAPI(config.apiEndpoint, oidcUserManager);

	return (
		<Router>
			<CookieSettingContButton></CookieSettingContButton>
			<AppToolchainProvider
				toolchain={{
					config,
					api,
					oidcUserManager,
				}}
			>
				<Switch>
					<Route component={OidcSignin} path="/signin-oidc" exact />
					<Route component={AppErrorPage} path="/error" exact />
					<Route
						component={OidcSigninRenew}
						path="/signin-renew"
						exact
					/>
					<Route component={OidcSignout} path="/signout-oidc" exact />

					<Route component={LoginPage} exact path="/bcclogin" />
					<Route component={LoginLandingPage} exact path="/login" />
					<Route component={LoginAsSupport} exact path="/delegatelogin" />
					<AppRoute
						component={OnboardingRoute}
						path="/welcome"
						title="Welcome to Data Inspector"
						sidebar={false}
					></AppRoute>
					<AppRoute
						component={DetectionRoute}
						exact
						path="/detections"
						title="Your Case Feed"
						requireOnboarding={true}
					/>
					<AppRoute
						component={ScanlogRoute}
						exact
						path="/scanlog"
						title="Your Scan Log"
						requireOnboarding={true}
					/>
				
					<AppRoute
						component={AuditlogRoute}
						exact
						path="/auditlog"
						title="Your Audit Log"
						requireOnboarding={true}
					/>
					<AppRoute
						component={CaseRoute}
						exact
						path="/cases/:id"
						title="Data Inspector Case View"
						requireOnboarding={true}
					/>
					
					{/* <AppRoute 
							component={ReportsRoute} 
							exact
							path="/reports"
							title="Reports"
							requireOnboarding={true} /> */}

					<Route component={SettingsRoute} path="/settings" />
					
					<Route path="/">
						<Redirect to="/detections" />
					</Route>
					<Route>
						<p>404</p>
					</Route>
				</Switch>
			</AppToolchainProvider>
		</Router>
	);
}

export default App;
