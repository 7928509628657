import { Typography } from '@barracuda-internal/bds-core';
import React from 'react';


export function DefaultColumnHeader (props) { return (
	<Typography variant="body1" color="textSecondary">
		<span
			style={{
				textTransform: 'capitalize',
				padding: 8,
				margin: '-8px 0px',
				lineHeight: '28px',
				display: 'block'
			}}
		>
			{props.title}
		</span>
	</Typography>
)};

