import { Button, Typography, Alert } from '@barracuda-internal/bds-core';
import { Divider } from '@material-ui/core';
import React, { ReactElement, useEffect, useState } from 'react';
import FEATURES from 'src/featureMap';
import withAnalytics from 'src/lib/withAnalytics';
import { ServiceSelectionGrid } from 'src/routes/onboarding/ServiceSelectionGrid';
import { OnboardingStepProps } from 'src/routes/onboarding/types';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
import useStyles from 'src/styles/di-theme';
import Grid from '@material-ui/core/Grid';
interface Props extends OnboardingStepProps {
	setStep: (step: number) => void;
}

function ServicesSetup({ setStep, activeStep }: Props): ReactElement {
	const classes = useStyles();
	const [doCompleteOnboarding, setDoCompleteOnboarding] = useState(0);
	const [connectCount, setConnectCount] = useState(0);
	const { auth } = useAuth();
	const { api } = useAppToolchain();

	const [updateStatus, res, err] = useApiRequest(
		() => {
			if (!auth) return;
			if (doCompleteOnboarding > 0 && connectCount != 0) {
				return api.saveAccountConfig(auth.accountDetails.account.id, [
					{
						path: '/onboardedTime',
						op: 'replace',
						value: new Date().toISOString(),
					},
				]);
			}
		},
		[doCompleteOnboarding],
		([status, res, err]) => {
			if (doCompleteOnboarding > 0 && connectCount != 0) {
				localStorage.removeItem('is_setup');
				window.location.href = '/detections';
			}
		},
	);

	const handleNext = withAnalytics(FEATURES.onboardingCompleted, () =>
		setDoCompleteOnboarding(doCompleteOnboarding + 1),
	);
	return (
		<>
			<Typography
				variant="h1"
				color="textPrimary"
				gutterBottom
				className={classes['mt_4']}
			>
				Connect Your Services
			</Typography>
			{connectCount == 0 && doCompleteOnboarding != 0 ? (
				<Alert severity="error">
					<Typography
						variant="body2"
						color="error"
						className={classes['mb_1']}
					>
						Data Inspector requires at least one service to be
						connected. Please select from the Connected Services
						options before completing the setup process.
					</Typography>
				</Alert>
			) : null}
			<Divider className={classes['mt_2']}></Divider>
			<ServiceSelectionGrid
				connectcount={(count) => setConnectCount(count)}
			/>
			<div className={classes['mt_3']}>
				<Grid container direction="row" spacing={1}>
					<Grid item xs={9}></Grid>
					<Grid item xs={1}>
						<Button
							onClick={() => setStep(activeStep - 1)}
							color="primary"
						>
							Back
						</Button>
					</Grid>
					<Grid item xs={2}>
						<Button
							color="primary"
							onClick={handleNext}
							variant="contained"
						>
							Complete
						</Button>
					</Grid>
				</Grid>
			</div>
		</>
	);
}

export default ServicesSetup;
