import { UserAssignment } from 'src/types/dataTypes';

export function userHasRole(
	accountId: string,
	userAssignments: UserAssignment[],
	role: string
): boolean {
	return !!userAssignments.filter(
		(ass) =>
			(ass.domain === '/*' || ass.domain === `/accounts/${accountId}`) &&
			ass.role === role
	).length;
}

export function isAdminRole(
	accountId: string,
	userAssignments: UserAssignment[]
): boolean {
	return !!userAssignments.filter(
		(ass) =>
			(ass.domain === '/*' || ass.domain === `/accounts/${accountId}`) &&
			(['role_full_admin', 'role_account_admin', 'role_bcc_msp_partneradmin'].includes(ass.role))
	).length;
}

export function isBCCRole(role){
	return ['role_account_admin', 'role_bcc_msp_partneradmin', 'role_bcc_msp_tech', 'role_bcc_msp_technodelete'].includes(role);
}
