import React from 'react';
import BaseLayout from 'src/routes/layouts/BaseLayout';

function FullPageError({ message }: { message?: string }) {
	return (
		<BaseLayout>
			<div className="error-wrapper">
				{message ? <p>{message}</p> : null}
			</div>
		</BaseLayout>
	);
}

export default FullPageError;
