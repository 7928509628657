import { useCallback, useState, useRef, useEffect } from 'react';
import { Toast, ToastProps } from 'src/components/toast/toast-models';

interface UseToastReturn {
  toasts: ToastProps[];
  triggerToast: (toastProps: Toast) => void;
}

const useToast = (): UseToastReturn => {
    const [toasts, setToasts] = useState<ToastProps[]>([]);
    const timers = useRef<Record<number, NodeJS.Timeout>>({}); 

    const triggerToast = useCallback((toastProps: Toast) => {
      const id = Date.now();
      const toastWithId = { ...toastProps, id };

      setToasts(prev => [...prev, toastWithId]);

      timers.current[id] = setTimeout(() => {
        setToasts(prev => prev.filter(toast => toast.id !== id));
        delete timers.current[id];
      }, toastProps?.duration || 3000);
    }, []);

    useEffect(() => {
      return () => {
        Object.values(timers.current).forEach(clearTimeout);
      };
    }, []);

    return { 
        toasts, 
        triggerToast
    }
}

export default useToast;