import React from 'react';
import { useCallback } from 'react';
import debounce from 'lodash/debounce';
import { TextField } from '@barracuda-internal/bds-core';
import useStyles from 'src/styles/di-theme';
import Tooltip from '@material-ui/core/Tooltip';
import InputAdornment from '@mui/material/InputAdornment';
import { CloseSharp } from '@material-ui/icons';

interface SearchFieldProps {
	setSearch: (search: string) => void;
	searchText: string;
	setSearchText: (searchText: string) => void;
	label?: string;
	searchFields: string;
  }

export default function SearchField({
    setSearch,
	searchText,
    setSearchText,
	label = '', 
    searchFields
}: SearchFieldProps) {
	const classes = useStyles();
	const [showTooltip, setShowTooltip] = React.useState(false);
	const debouncedUpdateSearch = useCallback(debounce(setSearchTextFn, 300), []);
	function setSearchTextFn(val) {
		setSearch(val.trim());
	}
	const handleSearch = (event) => {
        const val = event.target.value;
        setSearchText(val);
		debouncedUpdateSearch(val);
	};
	const  helperText = `Joe or <text> where text can be policy name`;
	return (
		<Tooltip
			title={helperText}
			arrow
			open={ showTooltip}
		>
			<TextField
				fullWidth
				size="small"
				label={label}
				onChange={handleSearch}
                onFocus={() => setShowTooltip(true)}
                onBlur={() => setShowTooltip(false) }
				value={searchText}
				variant="outlined"
				InputProps={{
					endAdornment: (
						<InputAdornment position="start">
							<CloseSharp
								fontSize="small"
								className={classes['cursor_pointer']}
								onClick={() => {
									setSearchTextFn('');
									setSearchText('');
								}}
							></CloseSharp>
						</InputAdornment>
					),
				}}
			/>
		</Tooltip>
	);
}