import { Button ,Typography,Chip,Avatar} from '@barracuda-internal/bds-core';
import {
    Popover,
    TableCell
    
} from '@material-ui/core';
import {
    InsertDriveFile as FileIcon,
    Search as PreviewIcon
} from '@material-ui/icons';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import Gravatar from 'react-gravatar';
import { SecurityReport } from 'src/components/SecurityReport';
import FEATURES from 'src/featureMap';
import withAnalytics from 'src/lib/withAnalytics';
import azurefiles from 'src/static/azurefiles.png';
import onedrive from 'src/static/onedrive.png';
import sharepoint from 'src/static/sharepoint.png';
import { Feed, Tag } from 'src/types/dataTypes';
import { FeedConfidenceReport } from './FeedConfidenceReport';

export function UserCell({ dataItem: { user } }) {
	if (!user || !user.email || !user.name)
		return (
			<TableCell component="td" scope="row">
				<Typography variant="body1" noWrap>
					Unknown
				</Typography>
			</TableCell>
		);

	return (
		<TableCell component="td" scope="row">
			<div
				style={{
					flexDirection: 'row',
					display: 'flex',
					alignItems: 'center',
				}}
			>
				<Avatar
					component={({ children, ...props }) => {
						return (
							<Gravatar {...props} email={user.email} size={40} />
						);
					}}
					style={{
						float: 'left',
						marginRight: '10px',
					}}
				/>
				<div
					style={{
						flexDirection: 'column',
						textAlign: 'left',
						textTransform: 'none',
					}}
				>
					<Typography variant="body1" color='textPrimary' noWrap>
						{user.name}
					</Typography>
				</div>
			</div>
		</TableCell>
	);
}

export function DateCell({ dataItem: { created } }) {
	return (
		<TableCell>
			<div
				style={{
					flexDirection: 'column',
					textAlign: 'left',
					textTransform: 'none',
				}}
			>
				<Typography variant="body1" color="textPrimary" noWrap>
					{DateTime.fromISO(created).toLocaleString(
						DateTime.DATE_FULL,
					)}
				</Typography>
				<Typography variant="subtitle1" noWrap>
					{DateTime.fromISO(created).toLocaleString(
						DateTime.TIME_WITH_SHORT_OFFSET,
					)}
				</Typography>
			</div>
		</TableCell>
	);
}

function TagCellChip({ feedId, tag }) {
	const [open, setOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState();
	const [openBATDFinding, setOpenBATDFinding] = useState(false);

	const handleClick = (e) => {
		if (
			tag.classification === 'CONTENT_MALICIOUS' ||
			tag.classification === 'CONTENT_SUSPICIOUS'
		) {
			setOpenBATDFinding(true);
		} else {
			setAnchorEl(e.currentTarget);
			setOpen(!open);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
		setOpen(!open);
	};

	return (
		<>
			<Chip
				avatar={
					<Avatar style={{ backgroundColor: 'white' }}>
						{tag.count}
					</Avatar>
				}
				clickable={true}
				label={tag.text}
				onClick={withAnalytics(
					FEATURES.feedsConfidenceCheck,
					handleClick,
					{
						tag,
					},
				)}
				size="small"
				style={{
					backgroundColor: tag.fillColor,
					color: 'white',
					fontStyle: 'bold',
					margin: '2px',
				}}
			/>
			<Popover
				open={open}
				onClose={handleClose}
				anchorEl={anchorEl}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
			>
				<FeedConfidenceReport feedId={feedId} tag={tag} />
			</Popover>
		</>
	);
}

export function TagCell({ dataItem: { tags, id } }) {
	return (
		<TableCell>
			{tags.map((tag) => (
				<TagCellChip key={tag.classification} tag={tag} feedId={id} />
			))}
		</TableCell>
	);
}

export function IconCell({ dataItem: { cloudType } }) {
	let typeIcon;
	switch (cloudType.toLowerCase()) {
		case 'onedriveconnector':
			typeIcon = (
				<img src={onedrive} alt="onedrive" width="20" height="20" />
			);
			break;
		case 'sharepointlibraryconnector':
			typeIcon = (
				<img src={sharepoint} alt="sharepoint" width="20" height="20" />
			);
			break;
		case 'azurefilesconnector':
			typeIcon = (
				<img src={azurefiles} alt="azurefiles" width="20" height="20" />
			);
			break;
		default:
			typeIcon = <FileIcon />;
	}

	return (
		<TableCell align="center">
			<div style={{ textAlign: 'center' }}>{typeIcon}</div>
		</TableCell>
	);
}

export function ItemCell({ dataItem: { itemPath, itemLocation } }) {
	return (
		<TableCell style={{ overflowWrap: 'break-word' }}>
			<Typography variant="subtitle1">{itemLocation}</Typography>
			<Typography variant="body2">{itemPath}</Typography>
		</TableCell>
	);
}

export function PreviewCell({ feedId, tags, onPreviewClick }) {
	// If there is a report with a snapshot, we will take the first one
	// we find and display a button to show the report.
	const tagsWithReport: Tag[] = tags.filter((t: Tag) => t.hasReport);

	const handleClick = () => onPreviewClick(feedId);

	return (
		<TableCell align="center" style={{ textAlign: 'center' }}>
			<Button
				onClick={withAnalytics(
					FEATURES.feedsPreviewFeedButtonClick,
					handleClick,
					{ tags: tags },
				)}
				variant="contained" size="small" color='primary'
				startIcon={<PreviewIcon />}
			>
				Review
			</Button>
			<SecurityReport feedId={feedId} tagsWithReport={tagsWithReport} />
		</TableCell>
	);
}

interface DismissCellProps {
	dataItem: Feed;
	onClick: (feed: Feed) => void;
}
export function DismissCell({ dataItem: feed, onClick }: DismissCellProps) {
	const handleClick = () => {
		onClick(feed);
	};
	return (
		<TableCell align="center" style={{ textAlign: 'center' }}>
			<Button onClick={handleClick} variant="outlined" size="small" color='secondary'>Dismiss</Button>
		</TableCell>
	);
}