import { Typography, Select } from '@barracuda-internal/bds-core';
import { FormControl, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement, useState, useEffect } from 'react';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
import LoadingIndicator from 'src/lib/components/LoadingIndicator';
import { CodeusAccountConfig } from 'src/types/dataTypes';

interface Props {
	onBoarding?: boolean;
	onChange?: (region: string) => void;
}

const locationMap = {
	US: ['🇺🇸', 'United States'],
	CA: ['🇨🇦', 'Canada'],
	NL: ['🇪🇺', 'European Union'],
	GB: ['🇬🇧', 'Great Britain'],
	AU: ['🇦🇺', 'Australia'],
	JP: ['🇯🇵', 'Japan'],
	IN: ['🇮🇳', 'India'],
};

const useStyles = makeStyles((theme) => ({
	button: {
		marginLeft: theme.spacing(1),
	},
}));

function AccountRegionSwitcher({
	onBoarding = false,
	onChange,
}: Props): ReactElement {
	const [config, setConfig] = useState<CodeusAccountConfig>();
	const systemConfig = useAppToolchain().config;
	const [selectedRegion, setSelectedRegion] = useState<string>();
	const [oneRegion, setOneRegion] = useState(false);
	const { auth, status } = useAuth();
	const { api } = useAppToolchain();

	useEffect(() => {
		setOneRegion(isOneRegion());
	}, []);

	const isOneRegion = () => {
		if (systemConfig.currentRegion != 'US') {
			if (
				location.hostname ==
				systemConfig.regionMapping[systemConfig.currentRegion]
			) {
				return true;
			}
		}
		return false;
	};
	const [configStatus] = useApiRequest(() => {
		if (!auth) return;

		return api
			.getAccountConfig(auth?.accountDetails.account.id)
			.then((res) => {
				console.log(config);
				setConfig(res);
				if (isOneRegion()) {
					setSelectedRegion(systemConfig.currentRegion);
					onChange && onChange(systemConfig.currentRegion);
				} else {
					setSelectedRegion(res.storageRegion);
					onChange && onChange(res.storageRegion);
				}
			});
	}, [auth]);

	if (status === 'PENDING' || configStatus === 'RUNNING')
		return <LoadingIndicator message="Loading Details..." />;

	if (!auth || !config) return null;

	const handleChange = (e) => {
		setSelectedRegion(e.target.value);
		onChange && onChange(e.target.value);
	};

	return (
		<>
			<FormControl variant="filled">
				<Select
					value={selectedRegion}
					onChange={handleChange}
					style={{
						marginRight: '20px',
						borderBottom: 'none',
						borderBottomLeftRadius: '4px',
						borderBottomRightRadius: '4px',
						paddingLeft: '10px',
						paddingBottom: '15px',
						left: '10px',
						width: '215px',
						height: '40px',
						background: '#ffffff',
						borderStyle: 'groove',
						borderWidth: '1px',
						borderColor: 'silver',
					}}
				>
					<option
						value="Region"
						disabled
						style={{
							padding: '10px',
							marginLeft: '10px',
							fontSize: '15px',
							fontWeight: 'bold',
							wordSpacing: '20px',
						}}
					>
						Region
					</option>
					{Object.entries(locationMap).map(([label, name]) => {
						if (oneRegion) {
							// Display only one region in the dropdown
							if (label == selectedRegion) {
								return (
									<MenuItem key={label} value={label}>
										{name[0]}&nbsp;&nbsp;&nbsp;{name[1]}
									</MenuItem>
								);
							}
						} else {
							if (!onBoarding && label == 'IN') {
								return (
									<MenuItem disabled key={label} value={label}>
										{name[0]}&nbsp;&nbsp;&nbsp;{name[1]}
									</MenuItem>
								);
							} else {
								return (
									<MenuItem key={label} value={label}>
										{name[0]}&nbsp;&nbsp;&nbsp;{name[1]}
									</MenuItem>
								);
							}
						}
					})}
				</Select>
			</FormControl>
		</>
	);
}

export default AccountRegionSwitcher;
