import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga';
import { FeatureAction } from 'src/featureMap';
import ulog from 'ulog';
import { version } from '../../package.json';

export type AnalyticsDetails =
	| { [key: string]: any }
	| ((...args: any[]) => { [key: string]: any });

export default function withAnalytics<T = void>(
	action: FeatureAction,
	fn: (...args: any[]) => T,
	details: AnalyticsDetails = undefined,
): () => T {
	return (...args) => {
		triggerAnalytics(action, details, args);
		return fn(...args);
	};
}

export function triggerAnalytics(
	action: FeatureAction,
	details: AnalyticsDetails = undefined,
	detailArgs: any[],
) {
	const id = `DIN ${action.id}`;

	let finalDetails = details;
	if (typeof details === 'function') {
		finalDetails = details(...detailArgs);
	}

	mixpanel.track(id, {
		...finalDetails,
		feature: action.feature,
		appVersion: version,
	});

	if (window?.ga) {
		ReactGA.send({
			hitType: 'event',
			eventCategory: action.feature,
			eventAction: action.id,
		});
	}

	if (window?.Intercom) {
		window.Intercom('trackEvent', action.id, {
			...finalDetails,
			feature: action.feature,
			appVersion: version,
		});
	}

	let logOutput: unknown[] = [];
	finalDetails && logOutput.push(finalDetails);
	ulog.debug(
		`ANALYTICS: ${action.feature.toUpperCase()}: ${action.id}:`,
		logOutput,
	);
}
