import { Link } from '@barracuda-internal/bds-core';
import { IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import React, { useState } from 'react';



interface DeleteWithConfirmProps {
	onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
	onSecondClick?: (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) => void;
	secondMessage: string;
}

export default function DeleteWithConfirm({
	onClick,
	onSecondClick,
	secondMessage,
}: DeleteWithConfirmProps) {
	const [clickCount, setClickCount] = useState(0);

	const handleClick = (e) => {
		if (clickCount === 0) {
			if (onClick) onClick(e);
		} else if (clickCount === 1) {
			if (onSecondClick) onSecondClick(e);
		}

		clickCount + 1 > 1 ? setClickCount(0) : setClickCount(clickCount + 1);
	};

	const handleClear = () => {
		setClickCount(0);
	};

	if (clickCount === 0) {
		return (
			<div>
				<IconButton onClick={handleClick}>
					<DeleteIcon />
				</IconButton>
				<span></span>
			</div>
		);
	} else if (clickCount === 1) {
		return (
			<div>
				<div>
					<DeleteForeverIcon style={{ color: 'red' }} />
				</div>
				<div>{secondMessage || 'Are you sure?'}</div>
				<div>
					<Link
						onClick={handleClear}
						underline="always"
						style={{ cursor: 'pointer', fontWeight: 'bold' }}
					>
						No
					</Link>
					<span> / </span>
					<Link
						onClick={handleClick}
						underline="always"
						style={{ cursor: 'pointer', fontWeight: 'bold' }}
					>
						Yes
					</Link>
				</div>
			</div>
		);
	}
}
