import React from 'react';
import { Checkbox, Typography, Box, IconButton , ListItemText} from '@barracuda-internal/bds-core';
import {
	FormControlLabel,
	FormControl,
	FormLabel,
	FormGroup,
} from '@material-ui/core';
import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Close from '@mui/icons-material/Close';
import useStyles from 'src/styles/di-theme';
import classNames from 'classnames';
import Collapse from '@mui/material/Collapse';
export default function FilterPanel({
	selected,
	onSelectionChange,
	onClose,
	aggData,
	filterOptions,
}) {
	const classes = useStyles();
	const [expandObj, setExpandObj] = React.useState({});
	const [refreshView, setRefreshView] = React.useState(1);
	const countMap = {};
	if (aggData) {
		for (let id in aggData) {
			aggData[id].forEach((rec) => {
				countMap[id + rec.key] = rec.count;
			});
		}
	}

	const getFinding = (filter, finding) => {
		let count = countMap[filter.id + finding]
			? countMap[filter.id + finding]
			: 0;
		return (
			<FormControlLabel
				className={classNames(classes['pb_2'], classes['pl_2'])}
				control={
					<Checkbox
						size="small"
						style={{ padding: '0 9px' }}
						checked={
							selected.indexOf(
								filter.category + ' : ' + finding,
							) > -1
						}
						disabled={count == 0}
						onChange={(event, checked) => {
							onSelectionChange(
								filter.category + ' : ' + finding,
								checked,
							);
						}}
					/>
				}
				label={
					<Typography
						variant="body1"
						color={count != 0 ? 'textPrimary' : 'textSecondary'}
					>
						{finding} ({count})
					</Typography>
				}
			/>
		);
	};

	return (
		refreshView > 0 && (
			<div>
				<div
					className={classNames(
						classes['p_2'],
						classes['border_bottom_gray'],
					)}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						<Typography variant="h3" color="textPrimary">
							Filter
						</Typography>

						<Box sx={{ flexGrow: 1 }}></Box>
						<IconButton
							aria-label="Filter"
							onClick={() => onClose()}
							edge="end"
							size="small"
						>
							<Close />
						</IconButton>
					</Box>
				</div>

				<div className={classNames(classes['p_2'])} style={{maxHeight : 'calc(100vh - 100px)', overflowY : 'auto'}}>
					{filterOptions.map((filter) => (
						<div className={classNames(classes['pb_2'])}>
							<FormControl
								component="fieldset"
								variant="standard"
								className={classNames(
									classes['pb_2'],
									classes['pt_2'],
								)}
							>
								<FormLabel component="legend">
									{' '}
									<Typography
										variant="h4"
										color="textPrimary"
									>
										{filter.category}
									</Typography>
								</FormLabel>
								<FormGroup>
									{filter.options.map((finding) => {
										if (typeof finding !== 'object') {
											return getFinding(filter, finding);
										} else {
											let subcategory = finding;
											let name = subcategory.name;
											let html = [];
											if( expandObj[name] == undefined ) expandObj[name] = true;
											html.push(
													 <ListItemButton onClick={() => {
																expandObj[
																	name
																] = !expandObj[
																	name
																];
																setExpandObj(
																	Object.assign(
																		expandObj,
																	),
																);
																setRefreshView(
																	refreshView +
																		1,
																);
															}}>
														{expandObj[
																	name
																] ? <ExpandLess  /> : <ExpandMore />}
														<ListItemText primary={name} />
														
													</ListItemButton>
											);
											html.push(
												<Collapse
													in={
														expandObj[
															name
														]
													}
													timeout="auto"
													unmountOnExit
												>
													<FormGroup
														className={classNames(
															classes['pl_4'],
														)}
													>
														{subcategory.options.map(
															(findingTemp) => {
																return getFinding(
																	filter,
																	findingTemp,
																);
															},
														)}
													</FormGroup>
												</Collapse>,
											);
											return html;
										}
									})}
								</FormGroup>
							</FormControl>
						</div>
					))}
				</div>
			</div>
		)
	);
}
