import { Switch, SwitchLabel, TextField } from '@barracuda-internal/bds-core';
import { Button, Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import React, { ReactElement, useEffect, useState } from 'react';
import FullPageError from 'src/components/FullPageError';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAPIEffect from 'src/lib/useAPIEffect';
import { ClassifierTextFileView } from 'src/types/dataTypes';
import ulog from 'ulog';
import TextFileUploader from '../TextFileUploader';
import classifierMap from './classifierMap';
import EditViewDialog, { EditViewDialogProps } from './EditViewDialog';
import { ClassifierTypes } from 'src/types/dataTypes';
import { validate } from 'src/components/Validation';

interface EditTextFileDialogProps extends EditViewDialogProps {}
const useStyles = makeStyles((theme) => ({
	alert: {
		margin: `0 0 ${theme.spacing(4)} 0`,
	},
	row: {
		borderBottom: 'thin solid lightgray',
		padding: theme.spacing(2),
	},
	optionCell: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'baseline',
	},
	fileDrop: {
		padding: theme.spacing(1),
		margin: `0 ${theme.spacing(1)}`,
		textAlign: 'center',
		border: 'thin solid lightgray',
	},
}));

function EditTextFileDialog({
	baseClassifier,
	onClose,
	onSave,
}: EditTextFileDialogProps): ReactElement {
	const details = classifierMap['TextFileFindingView'];
	const { api } = useAppToolchain();
	const classes = useStyles();

	const [isLoading, setIsLoading] = useState(false);
	const [formState, setFormState] = useState({
		wordCount: '',
		caseSensitive: false,
		displayName: '',
		matchWholeWord: true,
		tagName: '',
		disabled: true,
	});

	const [uiError, setUIError] = useState({
		tagName: { error: false, msg: '' },
		displayName: { error: false, msg: '' },
		upload: { error: false, msg: '' },
	});

	const [doSave, setDoSave] = useState(false);
	const [doGet, setDoGet] = useState(true);
	const [doUpload, setDoUpload] = useState<FormData>();
	const [isSaving, setIsSaving] = useState(false);
	const [accessToken, setAccessToken] = useState('');

	const [classifier, setClassifier]: [
		ClassifierTextFileView,
		React.Dispatch<ClassifierTextFileView>,
	] = useState();

	const [loadError, setLoadError]: [Error, React.Dispatch<Error>] =
		useState();
	const [saveError, setSaveError]: [Error, React.Dispatch<Error>] =
		useState();

	useAPIEffect(
		(cancelled) => {
			if (!doGet) return;
			setIsLoading(true);
			setClassifier(baseClassifier as ClassifierTextFileView);
			if (baseClassifier.id != '') {
				api.get<{ data: ClassifierTextFileView }>(
					baseClassifier.configUrl,
					{},
					{},
					false,
				)
					.then((res) => {
						if (cancelled) return;

						setClassifier(res.data);
						setFormState({
							...formState,
							wordCount: res.data.wordCount,
							caseSensitive: res.data.caseSensitive,
							matchWholeWord: res.data.matchWholeWord,
							displayName: res.data.displayName || '',
							tagName: res.data.tagName || '',
							disabled: res.data.disabled,
						});
					})
					.catch((err) => {
						setLoadError(err);
					})
					.finally(() => {
						if (cancelled) return;
						setIsLoading(false);
						setDoGet(false);
					});
			}
		},
		[doGet],
	);

	function hasUIValidations(uiError: any): boolean {
		const { displayName, tagName } = formState;
		uiError.displayName = {
			error: Boolean(validate({ value: displayName })),
			msg: validate({ value: displayName }),
		};
		uiError.tagName = {
			error: Boolean(validate({ value: tagName })),
			msg: validate({ value: tagName }),
		};
		if ( baseClassifier.id == '' && !doUpload) {
			uiError.upload = { error: true, msg: 'File required' };
		}else{
			uiError.upload = { error: false, msg: '' };
		}
		setUIError(uiError);
		return Object.values(uiError).some((field: any) => field.error);
	}
	useAPIEffect(
		(cancelled) => {
			if (!doSave) return;

			if (hasUIValidations(uiError)) {
				// There are validation errors, do not save
				setDoSave(false);
				return;
			}

			const patchObj = [
				{
					path: '/displayName',
					op: 'replace',
					value: formState.displayName,
				},
				{
					path: '/matchWholeWord',
					op: 'replace',
					value: formState.matchWholeWord,
				},
				{
					path: '/caseSensitive',
					op: 'replace',
					value: formState.caseSensitive,
				},
				{
					path: '/disabled',
					op: 'replace',
					value: formState.disabled,
				},
				{
					path: '/tagName',
					op: 'replace',
					value: formState.tagName,
				},
			];

			setIsSaving(true);
			const save = (id) => {
				api.updateTextFileClassifier(
					baseClassifier.accountId,
					id,
					patchObj,
				)
					.then((res) => {
						if (cancelled) return;
						cancelled = true;
						if (doUpload) {
							api.uploadTextFileView(
								baseClassifier.accountId,
								id,
								doUpload,
							)
								.then((res) => {
									onSave && onSave();
									onClose && onClose();
									setDoGet(true);
								})
								.catch((err) => {
									ulog.error(err);
									setSaveError(err);
								});
						} else {
							onSave && onSave();
							onClose && onClose();
						}
					})
					.catch((err) => {
						if (cancelled) return;
						ulog.error(err);
						setSaveError(err);
					})
					.finally(() => {
						if (cancelled) return;
						setDoSave(false);
						setIsSaving(false);
					});
			};
			if (baseClassifier.id == '') {
				api.addClassifierView(
					baseClassifier.accountId,
					baseClassifier.type as ClassifierTypes,
				).then((res) => {
					save(res.id);
				}).catch((err) => { 
					if (cancelled) return;
					ulog.error(err);
					setSaveError(err);
				});;
			} else {
				save(baseClassifier.id);
			}
		},
		[doSave],
	);

	useEffect(() => {
		api.getAccessToken().then((token) => setAccessToken(token));
	}, [api]);

	if (!!loadError)
		return <FullPageError message="An error occured while loading." />;

	if (!classifier) return null;

	const handleFileChange = (data: FormData) => {
		setDoUpload(data);
		uiError.upload = { error: false, msg: '' };
		setUIError(uiError);

	};
	function handleFieldChange(
		event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
		fieldName: string,
	) {
		const value = event.target.value;
		const error = validate({ value: value });
		setFormState((prevState) => ({
			...prevState,
			[fieldName]: value,
		}));
		setUIError((prevState) => ({
			...prevState,
			[fieldName]: { error: Boolean(error), msg: error },
		}));
	}

	return (
		<EditViewDialog
			baseClassifier={baseClassifier}
			Icon={details.icon}
			isLoading={false}
			isSaving={false}
			onChange={(newFormState) => {
				ulog.debug('Base form state changed', newFormState);
				setFormState({
					...formState,
					...newFormState,
				});
			}}
			onClose={onClose}
			onSave={() => setDoSave(true)}
			title={details.name}
		>
			<div>
				{!!saveError ? (
					<Alert className={classes.alert} color="error">
						Error saving form! Please wait a few moments and try
						again.
					</Alert>
				) : null}
				<TextField
					fullWidth
					label="Classifier Name"
					onChange={(event) => {
						handleFieldChange(event, 'displayName');
					}}
					style={{ marginBottom: '16px' }}
					value={formState.displayName}
					variant="outlined"
					required
					inputProps={{ maxLength: 64 }}
					error={uiError.displayName.error}
					helperText={uiError.displayName.msg}
				/>
				<TextField
					label="Label Name"
					helperText={
						uiError.tagName.error
							? uiError.tagName.msg
							: 'This label will display in your feed when matching text is found.'
					}
					margin="normal"
					onChange={(event) => handleFieldChange(event, 'tagName')}
					value={formState.tagName}
					variant="outlined"
					required
					inputProps={{ maxLength: 64 }}
					error={uiError.tagName.error}
				/>
				<div className={classes.fileDrop}>
					<TextFileUploader onFileChange={handleFileChange} />
				</div>
				{uiError.upload.error ? (
					<p className="MuiFormHelperText-root MuiFormHelperText-contained Mui-error MuiFormHelperText-filled MuiFormHelperText-marginDense">
						{uiError.upload.msg}
					</p>
				) : null}
				<div style={{ textAlign: 'right' }}>
					{classifier?.payload?.url && accessToken ? (
						<Button
							component={Link}
							href={`${classifier?.payload?.url}?access_token=${accessToken}`}
							target="_blank"
						>
							Download File
						</Button>
					) : null}
				</div>
				<h3>Options</h3>
				<Grid container className={classes.row}>
					<Grid item xs={10}>
						<h4>Case Sensitivity</h4>
						<p>
							When this option is enabled, the classifier will
							only look for text that matches the exact letter
							case as the specified keywords or phrases.
						</p>
						<p>For example, "cat" will not match "Cat".</p>
					</Grid>
					<Grid item xs={2} className={classes.optionCell}>
						<SwitchLabel
							label={formState.caseSensitive ? 'On' : 'Off'}
							labelPlacement="start"
							control={
								<Switch
									color="primary"
									checked={formState.caseSensitive}
									onChange={(e) =>
										setFormState({
											...formState,
											caseSensitive: e.target.checked,
										})
									}
								/>
							}
						/>
					</Grid>
				</Grid>
				<Grid container className={classes.row}>
					<Grid item xs={10}>
						<h4>Partial Matching</h4>
						<p>
							When this option is enabled, the classifier will
							look for any text that contains the specified
							keywords or phrases and not just exact matches.
						</p>
						<p>
							For example, "cat" will match both "cat" and
							"caterpillar".
						</p>
					</Grid>
					<Grid item xs={2} className={classes.optionCell}>
						<SwitchLabel
							label={!formState.matchWholeWord ? 'On' : 'Off'}
							labelPlacement="start"
							control={
								<Switch
									color="primary"
									checked={!formState.matchWholeWord}
									onChange={(e) =>
										setFormState({
											...formState,
											matchWholeWord: !e.target.checked,
										})
									}
								/>
							}
						/>
					</Grid>
				</Grid>
			</div>
		</EditViewDialog>
	);
}

export default EditTextFileDialog;
