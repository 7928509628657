import React, { useCallback, useContext, useEffect, useState } from 'react';
import Shimmer from 'src/components/Shimmer';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import PreviewToolTip from './PreviewToolTip';
import { SideDetailContext } from './SideDetailPanel';
import useStyles from 'src/styles/di-theme';
import classNames from 'classnames';
import '../../styles/cropper.css';

import FeedbackIcon from '@mui/icons-material/Feedback';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';


import ReactCrop, {
	centerCrop,
	makeAspectCrop,
  } from "react-image-crop";

const shimmerWidth = [100, 70, 90, 80, 60, 80, 60, 50, 40, 30];
const MIN_DIMENSION = 200;
const ASPECT_RATIO = 1;

export const ImageLoader = ({preview, index, selectedClassiferObj, myRef, generatePreviewWidthAndHeight, imageRefs, zoom, setSelectedClassifierObj, getLocation, selectedClassifer, imageSpecs, classifierIdx, previews, setShowFBPopup}) => {

	const [showShimmer, setShowShimmer] = useState(true);
	const [showError, setShowError] = useState(false);
	const [url, setUrl] = useState('');
	const [previewFindings, setPreviewFindings] = useState([]);
	const [image,setImage] = useState(null);
	const [imageId, setImageId] = useState('');
	const classes = useStyles();

	const {
		showRedactions,
		cropEnabled,
		cropIndex,
		crop,
		setCrop,
		buttonPosition,
		setButtonPosition,
		setCropEnabled,
		setCropIndex,
		setShowFalseNegativeFBPopup,
		setCropData,
		croppedImageRef
	} = useContext(SideDetailContext);


	useEffect(() => {
		setUrl(showRedactions ? preview.redactedURL : preview.revealedURL);
	}, [preview, showRedactions]);

	useEffect(() => {
		setImageId(`${preview.id}-${new Date().getTime()}`);
		setShowShimmer(true);
	}, [preview]);


    useEffect(() => {
		if( image != null ){
			setPreviewFindings(getPreviewFindings(preview?.previewFindings, image.naturalWidth, image.naturalHeight));
		}
		
    }, [zoom]);

	const pickFindingOnLoad = useCallback((image) => {
		let counter = 0;

		outerLoop: for(const preview of previews) {
			for(const finding of preview?.previewFindings) {
				if (finding.findingTag == selectedClassifer) {
					counter++;
					if (counter == classifierIdx) {
						let obj: any = getLocation(
							finding,
							zoom,
							preview.pixelDensity,
							image.naturalWidth,
							image.naturalHeight,
						);
						obj.border = showRedactions ? '3px solid #FFEB3B' : '3px solid #FCAD36';
						obj.borderRadius = '5px';
						obj.page = preview.page;
						setSelectedClassifierObj(obj);
						break outerLoop;
					}
				}
			}
		}

	}, [showRedactions, selectedClassifer, classifierIdx, previews]);

	const getPreviewFindings = (previewFindings, width, height) => {
		return previewFindings.map(finding => ({...finding, ...getLocation(finding, zoom, preview.pixelDensity, width, height)}));
	}

	const onReactCropperImageLoad = (event) => {
		const { width, height } = event.currentTarget;
		const cropWidthInPercent = (MIN_DIMENSION / width) * 150;
	
		const crop = makeAspectCrop(
		  {
			unit: "%",
			width: cropWidthInPercent,
		  },
		  ASPECT_RATIO,
		  width,
		  height
		);

		const centeredCrop = centerCrop(crop, width, height);
		setCrop(centeredCrop);
		updateButtonPosition(centeredCrop);
		setShowShimmer(false);
		setShowError(false);
	};
	
	  const updateButtonPosition = (percentCrop) => {
		const { x, y, width, height } = percentCrop;
	
		const buttonTop = y + height + 1;
		const buttonLeft = x + width - 30;
	
		setButtonPosition({ top: `${buttonTop}%`, left: `${buttonLeft}%` });
	  };
	
	  const calculateCropCoordinates = (percentCrop, img, url, page, pixelDensity, previewWithoutFindings) => {
		const { x, y, width, height } = percentCrop;
		const { naturalWidth, naturalHeight } = img;
	
		return {
		  topLeftCoordinate: {
			x: ((x / 100) * naturalWidth) / pixelDensity,
			y: ((y / 100) * naturalHeight) / pixelDensity,
		  },
		  bottomRightCoordinate: {
			x: (((x + width) / 100) * naturalWidth)/ pixelDensity,
			y: (((y + height) / 100) * naturalHeight) / pixelDensity,
		  },
		  imageUrl: url,
		  page,
		  previewWithoutFindings
		};
	  };
	
	return(
		<div 
			ref={imageRefs[index]}
			data-alt={index + 1}
			style={
				{
					position: 'relative',
					marginTop: '10px',
					marginBottom: `${cropEnabled && cropIndex === index ? '50px' : '10px'}`
				}
			}
		>
			{(showShimmer || showError) && <div>
                <div
                    style={{
                        width: 600,
                        display : 'block',
                        margin : '2px auto',
                        backgroundColor: '#fff',
                        padding: '30px',
						position: 'relative'
                    }}
                >
					{showError && 
						<Stack sx={{ width: '100%' }} spacing={2}>
							<Alert severity="error">
								Failed to load the image. Please try again after few minutes.
							</Alert>
						</Stack>
					}
                    <Shimmer 
                        shimmerWidth={shimmerWidth} 
                        skeletonHeight={30} 
                        animation = "wave"
                        style={{ marginBottom: 20, marginTop: 20}}
                    />
                </div>
			</div>
			} 
			{url.length > 0 && <div >
				{selectedClassiferObj != null && !showError && !cropEnabled &&
				selectedClassiferObj.page == preview.page ? (
					<div style={{ position : 'relative', height : 0,width : 0, scrollMarginTop : 500, top : selectedClassiferObj.top, left :selectedClassiferObj.left  }}>
						{!showError && !showShimmer && <div data-alt={index + 1} style={selectedClassiferObj} ref={myRef}></div>}
					</div>
				) : <></>}
					{
						previewFindings?.length > 0 && !showRedactions &&
						previewFindings?.map((finding) => (
							<PreviewToolTip 
								finding={finding} 
								key={finding.fidingId} 
								previewIndex={index}
								previewId={preview.id}
								setShowFBPopup={setShowFBPopup} 
								previewUrl={preview.url || ''} 
							/>
						))
					}

					{cropEnabled && cropIndex === index ? (
						<div id="crop" style={{position: 'relative'}}>
							<ReactCrop
								crop={crop}
								onChange={(pixelCrop, percentCrop) => {
									setCrop(percentCrop);
									updateButtonPosition(percentCrop);
								}}
								keepSelection
								minWidth={MIN_DIMENSION}
								minHeight={50}
								className='cropper'
								>
								<img
									src={url}
									key={imageId}
									onLoad={onReactCropperImageLoad}
									onError={() => {
										setShowError(true);
										setShowShimmer(true);
									}}
								/>
							</ReactCrop>

							{cropEnabled && cropIndex === index &&  
								(<div 
									className={classNames(classes['flex_ver_center'])}
									style={{
										backgroundColor: '#191919',
										padding: '2px',
										borderRadius: '5px',
										gap: 5,
										position: "absolute",
										top: buttonPosition.top,
										left: buttonPosition.left,
										zIndex: 9999
									}}
								>

									<Button
										variant="text"
										className={classNames(classes['flex_ver_center'])}
										sx={{
											color: '#FFFFFF',
											fontWeight: 700,
											fontSize: '16px',
											textTransform: 'none'
										}}
										startIcon={<FeedbackIcon sx={{ color: '#067CC1' }} />}
										onClick={() => {
											if(image) {
												croppedImageRef.current = image;
												const coordinates = calculateCropCoordinates(crop, image, url, preview.page, preview.pixelDensity, preview?.previewWithoutFindings ?? false);

												setCropData(coordinates);
												setShowFalseNegativeFBPopup(true);
											}
										}}
									>
										Report
									</Button>

									<Button
										variant="text"
										className={classNames(classes['flex_ver_center'])}
										sx={{
											color: '#FFFFFF',
											fontWeight: 700,
											fontSize: '16px',
											textTransform: 'none'
										}}
										startIcon={<CloseIcon sx={{ color: '#067CC1' }} />}
										onClick={() => {
											setCropEnabled(false);
											setCropIndex(null);
											setCrop(null);
										}}
									>
										Close
									</Button>
								</div>)
							}
						</div>
						
					) : (
						<img
							width={zoom * 6}
							src={url}
							key={imageId}
							onLoad={(event) => {
								const image = event.target;
								generatePreviewWidthAndHeight(image.naturalWidth, image.naturalHeight, preview.id);
								if(!imageSpecs[preview.id] && selectedClassiferObj.page == preview.page ) {
									pickFindingOnLoad(image);
								}
								setShowShimmer(false);
								setShowError(false);
								setPreviewFindings(getPreviewFindings(preview?.previewFindings, image.naturalWidth, image.naturalHeight));
								setImage(image);
							}}
							onError={() => {
								setShowError(true);
								setShowShimmer(true);
							}}
						/>
                  )}
			</div>}
		</div>

	)
}