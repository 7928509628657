import { UserManager } from 'oidc-client';
import React, { ReactElement } from 'react';
import { useAppToolchain } from 'src/hooks/useAppToolchain';

interface Props {
	userManager: UserManager;
}

function OidcSigninRenew({}: Props): ReactElement {
	const { oidcUserManager } = useAppToolchain();
	oidcUserManager.signinSilentCallback();
	return <p>Session Renewed</p>;
}

export default OidcSigninRenew;
