import React, { useState } from 'react';
import {
	Dialog,
	DialogContent,
	DialogActions,
	DialogTitle,
	Typography,
	Button,
} from '@barracuda-internal/bds-core';
import useAuth from 'src/hooks/useAuth';
import useStyles from 'src/styles/di-theme';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import { Alert } from '@material-ui/lab';
export default function SupportPopup({ show = false, closePopup }) {
	const classes = useStyles();
	const { api } = useAppToolchain();
	const [doSubmit, setDoSubmit] = useState(0);
	const [otherError, setOtherError] = React.useState(false);
	const { auth } = useAuth();
	const [updateStatus, req, error] = useApiRequest(
		doSubmit &&
			(() => {
				return api
					.createSupportDelegateToken(auth.accountDetails.account.id)
					.then((res) => {
						closePopup(true);
					})
					.catch((res) => {})
					.finally(() => {});
			}),
		[doSubmit],
		() => {},
	);

	return (
		<div>
			<Dialog open={show} onClose={() => {}}>
				<DialogTitle>
					<Typography variant="h2">
						Barracuda Support Access
					</Typography>
				</DialogTitle>
				<DialogContent>
					{otherError ? (
						<Alert severity="error" className={classes['mb_3']}>
							There was an error processing your request. Please
							try again.
						</Alert>
					) : null}
					<Typography variant="body1" className={classes['mb_2']}>
						You are about to grant Barracuda Support access to your
						account for a default duration of 7 days. During this time,
						the Barracuda Support team will have the ability to log
						in to your account. If you wish to revoke this access,
						you can do so by clicking on "Disable Access"
						located in the left panel or on the top banner.
					</Typography>
				</DialogContent>
				<DialogActions className={classes['m_1']}>
					<Button
						disabled={updateStatus === 'RUNNING'}
						color="primary"
						onClick={() => {
							setOtherError(false);
							closePopup(false);
						}}
					>
						Cancel
					</Button>
					<Button
						isLoading={updateStatus === 'RUNNING'}
						color="primary"
						variant="contained"
						onClick={() => {
							setDoSubmit(doSubmit + 1);
						}}
					>
						Submit
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
