
import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0)',
      maxWidth: 700,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));

  
export default function TooltipColumn({
	content
}): React.ReactElement {
	return <HtmlTooltip title={content}>{content}</HtmlTooltip>;
}

export function DIHtmlTooltip({
    ttcontent,
	content
}): React.ReactElement {
	return <HtmlTooltip title={ttcontent}>{content}</HtmlTooltip>;
}