import React, { CSSProperties } from 'react';
import { useHistory } from 'react-router-dom';


const StatusComponent = ({statusData, setTab}) => {
  const history = useHistory();

  function handleClick(data) {
    if(data.route === null) return;
  
    (typeof data.route === 'number') ? setTab(data.route) : history.push(data.route);
  }

  return (
      <div style={styles.container}>
        <p style={styles.title}>What happened in the last 24 hours</p>
        <div style={styles.cardContainer}>
          {statusData.map((data, index) => <StatusCard key={index} data={data} handleClick={handleClick} />)}
        </div>
      </div>
    
  )
}

export default StatusComponent;


const StatusCard = ({data, handleClick}) => {
  return (
    <>
      <div 
        className='status-wrapper'
        style={{cursor: `${data.route !== null ? 'pointer' : 'default'}`}}
        onClick={() => handleClick(data)}
      >
        <div style={styles.metricAlignment}>
          <h2 style={styles.margin0}>
            {`${ data.status === 'success' ? formatNumber(data.count) : "--" }`}
          </h2>

          {data.type === 'activePolicies' && data.status === 'success' && (
            <span>&nbsp;{`of ${data.totalCount}`}</span>
          )}

          {data.type === 'activeDetections' && data.detectionsbeforeDateCount !== 0 && data.status === 'success' &&(
            <span>&nbsp;{`(${convertToPercentage(data.count, data.detectionsbeforeDateCount)}% from yesterday)`}</span>
          )}
        </div>

        <p style={styles.margin0}>{data.title}</p> 
      </div>
    </>
  )
}

function formatNumber(num) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + ' M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + ' K';
  } else {
    return num.toString();
  }
}

function convertToPercentage(newVal, oldVal) {
  const percentage = ((newVal - oldVal) / oldVal) * 100;
  
  if (percentage % 1 === 0) {
    return percentage > 0 ? `+${percentage}` : `${percentage}`;
  } else {
    return percentage > 0 ? `+${percentage.toFixed(1)}` : `${percentage.toFixed(1)}`;
  }
}

const styles: { [key: string]: CSSProperties } = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #f0f0ef',
    marginRight: '24px',
    padding: '5px 0px 30px 0px'
  },
  title: {
    paddingLeft: '20px',
    fontWeight: 700
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '30px',
    padding: '0px 20px',
    justifyContent: 'space-between'
  },
  metricAlignment: {
    display: 'inline-flex',
    alignItems: 'baseline'
  },
  margin0 : {
    margin: 0
  }
}