import React, { ReactElement } from 'react';
import { withRequireOnboarding } from 'src/components/withRequireOnboarding';
import ScanlogPage from './scanlog/Main';

interface Props {}

export default function ScanlogRoute({}: Props): ReactElement {
	const Page = withRequireOnboarding(ScanlogPage);

	return (
			<Page/>
	);
}
