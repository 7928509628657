import { Alert, Button, Typography } from '@barracuda-internal/bds-core';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ReactElement, useState } from 'react';
import AccountRegionSwitcher from 'src/components/AccountRegionSwitcher';
import FEATURES from 'src/featureMap';
import { useApiRequest } from 'src/hooks/useApi';
import { useAppToolchain } from 'src/hooks/useAppToolchain';
import useAuth from 'src/hooks/useAuth';
import withAnalytics from 'src/lib/withAnalytics';
import { OnboardingStepProps } from 'src/routes/onboarding/types';
import {
	Dialog,
	DialogContent,
	DialogActions,
	DialogTitle,
} from '@barracuda-internal/bds-core';

interface Props extends OnboardingStepProps {}

const useStyles = makeStyles((theme) => ({
	footer: {
		marginTop: theme.spacing(2),
		'& button': {
			marginRight: theme.spacing(2),
		},
	},
}));

function DataSetup({ setStep, activeStep }: Props): ReactElement {
	const classes = useStyles();
	const [selectedRegion, setSelectedRegion] = useState<string>();
	const [doSaveRegion, setDoSaveRegion] = useState(false);
	const { auth } = useAuth();
	const { api } = useAppToolchain();
	const { config } = useAppToolchain();
	const handleRegionChange = withAnalytics(
		FEATURES.onboardingDataLocationChange,
		(region) => setSelectedRegion(region),
		(region) => {
			return { region };
		},
	);
	const systemConfig = useAppToolchain().config;
	const handleNext = () => {
		console.log("handleNext",selectedRegion)
		var regionUrl = systemConfig.regionMapping[selectedRegion]
		console.log(regionUrl)
		var url = window.location.href;
		if(selectedRegion == "IN"){
			if(!url.includes(regionUrl)){
				window.location.replace("https://"+regionUrl)
			}
		}
		if (
			selectedRegion.toLowerCase() != config.currentRegion &&
			config.regionMapping[selectedRegion.toLowerCase()] != undefined
		) {
			setOpenRedirectWarn(true);
		} else {
			setDoSaveRegion(true);
		}
	};

	const [updateStatus, res, err] = useApiRequest(
		doSaveRegion &&
			!!api &&
			!!auth &&
			((signal) =>
				api.saveAccountConfig(
					auth.accountDetails.account.id,
					[
						{
							path: '/storageRegion',
							op: 'replace',
							value: selectedRegion,
						},
					],
					{ signal },
				)),
		[doSaveRegion, api, auth],
		([status, res, err]) => {
			setDoSaveRegion(false);
			if (err) return;
			if (openRedirectWarn) {
				location.href =
					'https://' +
					config.regionMapping[selectedRegion.toLowerCase()];
			}
			
			setStep(activeStep + 1);
		},
	);
	const [openRedirectWarn, setOpenRedirectWarn] = useState(false);

	return (
		<>
			<Dialog open={openRedirectWarn}>
				<DialogTitle>
					<Typography variant="h2">Confirm Redirect</Typography>
				</DialogTitle>
				<DialogContent>
					<Alert severity="warning" className={classes['mb_3']}>
						As the selected region is different from your existing
						site region, we are going to redirect to{' '}
						{selectedRegion != undefined
							? config.regionMapping[selectedRegion.toLowerCase()]
							: ''}
					</Alert>
				</DialogContent>
				<DialogActions>
					<Button
						color="primary"
						onClick={() => setOpenRedirectWarn(false)}
					>
						Cancel
					</Button>
					<Button
						color="primary"
						isLoading={updateStatus === 'RUNNING'}
						variant="contained"
						onClick={() => {
							setDoSaveRegion(true);
						}}
					>
						Continue
					</Button>
				</DialogActions>
			</Dialog>
			<Grid container>
				<Grid item xs={12}>
					<Typography
						variant="h1"
						color="textPrimary"
						className={classes['mb_4']}
						style={{ padding: '15px', marginTop: '15px' }}
					>
						{err ? (
							<Alert color="error">
								An error occured while saving the data location.
								Please reload the page and try again.
							</Alert>
						) : null}
						Select data storage region
					</Typography>
				</Grid>
				<div className={classes['mt_2']}>
					<Grid item xs={12}>
						<Typography
							variant="body1"
							color="textPrimary"
							className={classes['mt_2']}
							style={{ padding: '15px', marginTop: '15px' }}
						>
							Data Inspector stores your organization's and
							tenant information according to your region,
							ensuring compliance with storage-related regulatory
							policies.
						</Typography>
					</Grid>
					<Grid item xs={12} style={{ padding: '10px' }}>
						<Alert severity="info" className={classes['mb_3']}>
							Note: You always have the option to modify your data
							storage region later through the Settings.
						</Alert>
					</Grid>
					<Grid item xs={12}>
						<AccountRegionSwitcher
							onChange={handleRegionChange}
							onBoarding={true}
						></AccountRegionSwitcher>
					</Grid>
					<Grid item xs={12}>
						<footer className={classes.footer}>
							<div className={classes['mt_3']}>
								<Grid container direction="row" spacing={1}>
									<Grid item xs={8}></Grid>
									<Grid item xs={2}>
										<Button
											onClick={() =>
												setStep(activeStep - 1)
											}
											color="primary"
										>
											Back
										</Button>
									</Grid>
									<Grid item xs={2}>
										<Button
											color="primary"
											onClick={handleNext}
											variant="contained"
										>
											Next
										</Button>
									</Grid>
								</Grid>
							</div>
						</footer>
					</Grid>
				</div>
			</Grid>
		</>
	);
}

export default DataSetup;
